import useSWR from 'swr';
import qs from 'qs';
import fetcher from '../fetcher';
import { TraitId, User } from '../../types/user';

export const useGetAdvocates = ({ traitIds, includes = [] }: { traitIds: TraitId[]; includes?: string[] }) => {
  const query = qs.stringify({ filter: { traits: traitIds.map((traitId) => ({ id: traitId })) }, include: includes });
  const { data, error, isLoading } = useSWR<{ data: User[] }>(`/v1/api/advocates?${query}`, fetcher);

  return {
    data: data?.data || [],
    loading: isLoading,
    error,
  };
};
