import { createContext, useState, useMemo } from 'react';

type Provider = {
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
};

export const AuthenticationContext = createContext({ isAuthenticated: false, setIsAuthenticated: () => null } as Provider);

type Props = {
  children: React.ReactNode;
  isAuthenticated?: boolean;
};

const AuthenticationProvider = ({ children, isAuthenticated: defaultIsAuthenticated = false }: Props) => {
  const accessToken = localStorage.getItem('accessToken');
  const [isAuthenticated, setIsAuthenticated] = useState(!!accessToken);

  const value = useMemo(
    () => ({
      isAuthenticated,
      setIsAuthenticated,
    }),
    [isAuthenticated],
  );

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
};

export default AuthenticationProvider;
