import { Drawer } from '@solace-health/ui';
import { CareTimelineResponse, CareTimelineTypes } from '../../../../../../hooks/useGetUserCareTimeline';
import Encounter from './Encounter';
import ApptDrawer from '../ApptDrawer/ApptDrawer';

type VisitsAndEncounterDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  data: CareTimelineResponse;
  refresh: () => void;
  clearSelectedData: () => void;
  setSchedulingDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function VisitsAndEncounterDrawer({
  isOpen,
  onClose,
  data,
  refresh,
  setSchedulingDrawerOpen,
  clearSelectedData,
}: VisitsAndEncounterDrawerProps) {
  const { data: payload, type } = data;

  return (
    <Drawer placement="right" isOpen={isOpen} onClose={onClose} styles={{ wrapper: { minWidth: '27rem' } }}>
      {type === CareTimelineTypes.Encounter ? (
        <Encounter data={payload} onClose={onClose} />
      ) : (
        <ApptDrawer
          appointment={payload}
          handleClose={onClose}
          refresh={refresh}
          setSchedulingDrawerOpen={setSchedulingDrawerOpen}
          clearSelectedData={clearSelectedData}
        />
      )}
    </Drawer>
  );
}
