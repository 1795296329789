import { z } from 'zod';

const onlyDigits = (string: string) => string.replace(/\D/g, '');

export const UserSchema = z.object({
  first_name: z.string().min(1, { message: 'This field is required' }),
  last_name: z.string().min(1, { message: 'This field is required' }),
  phone: z.string().refine(
    (phone: string) => {
      const digits = onlyDigits(phone);
      return digits.length === 10;
    },
    { message: 'Wrong number of digits' },
  ),
  email: z.coerce.string().email().min(1, { message: 'This field is required' }),
  patient_sex: z.enum(['male', 'female', '']),
  patient_dob: z.string().min(1, { message: 'This field is required' }),
  address: z.object({
    street: z.string().nullable(),
    city: z.string().nullable(),
    state: z.string().min(1, { message: 'This field is required' }),
    zip: z.string().nullable(),
    unit: z.string().nullable(),
  }),
});

export type UserSchemaType = z.infer<typeof UserSchema>;
