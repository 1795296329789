import { Button, Flex, Form, Modal, Typography } from '@solace-health/ui';
import { HealthRecord, RetrievalStatus } from '../../../types/healthRecord';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import useHealthRecords from '../../../hooks/useHealthRecords';
import { capitalize, sortBy } from 'lodash';
import usePatient from '../../../hooks/commonwell/usePatient';

export const MergePatient = ({ healthRecordId }: { healthRecordId: HealthRecord['id'] }) => {
  const { healthRecords, isLoading, refresh: refreshHealthRecords } = useHealthRecords();
  const { mergePatients } = usePatient({ healthRecordId: healthRecordId });
  const { enqueueSnackbar } = useSnackbar();
  const [isModalOpen, setModalOpen] = useState(false);

  const onSubmit = async (values: { non_surviving_health_record_id: string }) => {
    await mergePatients(values.non_surviving_health_record_id)
      .then(() => {
        enqueueSnackbar('Successfully merged patients', { variant: 'success' });
        refreshHealthRecords();
        setModalOpen(false);
      })
      .catch((e) => {
        enqueueSnackbar(`Failed to merge patients: ${e.message}`, { variant: 'error' });
      });
  };

  const onClose = () => {
    setModalOpen(false);
  };

  const healthRecordOptions = sortBy(healthRecords, 'user.last_name').reduce(
    (options, healthRecord) => {
      if (healthRecord.id !== healthRecordId && healthRecord.retrieval_status === RetrievalStatus.Complete) {
        options.push({
          label: (
            <Flex vertical style={{ overflow: 'auto' }}>
              <div>
                {capitalize(healthRecord.user.last_name)}, {capitalize(healthRecord.user.first_name)}
              </div>
              <div>
                <small>
                  <i>{healthRecord.user.email}</i>
                </small>
              </div>
            </Flex>
          ),
          value: healthRecord.id,
        });
      }

      return options;
    },
    [] as { label: JSX.Element; value: string }[],
  );

  if (isLoading) return <>Loading...</>;

  return (
    <>
      <Button.Unstyled
        onClick={() => setModalOpen(true)}
        style={{ maxWidth: '200px', background: 'rgb(218, 77, 84)', color: 'white' }}
      >
        Merge Health Records
      </Button.Unstyled>
      <Modal isOpen={isModalOpen} onClose={onClose} destroyOnClose>
        <Flex vertical gap="1rem">
          <Typography.Header>Merge Health Records</Typography.Header>
          <Typography.Body>
            <i>Note: The health record selected below will be merged into this health record.</i>
          </Typography.Body>

          <Form.Container onSubmit={onSubmit}>
            <Flex vertical gap="1rem">
              <Form.SelectMenu
                name="non_surviving_health_record_id"
                options={healthRecordOptions}
                placeholder="Select Health Record (Lastname, Firstname)... "
                formOptions={{ required: true }}
              />
              <Flex gap="1rem">
                <Button.Secondary onClick={onClose}>Cancel</Button.Secondary>
                <Form.Submit isSubmitting={false} style={{ width: '100%' }} />
              </Flex>
            </Flex>
          </Form.Container>
        </Flex>
      </Modal>
    </>
  );
};
