import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import useSearchModels, { SearchModelSlug } from '../../../../hooks/useSearchModels';
import { SearchCode } from './SearchCode';
import { EditModel } from './EditModel';
import { orderBy } from 'lodash';

const MODEL_COLOR: { [x: string]: string } = {
  [SearchModelSlug.GRAY]: '#D9D9D9',
  [SearchModelSlug.GREEN]: '#B6D7A8',
  [SearchModelSlug.ORANGE]: '#F9CB9C',
  [SearchModelSlug.PINK]: '#D5A6BD',
  [SearchModelSlug.YELLOW]: '#FFE599',
};

export const AIModels = () => {
  const { data: searchModels } = useSearchModels();

  return (
    <>
      {orderBy(searchModels, ['slug']).map((model) => (
        <TableContainer key={model.id} component={Paper} sx={{ marginBottom: '48px' }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: `${MODEL_COLOR[model.slug]}70` }}>
                <TableCell colSpan={12}>
                  <EditModel searchModel={model} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell colSpan={2} width="24px">
                  Description
                </TableCell>

                <Tooltip
                  title="The ranking priority for the advocate profile and search result context when a search matches multiple codes"
                  placement="top"
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    <Box sx={{ display: 'flex' }}>
                      Context Priority <InfoIcon htmlColor="black" sx={{ marginLeft: '5px' }} fontSize="small" />
                    </Box>
                  </TableCell>
                </Tooltip>

                <TableCell>Distance Multiplier</TableCell>
                <TableCell>Value</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {model.search_codes.map((searchCode) => (
                <SearchCode key={searchCode.id} searchCode={searchCode} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ))}
    </>
  );
};
