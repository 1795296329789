import { Flex, LoadingSpinner, Typography } from '@solace-health/ui';
import { useParams } from 'react-router-dom';
import { useVideoCallTranscript } from '../../../hooks/bookings/useVideoCallTranscript';
import { orderBy } from 'lodash';
import dayjs from 'dayjs';
import { useVideoCallSoapNote } from '../../../hooks/bookings/useVideoCallSoapNote';
import { useMemo } from 'react';

export const CallTranscriptPage = () => {
  const { id } = useParams() as { id: string };

  const { data: transcript, loading } = useVideoCallTranscript({ id });
  const { data: soapNote } = useVideoCallSoapNote({ id });

  const aiResponse = useMemo(() => {
    let json = {};

    if (!soapNote?.ai_response) return '{}';

    try {
      json = JSON.parse(soapNote?.ai_response?.choices?.[0]?.message?.content || '{}');
    } catch (e: any) {
      console.error(`Failed to parse ai_response - ${e.message}`);
      json = { content: soapNote?.ai_response?.choices?.[0]?.message?.content };
    }

    return JSON.stringify(json, null, 4);
  }, [soapNote]);

  if (loading) return <LoadingSpinner />;

  return (
    <Flex vertical gap="2rem">
      <Typography.Display>Call Transcript</Typography.Display>
      {transcript.length === 0 ? (
        <Typography.Header>No transcript found</Typography.Header>
      ) : (
        <Flex vertical gap="1rem">
          {orderBy(transcript, ['event_dt'], ['asc']).map((item, i) => {
            return (
              <Flex vertical>
                <div>
                  [<i>{dayjs(item.event_dt).format('hh:mm:ss a')}</i>]{' '}
                  <b>
                    {item.user.first_name} {item.user.last_name}:
                  </b>{' '}
                  {item.text}
                </div>
              </Flex>
            );
          })}
        </Flex>
      )}
      {!soapNote ? (
        <Typography.Header>No SOAP notes generated from call</Typography.Header>
      ) : (
        <Flex vertical gap="1rem">
          <Typography.Display>AI Generated SOAP Notes</Typography.Display>
          <Flex>
            <pre style={{ whiteSpace: 'pre-wrap' }}>{aiResponse}</pre>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
