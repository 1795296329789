import { Icons } from '@solace-health/ui';
import * as S from './style';

const ActiveTag = ({ active }: { active: boolean }) => {
  return (
    <S.Popover content={active ? 'Active' : 'Inactive'}>
      <S.Circle active={active}>
        <Icons.Check color="#fff" size={16} />
      </S.Circle>
    </S.Popover>
  );
};

export default ActiveTag;
