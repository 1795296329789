import { Flex, Typography } from '@solace-health/ui';
import { Link, useLocation } from 'react-router-dom';
import { RouteLink } from '../../utils/routes';

const CONTENT: { [key: string]: { image: string; component: React.ReactNode } } = {
  NO_ACCESS: {
    image: 'https://solace-public-assets.s3.us-east-1.amazonaws.com/images/locked-out-space-cat.png',
    component: (
      <>
        <Typography.Header>Uh oh! Looks like you don't have access to view this page.</Typography.Header>
        <Typography.Header>If you believe you should, reach out to Tom.</Typography.Header>
      </>
    ),
  },
  NOT_FOUND: {
    image: 'https://solace-public-assets.s3.us-east-1.amazonaws.com/images/lost-space-cat.png',
    component: <Typography.Header>Uh oh! This page doesn't exist.</Typography.Header>,
  },
};

export const NotFoundPage = () => {
  const { pathname } = useLocation();

  const validRoutes = Object.values(RouteLink);

  let content = 'NOT_FOUND';
  if (validRoutes.includes(pathname as RouteLink)) content = 'NO_ACCESS';

  return (
    <Flex vertical align="center" gap="2rem">
      <img src={CONTENT[content].image} width={400} style={{ borderRadius: '50%' }} />
      <Flex vertical align="center" gap=".5rem">
        {CONTENT[content].component}
      </Flex>
      <Link to={RouteLink.Home}>Go Home</Link>
    </Flex>
  );
};
