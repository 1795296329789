export enum RouteLink {
  AcceptedInsurance = '/accepted_insurance',
  Advocate = '/advocates/:userId',
  Advocates = '/advocates',
  CallTranscript = 'call_transcripts/:id',
  CallTranscripts = '/call_transcripts',
  Clients = '/clients',
  EligibilityChecks = '/eligibility-checks',
  FeatureFlags = '/feature_flags',
  Form = 'forms/:id',
  Forms = '/forms',
  HealthRecord = 'health_records/:id',
  HealthRecords = '/health_records',
  Home = '/',
  InternalUsers = '/internal-users',
  InternalUser = '/internal-users/:id',
  Notifications = '/notifications',
  Patient = '/patients/:id',
  PatientReferrals = '/patient-referrals',
  Patients = '/patients',
  Payouts = '/payouts',
  Prospects = '/prospects',
  Reviews = '/reviews',
  SearchConfig = '/searchConfig',
  SearchResultListing = 'SearchResults/Listings/:listingId',
  SearchResults = '/searchResults',
  Seed = '/seed',
  Timesheets = '/timesheets',
  User = '/users/:userId',
  Users = '/users',
  Visits = '/visits',
}
