import { Flex, Icons, Tags, Typography } from '@solace-health/ui';
import { Role, User } from '../../../../../types/user';

const Divider = () => <Typography.Body> | </Typography.Body>;

type Props = {
  user: User & { roles: Role[] };
};

export const Details = ({ user }: Props) => {
  return (
    <Flex align="center" gap="1rem" wrap="wrap">
      {user?.email && (
        <Flex align="center" gap=".5rem">
          <Icons.Email color="#000" />
          <Typography.Body>{user.email}</Typography.Body>
        </Flex>
      )}
      <Divider />
      <Flex gap="1rem">
        {user.roles.map((role) => {
          return (
            <Tags.Round key={`role-${role.id}`} style={{ color: '#000', border: 'none' }} color="#e9f0ee">
              <Flex justify="center" gap=".5rem" align="center">
                {role.name}
              </Flex>
            </Tags.Round>
          );
        })}
      </Flex>
    </Flex>
  );
};
