import { User } from '../../types/user';
import useGetPaginatedResource from '../useGetPaginatedResource';

type UseGetInternalUsersProps = {
  search?: string;
};

const useGetPaginatedInternalUsers = ({ search }: UseGetInternalUsersProps) => {
  const restPaginatedResourceHookReturn = useGetPaginatedResource<User>('/v1/api/internal_users', { search });

  return {
    ...restPaginatedResourceHookReturn,
    refresh: restPaginatedResourceHookReturn.mutate,
  };
};

export default useGetPaginatedInternalUsers;
