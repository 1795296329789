import { Flex, Typography } from '@solace-health/ui';
import usePatient from '../../../hooks/commonwell/usePatient';
import { HealthRecord } from '../../../types/healthRecord';
import { addressAsString } from '../../../utils/commonwell';
import dayjs from 'dayjs';

export const Patient = ({ healthRecordId }: { healthRecordId: HealthRecord['id'] }) => {
  const { patient, isLoading } = usePatient({ healthRecordId });

  if (isLoading) return <>Loading patient...</>;
  if (!patient) return <>Patient not found</>;

  const name = patient.names[0];

  return (
    <>
      <Typography.Header>Patient</Typography.Header>
      <Flex vertical>
        <div>
          Name: {name.given[0]} {name.family[0]}
        </div>
        <div>Gender: {patient.gender}</div>
        <div>Birth date: {patient.birthDate && dayjs(patient.birthDate).format('MM/DD/YYYY')}</div>
        <div>Addresses: {patient.addresses.map((address) => addressAsString(address))}</div>
      </Flex>
    </>
  );
};
