import dayjs from 'dayjs';
import { GetUserResponse } from '../../../../../hooks/useGetUser';
import useGetUserHealthInsurances from '../../../../../hooks/useGetUserHealthInsurances';
import { Avatar, Button, Flex, Icons, Size, Typography } from '@solace-health/ui';
import StatusPill from '../../../../../components/sharedStatusPill/StatusPill';
import { AdminTable } from '../../../../../components/shared/Table';
import { useState } from 'react';
import UpsertPatientModal from '../../../../../components/shared/UpsertPatientModal';

export type Props = {
  patient: GetUserResponse;
};

export default function InsuranceSection({ patient }: Props) {
  const [isOpen, setOpen] = useState(false);
  const { data: healthInsurances, refresh } = useGetUserHealthInsurances({ userId: patient.id });

  const tableColumns = [
    {
      title: 'Date Added',
      dataIndex: 'date_added',
      key: 'date_added',
      render: (date: string) => dayjs(date).format('MM/DD/YYYY'),
    },
    {
      title: 'Insurance',
      dataIndex: 'insurance',
      key: 'insurance',
      render: (insurance: string) => <Typography.Header size={Size.SM}>{insurance}</Typography.Header>,
    },
    { title: 'Member ID', dataIndex: 'member_id', key: 'member_id' },
    {
      title: 'Policy Holder',
      dataIndex: 'policy_holder',
      key: 'policy_holder',
      render: (policyHolder: string) => (
        <Flex align="center" gap={4}>
          <Avatar size={26} />
          <Typography.Body>{policyHolder}</Typography.Body>
        </Flex>
      ),
    },
    {
      title: '',
      dataIndex: 'isPrimary',
      key: 'isPrimary',
      render: (isPrimary: boolean) =>
        isPrimary && (
          <StatusPill backgroundColor="var(--green-100, #F4F8F7)" borderColor="var(--border-color-dark-green, #285E50)">
            Active
          </StatusPill>
        ),
    },
  ];

  const data = healthInsurances?.map((healthInsurance) => ({
    key: healthInsurance?.id,
    insurance: `${healthInsurance?.payor?.insurance_program?.name} ${(healthInsurance?.payor?.insurance_company_id || '').toUpperCase()} (${healthInsurance?.payor?.state || ''})`,
    member_id: healthInsurance?.member_id,
    policy_holder: `${healthInsurance?.subscriber_first_name} ${healthInsurance?.subscriber_last_name}`,
    isPrimary: healthInsurance?.is_primary,
    date_added: healthInsurance?.created_at,
  }));

  const referralInsuranceAddress = patient.referral?.insurance_address?.full;

  return (
    <Flex vertical gap="1rem">
      <Flex justify="space-between" align="center">
        <Typography.Header>Insurance</Typography.Header>
        {(!healthInsurances || healthInsurances.length === 0) && (
          <Button.Outline style={{ maxWidth: '13rem' }} onClick={() => setOpen(true)}>
            <Flex align="center" gap=".25rem" justify="center">
              <Icons.Plus color="var(--button-label-green, #285E50)" />
              Add Insurance
            </Flex>
          </Button.Outline>
        )}
      </Flex>
      <AdminTable columns={tableColumns} data={data ?? []} showSearch={false} />
      {referralInsuranceAddress && (
        <Flex vertical gap=".25rem">
          <Typography.Header>Current Insurance Address</Typography.Header>
          <Typography.Body>{referralInsuranceAddress}</Typography.Body>
        </Flex>
      )}
      <UpsertPatientModal isOpen={isOpen} onClose={() => setOpen(false)} patientId={patient.id} onUpdate={() => refresh()} />
    </Flex>
  );
}
