import { Button, Divider, Flex, Form, LoadingSpinner, Size, Typography, styled, useForm } from '@solace-health/ui';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import useGetInsuranceCompanies from '../../../hooks/useGetInsuranceCompanies';
import useGetPayor, { UpsertPayorDto } from '../../../hooks/useGetPayor';
import { States } from '../../../shared/constants/general-constants';
import { TradingPartnerServiceId } from '../../../types/payor';
import { InsuranceProgramId } from '../../../types/prospect';
import { SolaceApiError } from '../../../utils/errors';
import useGetCandidPayers from '../../../hooks/useGetCandidPayers';
import { useGetPhysicians } from '../../../hooks/users/useGetPhysicians';
import { useGetPayorVersions } from '../../../hooks/users/useGetPayorVersions';
import dayjs from 'dayjs';

const defaultFormValues = {
  name: '',
  state: '',
  insurance_company_id: '',
  insurance_program_id: '',
  trading_partner_service_id: '',
  candid_payer_uuid: '',
  candid_payer_id: '',
  candid_payer_name: '',
  is_enabled: true,
  physicians: [],
  pverify_payer_code: '',
};

type Props = {
  onSubmit: (payorId: string) => void;
  currentPayorId?: string;
};

export const insuranceProgramOptions = [
  {
    label: 'Medicare',
    value: InsuranceProgramId.Medicare,
  },
];

const FormContainer = styled(Form.Container)`
  margin: 10px 0;
  overflow: hidden;
`;

const PayorInfoForm = ({ onSubmit, currentPayorId }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data: payor, createPayor, updatePayor } = useGetPayor({ id: currentPayorId, include: ['physicians'] });
  const { data: payorVersions } = useGetPayorVersions({ id: currentPayorId });
  const { insuranceCompanies } = useGetInsuranceCompanies();
  const { data: physicians } = useGetPhysicians();

  const form = useForm<UpsertPayorDto>({
    defaultValues: defaultFormValues,
  });

  const [isSaving, setSaving] = useState(false);

  const insuranceCompanyOptions = useMemo(
    () => insuranceCompanies.map(({ id, name }) => ({ label: name, value: id })),
    [insuranceCompanies],
  );

  const candidPayerId = form.watch('candid_payer_id');

  const { data: candidPayers, loading: loadingCandidPayers } = useGetCandidPayers({ id: candidPayerId });

  useEffect(() => {
    if (!formState.isDirty) {
      resetForm();
    }
  }, [payor]);

  const { formState } = form;

  const resetForm = () => {
    form.reset({
      name: payor?.name || '',
      state: payor?.state || '',
      insurance_company_id: payor?.insurance_company_id || null,
      insurance_program_id: payor?.insurance_program_id || '',
      trading_partner_service_id: payor?.trading_partner_service_id || '',
      candid_payer_uuid: payor?.candid_payer_uuid || '',
      candid_payer_id: payor?.candid_payer_id || '',
      candid_payer_name: payor?.candid_payer_name || '',
      is_enabled: payor?.is_enabled || true,
      physicians: payor?.physicians?.map((physician) => physician.id) || [],
      pverify_payer_code: payor?.pverify_payer_code || '',
    });
  };

  const onHandleSubmit = async () => {
    const isValid = await form.trigger();
    if (!isValid) return;
    setSaving(true);
    const data = form.getValues() as UpsertPayorDto;
    try {
      const payor = await upsertPayor({
        ...data,
      });
      enqueueSnackbar(`Payor ${currentPayorId ? 'Updated' : 'Created'}`, { variant: 'success' });
      onSubmit(payor.id);
    } catch (e) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`Check failed, please confirm details and resubmit - ${error.message}`, { variant: 'error' });
    } finally {
      setSaving(false);
    }
  };

  const upsertPayor = async (data: UpsertPayorDto) => {
    let payor;
    if (currentPayorId) {
      payor = await updatePayor(currentPayorId, data);
    } else {
      payor = await createPayor(data);
    }

    return payor;
  };

  const stateOptions = Object.entries(States).map(([key, value]) => ({ label: value, value: key }));
  const tradingPartnerOptions = Object.entries(TradingPartnerServiceId).map(([key, value]) => ({
    label: key,
    value: value,
  }));

  const mostRecentVersion = payorVersions?.[0];
  const isCreated = !!mostRecentVersion?.data?.created_by_name;
  return (
    <FormContainer formMethods={form} onSubmit={onHandleSubmit}>
      <Flex vertical gap=".5rem">
        <Typography.Header>{currentPayorId ? 'Update' : 'Add'} Payor Info</Typography.Header>
        {mostRecentVersion ? (
          <Typography.Body size={Size.XS}>
            {isCreated ? 'Created' : 'Last updated'} by{' '}
            {mostRecentVersion.data[isCreated ? 'created_by_name' : 'updated_by_name']} on{' '}
            {dayjs(mostRecentVersion.date).format('MMMM Do [at] h:mm A')}
          </Typography.Body>
        ) : null}
      </Flex>
      <Divider />

      <Flex vertical gap={24}>
        <Form.Text name="name" label="Name" formOptions={{ required: true }} />
        <Form.SelectMenu options={stateOptions} name="state" label="State" formOptions={{ required: true }} />
        <Form.SelectMenu options={insuranceCompanyOptions} name="insurance_company_id" label="Insurance Company" />
        <Form.SelectMenu
          options={insuranceProgramOptions}
          name="insurance_program_id"
          label="Insurance Program"
          formOptions={{ required: true }}
        />
        <Form.SelectMenu
          options={tradingPartnerOptions}
          name="trading_partner_service_id"
          label="Trading Partner Service"
          formOptions={{ required: true }}
        />

        <Form.Text name="pverify_payer_code" label="PVerify Payer Code" formOptions={{ required: true }} />

        <Flex vertical>
          <Typography.Header>Candid Info</Typography.Header>
          <Typography.Body size={Size.SM}>
            Provide the Candid Payer ID and choose the suggested uuid and name that matches
          </Typography.Body>
        </Flex>
        <Form.Text name="candid_payer_id" label="Payer ID" formOptions={{ required: true }} />

        {loadingCandidPayers && <LoadingSpinner />}
        {!loadingCandidPayers && candidPayers.length === 0 && <Typography.Body>No candid payers found</Typography.Body>}

        {!loadingCandidPayers &&
          candidPayers.length > 0 &&
          candidPayers.map((candidPayer) => {
            return (
              <Flex vertical>
                <Typography.Body>
                  <b>Name:</b> {candidPayer.payer_name}
                </Typography.Body>
                <Typography.Body>
                  <b>UUID:</b> {candidPayer.payer_uuid}
                </Typography.Body>
              </Flex>
            );
          })}

        <Form.Text name="candid_payer_name" label="Payer Name" formOptions={{ required: true }} />
        <Form.Text name="candid_payer_uuid" label="Payer UUID" formOptions={{ required: true }} />

        <Form.SelectMenu
          label="Licensed Physicians"
          mode="multiple"
          name="physicians"
          options={physicians?.map((physician) => ({ label: physician.fullName, value: physician.id })) || []}
        />

        <Flex gap={12} style={{ marginTop: 20 }}>
          <Button.Outline style={{ maxWidth: 160 }} onClick={onHandleSubmit} isSubmitting={isSaving}>
            Save Payor
          </Button.Outline>
        </Flex>
      </Flex>
    </FormContainer>
  );
};

export default PayorInfoForm;
