import { Flex, Typography } from '@solace-health/ui';
import { useGetAdvocateOnboardingSteps } from '../../../../../../hooks/users/useGetAdvocateOnboardingSteps';
import { DocusignStep } from './DocusignStep';
import { BackgroundCheckStep } from './BackgroundCheckStep';
import * as S from './style';
import { OfferPacketStep } from './OfferPacketStep';
import { SchedulingLinkStep } from './SchedulingLinkStep';
import { GoogleAdminStep } from './GoogleAdminStep';
import { AddedToGustoStep } from './AddedToGustoStep';
import { AssignedComplianceTrainingStep } from './AssignedComplianceTrainingStep';
import { SetStartDateStep } from './SetStartDateStep';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SetStartDate } from './SetStartDate';

dayjs.extend(utc);

type Props = {
  advocateId: string;
};
export const AdvocateOnboarding = ({ advocateId }: Props) => {
  const { data: onboardingSteps, refresh } = useGetAdvocateOnboardingSteps({ advocateId });

  return (
    <Flex vertical gap="4rem" style={{ marginTop: '1rem' }}>
      <SetStartDate onboardingSteps={onboardingSteps} advocateId={advocateId} refresh={refresh} />

      <Flex vertical gap="1rem">
        <Typography.Display>Onboarding Steps</Typography.Display>
        <S.Table>
          <thead>
            <tr>
              <th>Step</th>
              <th>Status</th>
              <th>Completed Date</th>
              <th>Completed By</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <DocusignStep onboardingSteps={onboardingSteps} advocateId={advocateId} refresh={refresh} />
            <BackgroundCheckStep onboardingSteps={onboardingSteps} advocateId={advocateId} refresh={refresh} />
            <OfferPacketStep onboardingSteps={onboardingSteps} advocateId={advocateId} refresh={refresh} />
            <SchedulingLinkStep onboardingSteps={onboardingSteps} advocateId={advocateId} refresh={refresh} />
            <GoogleAdminStep onboardingSteps={onboardingSteps} advocateId={advocateId} refresh={refresh} />
            <AddedToGustoStep onboardingSteps={onboardingSteps} advocateId={advocateId} refresh={refresh} />
            <AssignedComplianceTrainingStep onboardingSteps={onboardingSteps} advocateId={advocateId} refresh={refresh} />
            <SetStartDateStep onboardingSteps={onboardingSteps} advocateId={advocateId} refresh={refresh} />
          </tbody>
        </S.Table>
      </Flex>
    </Flex>
  );
};
