import { useMemo, useState } from 'react';
import * as S from './style';

import { Button, Flex, Icons, LoadingSpinner, Tags, Toggle, Tooltip, Typography } from '@solace-health/ui';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { useSnackbar } from 'notistack';
import { AdminTable } from '../../../components/shared/Table';
import UpsertPayorModal from '../../../components/shared/UpsertPayorModal';
import useGetPayor from '../../../hooks/useGetPayor';
import usePayors from '../../../hooks/usePayors';
import { dateCompare, textCompare } from '../../../shared/utils/table-utils';
import { Payor } from '../../../types/payor';
import { getFullStateName } from '../../../utils/general';
import { useGetPhysicians } from '../../../hooks/users/useGetPhysicians';

dayjs.extend(timezone);

const PHYSICIAN_COLORS: { [index: string]: string } = {
  '0': 'var(--green-500)',
  '1': 'var(--gold-500)',
  '2': 'var(--purple-500)',
};

type PayorTableType = {
  key: string;
  name: string;
  insurance: string;
  state: string;
  physicians: string[];
  isEnabled: boolean;
} & Payor;

export default function AcceptedInsurancePage() {
  const { enqueueSnackbar } = useSnackbar();
  const { payors, refresh, isLoading } = usePayors({ include: ['insurance_company', 'physicians'] });
  const { data: physicians } = useGetPhysicians();
  const { updatePayor } = useGetPayor({});
  const [isOpen, setOpen] = useState(false);
  const [currentPayorId, setCurrentPayorId] = useState<string | undefined>(undefined);

  const handleToggleEnabled = async (id: string, isEnabled: boolean) => {
    try {
      await updatePayor(id, { is_enabled: isEnabled });
      enqueueSnackbar('Payor status updated successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to update payor status', { variant: 'error' });
    }
  };

  const handleAddPayor = () => {
    setCurrentPayorId(undefined);
    setOpen(true);
  };

  const handleUpsertPayorClose = () => {
    setCurrentPayorId(undefined);
    setOpen(false);
  };

  const { columns, tableData } = useMemo(() => {
    const processedColumns = [
      {
        title: 'ID',
        dataIndex: 'id',
        width: 140,
        render: (id: string) => {
          return <Tooltip content={id}>{id.slice(0, 8)}...</Tooltip>;
        },
      },
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a: PayorTableType, b: PayorTableType) => textCompare(a, b, 'name'),
        width: 240,
      },
      {
        title: 'Insurance Company',
        dataIndex: 'insurance',
        sorter: (a: PayorTableType, b: PayorTableType) => textCompare(a, b, 'insurance'),
      },
      {
        title: 'State',
        dataIndex: 'state',
        sorter: (a: PayorTableType, b: PayorTableType) => textCompare(a, b, 'state'),
      },
      {
        title: 'Physicians',
        filters: [
          ...(physicians?.map((physician) => {
            return { text: physician.fullName, value: physician.id };
          }) || []),
          { text: 'No physicians', value: 'none' },
        ],
        onFilter: (value: string, record: Payor) => {
          if (value === 'none') {
            return record.physicians?.length === 0;
          }

          return record.physicians?.some((physician) => physician.id === value);
        },
        render: (payor: Payor) => {
          if (payor.physicians?.length === 0) return <Tags.Round color="var(--red-300)">None</Tags.Round>;

          return (
            <div>
              {payor.physicians?.map((physician, index) => (
                <Tags.Round color={PHYSICIAN_COLORS[String(index)] || 'var(--green-500)'}>
                  Dr. {physician.first_name}
                </Tags.Round>
              ))}
            </div>
          );
        },
      },
      {
        title: 'Created',
        sorter: (a: PayorTableType, b: PayorTableType) => dateCompare<{ created_at: string }>(a, b, 'created_at'),
        render: (payor: Payor) => dayjs(payor.created_at).format('MM/DD/YY h:mma'),
      },
      {
        title: 'Updated',
        sorter: (a: PayorTableType, b: PayorTableType) => dateCompare<{ updated_at: string }>(a, b, 'updated_at'),
        render: (payor: Payor) => dayjs(payor.updated_at).format('MM/DD/YY h:mma'),
      },
      {
        title: 'Enabled',
        dataIndex: 'is_enabled',
        render: (isEnabled: boolean, record: PayorTableType) => (
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Toggle
              defaultState={isEnabled}
              value={isEnabled.toString()}
              name={`toggle-${record.id}`}
              handleEvent={(isEnabled) => handleToggleEnabled(record.id, isEnabled)}
            />
          </div>
        ),
      },
    ];

    const processTableData = payors.map((payor) => ({
      ...payor,
      key: payor.id,
      insurance: payor.insurance_company?.name || 'N/A',
      state: `${getFullStateName(payor.state)} (${payor.state})`,
    }));

    return { columns: processedColumns, tableData: processTableData };
  }, [payors]);

  const handleOnRowClick = (record: PayorTableType) => {
    setCurrentPayorId(record.id);
    setOpen(true);
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <>
      <S.Container>
        <Typography.Display>Accepted Insurances</Typography.Display>
        <Flex justify="end">
          <Button.Primary onClick={handleAddPayor} style={{ maxWidth: 200, display: 'flex', gap: '1rem' }}>
            <Icons.AddPlus color={'#fff'} size={16} />
            Add Payor
          </Button.Primary>
        </Flex>
        <AdminTable columns={columns} data={tableData} onRowClick={handleOnRowClick} />
      </S.Container>

      <UpsertPayorModal
        isOpen={isOpen}
        onClose={handleUpsertPayorClose}
        onUpdate={() => refresh()}
        payorId={currentPayorId}
      />
    </>
  );
}
