import { Flex, styled } from '@solace-health/ui';

export const CapabilitesContainer = styled(Flex)`
  width: 100%;
  max-width: 330px;
  padding: 1rem;
  margin-top: 12px;

  border-radius: var(--border-radius-lg, 0.5rem);
  border: 1px solid var(--border-color-light-green, #afc8bf);
  background: var(--green-100, #f4f8f7);

  /* shadow-md */
  box-shadow:
    0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.1);

  header {
    margin: -1rem -1rem 0;
    padding: 1rem;
    border-bottom: 1px solid #afc8bf;
  }
`;

export const Wrapper = styled(Flex)`
  height: 100%;
  min-height: 0;
  flex-grow: 1;
`;
