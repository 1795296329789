import { Button, styled } from '@solace-health/ui';

export const StyledButton = styled(Button.Primary)`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  max-width: 260px;

  path {
    fill: #fff;
  }
`;
