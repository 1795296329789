import useSWR from 'swr';
import qs from 'qs';
import { User } from '../../types/user';
import fetcher from '../fetcher';
import { Booking } from '../../types/booking';
import { Referral } from '../../types/referrals';

type UseGetBookingsProps = {
  id?: User['sharetribe_uuid'];
  includes?: string[];
  filter?: { state?: string[] };
};

export type BookingResponse = Booking & { advocate: User; client: User & { referral: Referral } };

export default function useGetBookingsForPatient({ id, includes, filter }: UseGetBookingsProps) {
  const { data, error, mutate } = useSWR<BookingResponse[]>(
    id ? `/v1/api/user/${id}/bookings?${qs.stringify({ includes, filter })}` : null,
    fetcher,
  );

  return {
    data: data || [],
    loading: data === undefined,
    error,
    refresh: mutate,
  };
}
