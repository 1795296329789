import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

export type JitsiNumber = {
  countryCode: string;
  formattedNumber: string;
  tollFree: boolean;
};

export const UseJitsi = () => {
  const [phoneNumber, setPhoneNumber] = useState<JitsiNumber>();
  const JistsiDIDApi = 'https://api-vo.jitsi.net/vmms-conference-mapper/access/v1/dids';

  useEffect(() => {
    const fetchPhoneNumber = async () => {
      const { data: fetchedNumbers }: AxiosResponse<JitsiNumber[]> = await axios.get(JistsiDIDApi);
      const USNumber = fetchedNumbers
        .sort((a, b) => (a.tollFree === b.tollFree ? 0 : a.tollFree ? -1 : 1))
        .find((number) => number.countryCode === 'US');
      setPhoneNumber(USNumber);
    };
    fetchPhoneNumber();
  }, []);

  return {
    phoneNumber,
  };
};
