import useSWR from 'swr';
import fetcher from './fetcher';
import { PhysicianState, User } from '../types/user';
import { UsaState } from '../shared/constants/general-constants';
import { useSnackbar } from 'notistack';
import { SolaceApiError } from '../utils/errors';
import { SolaceAPI } from '../utils/api';
import * as qs from 'qs';

const usePhysicianStates = ({ physicianId }: { physicianId: string | null }) => {
  const { enqueueSnackbar } = useSnackbar();

  const query = { filter: { physician_id: physicianId } };
  const { data, error, isLoading, mutate } = useSWR<PhysicianState[]>(
    `/v1/api/physician_states?${qs.stringify(query)}`,
    fetcher,
  );

  const addPhysicianState = async ({ id, state }: { id: User['id']; state: UsaState }) => {
    try {
      await SolaceAPI.post({ path: `/api/physician_states`, body: { state, physician_id: physicianId } });
      enqueueSnackbar(`${state} added for physician`, { variant: 'success' });
    } catch (e: any) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
    }
  };

  const deletePhysicianState = async ({ id, state }: { id: User['id']; state: UsaState }) => {
    try {
      await SolaceAPI.delete({ path: `/api/physician_states/${id}/${state}` });
      enqueueSnackbar(`${state} removed for physician`, { variant: 'success' });
    } catch (e: any) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
    }
  };

  return {
    data: data || [],
    loading: isLoading,
    error,
    refresh: mutate,
    addPhysicianState,
    deletePhysicianState,
  };
};

export default usePhysicianStates;
