import { Drawer as SolaceDrawer, LoadingSpinner, styled, Flex, Select, DatePicker, Divider } from '@solace-health/ui';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { CommunityHealthListing, useCHWAvailability } from '../../../../../hooks/useCHWAvailability';
import { GetUserResponse } from '../../../../../hooks/useGetUser';
import { Confirmation } from './Confirmation/Confirmation';
import { PatientInfo } from './PatientInfo';
import { MeetingInfo } from './MeetingInfo/MeetingInfo';
import { Booking } from '../../../../../types/booking';
import { usePhysicianAvailability } from '../../../../../hooks/usePhysicianAvailability';
import { Listing } from '../../../../../types/listing';
import { ChooseAdvocate } from './ChooseAdvocate';

const Drawer = styled(SolaceDrawer)`
  .ant-drawer-body {
    padding: 2rem;
  }
`;

const month = (date: Date) => date.getUTCMonth() + 1;
const formatDate = (date: Date) => dayjs(date).format('YYYY-MM-DD');

export enum MeetingOptions {
  Physician = 'physician',
  CHWIntro = 'chw-intro',
  CHWFollowUp = 'chw-follow-up',
}

type Props = {
  user: GetUserResponse;
  isOpen: boolean;
  handleClose: () => void;
  refresh: () => void;
  originalBooking?: Booking | null;
};

export const SchedulingDrawer = ({ user, isOpen, handleClose, refresh, originalBooking = undefined }: Props) => {
  const [date, setDate] = useState({
    full: formatDate(dayjs(new Date()).add(1, 'day').toDate()),
    month: month(new Date()),
    year: new Date().getUTCFullYear(),
  });
  const [meetingType, setMeetingType] = useState<MeetingOptions>(MeetingOptions.Physician);
  const showPhysicianAvailability = meetingType === MeetingOptions.Physician;
  const isIntroCall = [MeetingOptions.CHWIntro, MeetingOptions.Physician].includes(meetingType);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [booking, setBooking] = useState<(Booking & { listing: CommunityHealthListing }) | null>(null);

  const [selectedListingId, setSelectedListingId] = useState<string | null>(null);

  const listingId = selectedListingId as Listing['id'];
  const { data: chwAvailability } = useCHWAvailability({
    month: date.month,
    year: date.year,
    user,
    listingId,
  });
  const { data: physicianAvailability } = usePhysicianAvailability({
    month: date.month,
    year: date.year,
    user,
  });

  const onDateSelect = (dayJsDate: Dayjs | null) => {
    if (!dayJsDate) return;

    const toDate = dayJsDate.toDate();

    setDate({ full: formatDate(toDate), month: month(toDate), year: toDate.getUTCFullYear() });
  };

  const onPanelChange = (dayJsDate: Dayjs) => {
    if (!dayJsDate) return;

    const toDate = dayJsDate.startOf('month').toDate();

    setDate({ full: formatDate(toDate), month: month(toDate), year: toDate.getUTCFullYear() });
  };

  const disabledDates = (current: Dayjs) => {
    if (current && current.isBefore(dayjs(), 'day')) {
      return true;
    }

    const dates = Object.keys(showPhysicianAvailability ? physicianAvailability : chwAvailability);

    const dayJsdates = dates.map((date) => dayjs(date).format('YYY-MM-DD'));
    return !dayJsdates.includes(current.format('YYY-MM-DD'));
  };

  const onClose = () => {
    handleClose();
    setShowConfirmation(false);
  };

  if (!user) return <LoadingSpinner />;

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="right" width="450px" destroyOnClose>
      {showConfirmation && booking ? (
        <Confirmation
          booking={booking}
          onClose={onClose}
          user={user}
          rescheduling={!!originalBooking}
          meetingType={meetingType}
        />
      ) : (
        <Flex vertical gap="1.5rem">
          <PatientInfo user={user} originalBooking={originalBooking} showPhysicianAvailability={showPhysicianAvailability} />

          <Select
            sort={false}
            onChange={(e) => setMeetingType(e as MeetingOptions)}
            defaultValue={MeetingOptions.Physician}
            showSearch={false}
            options={[
              { label: 'Physician', value: MeetingOptions.Physician },
              { label: 'CHW intro', value: MeetingOptions.CHWIntro },
              { label: 'CHW follow-up', value: MeetingOptions.CHWFollowUp },
            ]}
          />
          {meetingType !== MeetingOptions.Physician && (
            <ChooseAdvocate selectedListingId={selectedListingId} setSelectedListingId={setSelectedListingId} />
          )}

          <Divider />

          {isOpen && (
            <Flex>
              <DatePicker
                value={dayjs(date.full)}
                onChange={onDateSelect}
                onPanelChange={onPanelChange}
                disabledDate={(current) => disabledDates(current)}
              />
            </Flex>
          )}
          <MeetingInfo
            availability={showPhysicianAvailability ? physicianAvailability : chwAvailability}
            date={date}
            refresh={refresh}
            user={user}
            setShowConfirmation={setShowConfirmation}
            setBooking={setBooking}
            showPhysicianAvailability={showPhysicianAvailability}
            isIntroCall={isIntroCall}
            originalBooking={originalBooking}
          />
        </Flex>
      )}
    </Drawer>
  );
};
