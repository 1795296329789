import useSWR from 'swr';
import fetcher from './fetcher';
import { Listing, PausedListingTrait } from '../types/listing';
import { SolaceAPI } from '../utils/api';
import { TraitId } from '../types/user';
import { AxiosResponse } from 'axios';

type Data = {
  listing: (Listing & { paused_listing_traits: PausedListingTrait[] }) | null;
  profileSteps: Record<string, boolean>;
};

const useGetListing = ({
  id,
}: {
  id: Listing['id'];
}): {
  data: Data;
  loading: boolean;
  error: Error;
  refresh: () => void;
  createPausedListingTrait: ({ id, traitId }: { id: string; traitId: TraitId }) => Promise<AxiosResponse<any>>;
  deletePausedListingTrait: ({ id }: { id: string; traitId: TraitId }) => Promise<AxiosResponse<any>>;
} => {
  const shouldFetch = !!id;
  const { data, error, mutate } = useSWR<Data>(shouldFetch ? `/v1/api/listings/${id}` : null, fetcher);

  const createPausedListingTrait = async ({ id, traitId }: { id: string; traitId: TraitId }) => {
    return SolaceAPI.post({ path: `/api/listings/${id}/paused_listing_trait`, body: { traitId } });
  };

  const deletePausedListingTrait = async ({ id, traitId }: { id: string; traitId: TraitId }) => {
    return SolaceAPI.delete({ path: `/api/listings/${id}/paused_listing_trait/${traitId}` });
  };

  return {
    data: data || { listing: null, profileSteps: {} },
    loading: data === undefined && !error,
    error,
    refresh: mutate,
    createPausedListingTrait,
    deletePausedListingTrait,
  };
};

export default useGetListing;
