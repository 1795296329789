import { Button, Flex, Size, Typography } from '@solace-health/ui';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { User } from '../../../../../types/user';
import { copyToClipBoard, phoneNumberFormat } from '../../../../../utils/general';
import { useSnackbar } from 'notistack';

type Props = {
  lovedOne: User | undefined;
};

const Section = styled(Flex)`
  gap: 1rem;
  align-items: center;
`;

export const LovedOneInfo = ({ lovedOne }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  if (!lovedOne) return null;

  const handleCopyToClipboard = (text: string) => {
    copyToClipBoard({ text });
    enqueueSnackbar(`Successfully copied ${text} to clipboard`, { variant: 'success' });
  };

  return (
    <Flex vertical gap="1rem">
      <Typography.Body bold size={Size.XL}>
        Loved One Information
      </Typography.Body>
      <Section>
        <Typography.Body bold>Loved One: </Typography.Body>
        <Link to={`/patients/${lovedOne?.id}`}>{lovedOne?.fullName}</Link>
      </Section>
      <Section>
        <Typography.Body bold>First Name: </Typography.Body>
        <Typography.Body>{lovedOne?.first_name}</Typography.Body>
      </Section>
      <Section>
        <Typography.Body bold>Last Name: </Typography.Body>
        <Typography.Body>{lovedOne?.last_name}</Typography.Body>
      </Section>
      <Section>
        <Typography.Body bold>Phone: </Typography.Body>
        <Typography.Body>{phoneNumberFormat(lovedOne?.phone)}</Typography.Body>
      </Section>
      <Section>
        <Typography.Body bold>Email: </Typography.Body>
        <Button.Link onClick={() => handleCopyToClipboard(lovedOne?.email)}>{lovedOne?.email}</Button.Link>
      </Section>
    </Flex>
  );
};
