import { Flex, Icons, Select, Typography } from '@solace-health/ui';
import { TraitId } from '../../../../../types/user';
import { useGetAdvocates } from '../../../../../hooks/users/useGetAdvocates';
import useGetListing from '../../../../../hooks/useGetListing';
import { Listing, PausedListingTrait } from '../../../../../types/listing';

type Props = {
  selectedListingId: Listing['id'] | null;
  setSelectedListingId: (listingId: string | null) => void;
};

export const ChooseAdvocate = ({ selectedListingId, setSelectedListingId }: Props) => {
  const { data: users } = useGetAdvocates({
    traitIds: [TraitId.CommunityHealthAdvocate],
    includes: ['listings.paused_listing_traits'],
  });
  const { data: listing } = useGetListing({ id: selectedListingId || '' });

  const hasPausedChwAdvocateTrait = listing.listing?.paused_listing_traits?.some(
    (pausedListingTrait: PausedListingTrait) => pausedListingTrait.trait_id === TraitId.CommunityHealthAdvocate,
  );

  const filteredUsers = users.filter(
    (user) =>
      !user.listings?.some((listing) =>
        listing?.paused_listing_traits?.some((pausedTrait) => pausedTrait.trait_id === TraitId.CommunityHealthAdvocate),
      ),
  );

  return (
    <Flex vertical gap="1.5rem">
      <Select
        sort={false}
        onChange={(e) => setSelectedListingId(e as string)}
        defaultValue={null}
        options={[
          { label: 'Any Advocate', value: null },
          ...filteredUsers.map((user) => ({
            label: `${user.first_name} ${user.last_name}`,
            value: user.sharetribe_default_listing_uuid,
          })),
        ]}
      />
      {hasPausedChwAdvocateTrait && (
        <Flex align="center" gap="1rem">
          <Icons.WarningTriangle size={32} />
          <Typography.Body>
            <b>Warning!</b> This advocate is not accepting new bookings.
          </Typography.Body>
        </Flex>
      )}
    </Flex>
  );
};
