import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex, Tabs } from '@solace-health/ui';
import { LoadingSpinner } from '@solace-health/ui';
import { Header } from './Header';
import { InfoSection } from './InfoSection';
import * as S from './style';
import BackButton from '../../../../../shared/components/BackButton/BackButton';
import { Details } from './Details';
import { useGetInternalUser } from '../../../../../hooks/useGetInternalUser';
import { RolesSection } from './RolesSection';
import { Role } from '../../../../../types/user';

type Tabs = 'info' | 'roles';

const InternalUserPage = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState<Tabs>('info');

  const { data: user, refresh } = useGetInternalUser<{ roles: Role[] }>({
    id: params.id,
    includes: ['roles'],
  });

  if (!user) return <LoadingSpinner />;

  let tabs = [
    {
      label: 'Info',
      key: 'info',
      children: <InfoSection user={user} refresh={refresh} />,
    },
    {
      label: 'Roles',
      key: 'roles',
      children: <RolesSection user={user} refresh={refresh} />,
    },
  ];

  return (
    <>
      <S.Container>
        <BackButton onClick={() => navigate(-1)} label="Back" />

        <Flex vertical gap="1rem">
          <Header user={user} />
          <Details user={user} />
        </Flex>
        <Tabs
          items={tabs}
          defaultActiveKey={'info'}
          activeKey={activeKey}
          style={{ margin: '0', maxWidth: '100%' }}
          destroyInactiveTabPane
          onChange={(key) => {
            setActiveKey(key as Tabs);
          }}
        />
      </S.Container>
    </>
  );
};

export default InternalUserPage;
