import { styled } from '@solace-health/ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .ant-table-row:hover {
    cursor: pointer;
  }
`;
