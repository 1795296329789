import { Divider, Flex, Modal, Typography } from '@solace-health/ui';
import { BookingHelpRequest } from '../../../../hooks/prospects/useGetBookingHelpRequests';

type Props = {
  bookingHelpRequest: BookingHelpRequest | null;
  setBookingHelpRequest: (bookingHelpRequest: BookingHelpRequest | null) => void;
};

export const PatientReferralModal = ({ bookingHelpRequest, setBookingHelpRequest }: Props) => {
  const prospect = bookingHelpRequest?.prospect;

  const prospectDetails = [
    {
      label: 'Mobile Phone',
      value: prospect?.phone || 'N/A',
    },
    {
      label: 'Email',
      value: prospect?.email || 'N/A',
    },
    {
      label: 'Insurance',
      value: bookingHelpRequest?.payload?.insurance || 'N/A',
    },
    {
      label: 'State',
      value: prospect?.state || 'N/A',
    },
    {
      label: 'Conditions',
      value: prospect?.conditions.join(', '),
    },
  ];

  const provider = bookingHelpRequest?.payload?.provider;

  const providerDetails = [
    {
      label: 'Phone',
      value: provider?.phone || 'N/A',
    },
    {
      label: 'Email',
      value: provider?.email || 'N/A',
    },
    {
      label: 'Location',
      value: provider?.city || provider?.state ? `${provider?.city}, ${provider?.state}` : 'N/A',
    },
    {
      label: 'Organization',
      value: provider?.organization_name || 'N/A',
    },
    {
      label: 'Title',
      value: provider?.title || 'N/A',
    },
    {
      label: 'NPI',
      value: provider?.npi || 'N/A',
    },
    {
      label: 'Other Information',
      value: provider?.other_information || 'N/A',
    },
  ];

  return (
    <Modal isOpen={!!bookingHelpRequest} onClose={() => setBookingHelpRequest(null)}>
      <Flex vertical gap="2rem">
        <Flex vertical gap="1rem">
          <Typography.Display>Patient</Typography.Display>
          <Typography.Header>
            {bookingHelpRequest?.prospect.first_name} {bookingHelpRequest?.prospect.last_name}
          </Typography.Header>
          {prospectDetails.map((detail) => {
            return (
              <Typography.Body>
                <strong>{detail.label}:</strong> {detail.value}
              </Typography.Body>
            );
          })}
        </Flex>
        <Divider />
        <Flex vertical gap="1rem">
          <Typography.Display>Provider</Typography.Display>
          <Typography.Header>
            {bookingHelpRequest?.payload?.provider?.first_name} {bookingHelpRequest?.payload?.provider?.last_name}
          </Typography.Header>
          {providerDetails.map((detail) => {
            return (
              <Typography.Body>
                <strong>{detail.label}:</strong> {detail.value}
              </Typography.Body>
            );
          })}
        </Flex>
      </Flex>
    </Modal>
  );
};
