import { Flex, Form, FormatType } from '@solace-health/ui';

export const relationships = [
  { label: 'Father', value: 'father' },
  { label: 'Mother', value: 'mother' },
  { label: 'Grandmother', value: 'grandmother' },
  { label: 'Grandfather', value: 'grandfather' },
  { label: 'Son', value: 'son' },
  { label: 'Daughter', value: 'daughter' },
  { label: 'Partner', value: 'partner' },
  { label: 'Husband', value: 'husband' },
  { label: 'Wife', value: 'Wife' },
  { label: 'Brother', value: 'brother' },
  { label: 'Sister', value: 'sister' },
  { label: 'Niece', value: 'niece' },
  { label: 'Nephew', value: 'nephew' },
  { label: 'Aunt', value: 'aunt' },
  { label: 'Uncle', value: 'Uncle' },
  { label: 'In-Law', value: 'in_law' },
  { label: 'Guardian', value: 'guardian' },
  { label: 'Neighbor', value: 'neighbor' },
  { label: 'Friend', value: 'Friend' },
];

export const LovedOneInfoForm = () => {
  return (
    <Flex vertical gap={24}>
      <Flex gap={8}>
        <Form.Text name="loved_one.first_name" label="First Name" formOptions={{ required: true }} />
        <Form.Text name="loved_one.last_name" label="Last Name" formOptions={{ required: true }} />
      </Flex>
      <Form.Text
        name={'loved_one.email'}
        formOptions={{ required: true }}
        pattern={FormatType.Email}
        label="Email"
        placeholder="Email address"
      />
      <Form.Text
        label="Phone Number"
        name={'loved_one.phone'}
        formOptions={{ required: true }}
        format={FormatType.Phone}
        pattern={FormatType.Phone}
        placeholder="(123) 456-7890"
      />
      <Form.Select
        label="Relationship to Client"
        name="loved_one.relationship_to_client"
        formOptions={{ required: true }}
        options={relationships}
      />
      <Form.Text label="Preferred Name" name="loved_one.preferred_name" />
    </Flex>
  );
};
