/**
 * Warning: This code is ugly and might have bugs. It was a quick prototype to be able to view care plans in JSON format
 * because the data is encrypted in the database.
 */
import { Flex, Segmented, Typography } from '@solace-health/ui';
import { useGetForms } from '../../../../hooks/forms/useGetForms';
import { AdminTable } from '../../../../components/shared/Table';
import { useMemo, useState } from 'react';
import { User } from '../../../../types/user';
import { useNavigate } from 'react-router-dom';
import { FormTemplate } from '../../../../types/forms';
import { snakeToSentenceCase } from '../../../../shared/utils/gen-utils';
import { CarePlans } from './CarePlans';

enum FormsFilterType {
  All = 'all',
  CarePlans = 'care_plans',
}

export const FormsPage = () => {
  const navigate = useNavigate();

  const [activeList, setActiveList] = useState<FormsFilterType>(FormsFilterType.All);

  const { data: forms, loading } = useGetForms<{ form_template: FormTemplate; sender: User; sent_to: User }>({
    include: ['form_template', 'sender', 'sent_to'],
  });

  const { columns, tableData } = useMemo(() => {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        render: (id: string) => (
          <span style={{ maxWidth: '24px', overflowX: 'hidden', textOverflow: 'ellipsis' }}>{id}</span>
        ),
      },
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Advocate',
        dataIndex: 'advocate',
      },
      {
        title: 'Patient',
        dataIndex: 'patient',
      },
      {
        title: 'Sent',
        dataIndex: 'last_sent_dt',
        render: (lastSentDt: string) =>
          lastSentDt ? new Date(lastSentDt).toLocaleString('en-us', { timeZoneName: 'short' }) : 'N/A',
      },
      {
        title: 'Completed',
        dataIndex: 'completed_dt',
        render: (completedDt: string) =>
          completedDt ? new Date(completedDt).toLocaleString('en-us', { timeZoneName: 'short' }) : 'N/A',
      },
    ];

    const filteredTableData = forms.map((form) => ({
      id: form.id,
      name: form.form_template.name,
      advocate: form.sender.fullName,
      patient: form.sent_to.fullName,
      last_sent_dt: form.last_sent_dt,
      completed_dt: form.completed_dt,
    }));

    return { columns, tableData: filteredTableData };
  }, [forms]);

  return (
    <Flex vertical gap="1rem">
      <Typography.Display>Forms</Typography.Display>

      <Segmented
        defaultValue={FormsFilterType.All}
        options={Object.values(FormsFilterType).map((type) => ({
          label: snakeToSentenceCase(type),
          value: type,
        }))}
        onChange={(e) => setActiveList(e as FormsFilterType)}
      />

      {activeList === FormsFilterType.CarePlans ? (
        <CarePlans forms={forms} />
      ) : (
        <AdminTable
          columns={columns}
          data={tableData}
          loading={loading}
          showSearch
          onRowClick={(form) => {
            navigate(`/forms/${form.id}`);
          }}
        />
      )}
    </Flex>
  );
};
