import { Flex, FormatType, Modal, useForm, Form, Typography, Divider } from '@solace-health/ui';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useGetRoles } from '../../../../../hooks/useGetRoles';
import { SolaceAPI } from '../../../../../utils/api';
import { SolaceApiError } from '../../../../../utils/errors';
import { zodResolver } from '@hookform/resolvers/zod';
import { InternalUserSchemaType, InternalUserSchema } from '../InternalUserPage/schema';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const CreateInternalUserModal = ({ isOpen, onClose, onSubmit }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [saving, setSaving] = useState(false);

  const { data: roles } = useGetRoles();

  const form = useForm<InternalUserSchemaType>({
    resolver: zodResolver(InternalUserSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
    },
  });

  const onHandleSubmit = async () => {
    const isValid = await form.trigger();
    if (!isValid) return;

    setSaving(true);
    const data = form.getValues();

    try {
      await SolaceAPI.post({ path: '/api/internal_users', body: data });
      enqueueSnackbar(`Internal user created`, { variant: 'success' });
      onSubmit();
      onClose();
    } catch (e) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`Failed to create user - ${error.message}`, { variant: 'error' });
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} destroyOnClose>
        <Typography.Header>Create Internal User</Typography.Header>
        <Divider />
        <Form.Container formMethods={form} onSubmit={onHandleSubmit}>
          <Flex vertical gap="2rem">
            <Flex gap={8}>
              <Form.Text name="first_name" label="First Name" formOptions={{ required: true }} />
              <Form.Text name="last_name" label="Last Name" formOptions={{ required: true }} />
            </Flex>

            <Form.Text
              name={'email'}
              formOptions={{ required: true }}
              pattern={FormatType.Email}
              label={'Email'}
              placeholder="Email address"
            />

            <Form.SelectMenu
              label="Roles"
              mode="multiple"
              name="roles"
              options={roles.map((role) => ({ label: role.name, value: role.id }))}
            />

            <Form.Submit isSubmitting={saving} />
          </Flex>
        </Form.Container>
      </Modal>
    </>
  );
};
