import { Avatar, Button, Flex, Icons, Tags, Typography } from '@solace-health/ui';
import { phoneNumberFormat, timeZoneName } from '../../../../../../utils/general';
import dayjs from 'dayjs';
import { User } from '../../../../../../types/user';
import { IDENTITY } from '../../../UserViewPage';
import { TraitIcon } from '../../TraitIcon';

type Props = {
  user: User & { identities: { provider: 'auth0' | 'google-oauth2' | 'facebook' }[] };
};

export const Header = ({ user }: Props) => (
  <Flex vertical gap={24}>
    <Flex justify="space-between" align="center">
      <Flex gap={16} align="center">
        <Avatar src={user.profile_image_url || ''} size={75} />
        <Flex vertical gap=".5rem">
          <Typography.Display>{user.fullName}</Typography.Display>
          <Flex gap=".5rem">
            {user.traits?.map((trait) => (
              <Tags.Round key={trait.id} style={{ color: '#000', border: 'none' }} color="#e9f0ee">
                <Flex justify="center" gap=".5rem" align="center">
                  <TraitIcon trait={trait} />
                  {trait.name}
                </Flex>
              </Tags.Round>
            ))}
          </Flex>
        </Flex>
      </Flex>
      <Button.Primary
        style={{ maxWidth: '200px' }}
        onClick={() =>
          window.open(`${process.env.REACT_APP_SOLACE_FUNNEL_URL}/l/${user.sharetribe_default_listing_uuid}`, '_blank')
        }
      >
        <Flex gap={12} align="center">
          <Icons.ExternalLink color="#fff" />
          <Typography.Header>View Profile</Typography.Header>
        </Flex>
      </Button.Primary>
    </Flex>
    <Flex align="center" gap="1rem" wrap="wrap">
      {user?.phone && (
        <>
          <Flex align="center" gap=".5rem">
            <Icons.Phone color="#000" />
            <Typography.Body>{phoneNumberFormat(user.phone, '')}</Typography.Body>
          </Flex>
          |
        </>
      )}
      <Flex align="center" gap=".5rem">
        <Icons.Email color="#000" />
        <Typography.Body>{user.email}</Typography.Body>
      </Flex>
      |
      <Flex align="center" gap=".5rem">
        <Typography.Body>
          Login Method: {user.identities.map((identity) => IDENTITY[identity.provider]).join(', ')}
        </Typography.Body>
      </Flex>
      |
      <Flex align="center" gap=".5rem">
        <Icons.LocationPin color="#000" />
        <Typography.Body>{timeZoneName(user.time_zone, { includeAbbr: true })}</Typography.Body>
      </Flex>
      |
      <Flex align="center" gap=".5rem">
        <Typography.Body>Joined: </Typography.Body>
        <Typography.Body>{dayjs(user?.created_dt).format('MM/DD/YYYY')}</Typography.Body>
      </Flex>
    </Flex>
  </Flex>
);
