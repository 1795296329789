import useSWR from 'swr';
import fetcher from './fetcher';

export enum FeatureFlagId {
  BookingHelpRequest = 'booking_help_request',
}

export type FeatureFlag = {
  id: FeatureFlagId;
  is_active: boolean;
};

const useFeatureFlags = () => {
  const { data, error, mutate, isLoading } = useSWR<FeatureFlag[]>(`/v1/api/feature_flags`, fetcher);

  return {
    data: data || [],
    loading: isLoading,
    error,
    refresh: mutate,
  };
};

export default useFeatureFlags;
