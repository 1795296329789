import { Booking } from './booking';
import { User } from './user';

export enum PhysicianPatientStatus {
  EncounterOverdue = 'encounter_overdue',
  NeedsReview = 'needs_review',
  AwaitingCarePlan = 'awaiting_care_plan',
  VisitScheduled = 'visit_scheduled',
  CarePlanApproved = 'care_plan_approved',
  NoShow = 'no_show',
  Unknown = 'unknown',
}

export enum RelationshipType {
  AdvocateClient = 'advocate_client',
  AdvocatePatient = 'advocate_patient',
  PhysicianPatient = 'physician_patient',
}

export type PhysicianStatus = {
  label: string;
  value: PhysicianPatientStatus;
};

export enum ArchiveReason {
  NeedsMet = 'needs_met',
  Unresponsive = 'unresponsive',
  Other = 'other',
}

export type AdvocatePatient = {
  id: number;
  advocate_id: string;
  patient_id: string;
  last_contact: string | null;
  first_contact: string | null;
  archived_dt: string | null;
  care_details: string | null;
  pending: boolean;
  created_at: string;
  updated_at: string;
  patient: User;
  advocate: User;
  type: RelationshipType;
  first_intro_call: Booking | null;
  latest_booking?: Booking;
  physician_status?: {
    label: string;
    value: PhysicianPatientStatus;
  };
  archive_reason?: ArchiveReason;
  archive_context?: string;
};
