import { Button, styled } from '@solace-health/ui';

export const LastContactButton = styled(Button.Unstyled)`
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid #5a5a5a;
  background: #fff;
  color: #555555;
  padding: 4px 8px;
  margin: 8px 0;
  width: 100%;
`;
