import { Button, Flex } from '@solace-health/ui';
import usePatientLinks from '../../../hooks/commonwell/usePatientLinks';
import { HealthRecord } from '../../../types/healthRecord';
import { ConfirmActionModal } from './shared/ConfirmActionModal';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

export const ResetPatientLinks = ({ healthRecordId }: { healthRecordId: HealthRecord['id'] }) => {
  const { resetPatientLinks, refresh } = usePatientLinks({ healthRecordId });
  const [isModalOpen, setModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onConfirm = async () => {
    await resetPatientLinks()
      .then(() => {
        enqueueSnackbar('Successfully reset links', { variant: 'success' });
        refresh();
        setModalOpen(false);
      })
      .catch((e) => {
        enqueueSnackbar(`Failed to reset links: ${e.message}`, { variant: 'error' });
      });
  };

  return (
    <>
      <Button.Unstyled
        onClick={() => setModalOpen(true)}
        style={{ maxWidth: '200px', background: 'rgb(221, 177, 0)', color: 'white' }}
      >
        Reset Patient Links
      </Button.Unstyled>
      <ConfirmActionModal
        onClose={() => setModalOpen(false)}
        isOpen={isModalOpen}
        onConfirm={onConfirm}
        prompt={
          <Flex vertical gap="1rem">
            Are you sure you want to reset patient links?
            <small>
              <i>Note: This can take a couple minutes to fully refresh</i>
            </small>
          </Flex>
        }
        confirmText="Yes, reset"
      />
    </>
  );
};
