import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { Table, Typography } from '@solace-health/ui';
import useGetDeletedTimespans from '../../../../../../hooks/useGetDeletedTimespans';
import { baseTimespanTableColumns, TimespansProps, TimespanTable } from './Timespans';

dayjs.extend(timezone);

export default function DeletedTimespansTable({ advocateId }: TimespansProps) {
  const { data } = useGetDeletedTimespans({ advocateId });

  if (!data) return <Typography.Header>No TimeSpans found</Typography.Header>;

  const tableColumns = [
    ...baseTimespanTableColumns,
    {
      title: 'Deleted',
      dataIndex: 'deleted_dt',
      key: 'deleted_dt',
      render: (deleted_dt: string, record: TimespanTable) => {
        return dayjs(deleted_dt).tz(record?.advocate_patient?.advocate.time_zone).format('MMMM Do [at] h:mm A');
      },
    },

    {
      title: 'Deleted by',
      key: 'deleted_by_id',
      render: (record: TimespanTable) => {
        return `${record.deleted_by?.first_name} ${record.deleted_by?.last_name}`;
      },
    },
  ];

  return (
    <>
      <h3>Deleted Time Spans</h3>
      <Table<TimespanTable>
        style={{ width: '100%' }}
        columns={tableColumns}
        dataSource={data}
        showPagination={data.length > 10}
        pagination={{ defaultPageSize: 10 }}
      />
    </>
  );
}
