import styled from '@emotion/styled';
import { Button } from '@solace-health/ui';

export const Table = styled.table`
  border-collapse: collapse;

  th {
    text-align: left;
    padding-left: 1rem;
  }

  tr {
    border: 1px solid #d9d9d9;
  }

  td {
    padding: 1rem;
  }
`;

export const ActionButton = styled(Button.Primary)`
  padding: 0.5rem;
  height: 36px;
`;
