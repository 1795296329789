import useSWR from 'swr';
import fetcher from './../fetcher';
import { HealthRecord } from '../../types/healthRecord';
import { PatientLink } from '../../types/commonwell';

const useProbableLinks = ({ healthRecordId }: { healthRecordId: HealthRecord['id'] }) => {
  const { data, error, mutate, isLoading } = useSWR<PatientLink[]>(
    healthRecordId && `/v1/api/commonwell/probable_links/${healthRecordId}`,
    fetcher,
  );

  return {
    probableLinks: data || [],
    error,
    refresh: mutate,
    isLoading,
  };
};

export default useProbableLinks;
