import useSWR from 'swr';
import fetcher from './fetcher';
import { Role, User } from '../types/user';

export type GetInternalUserResponse<T> = User & T;

export const useGetRoles = () => {
  const { data, error, mutate, isLoading } = useSWR<Role[]>(`/v1/api/roles`, fetcher);

  return {
    data: data || [],
    isLoading,
    error,
    refresh: mutate,
  };
};
