import { useFormContext } from '@solace-health/ui';
import { TimeSpan } from '../../../types/timeSpan';
import { User } from '../../../types/user';
import { FormFields } from './PayoutsPage';
import { formatCurrency, sumTotalTimeAndAmount } from './calculations';
import { difference, sortBy } from 'lodash';
import * as S from './style';

export const PatientLineItem = ({
  timeSpans,
  isSubmitting,
}: {
  timeSpans: (TimeSpan & {
    advocate_patient: {
      patient: User;
      advocate: User;
    };
  })[];
  isSubmitting: boolean;
}) => {
  const { watch, setValue, getValues } = useFormContext();
  const patient = timeSpans[0].advocate_patient.patient;
  const selectedTimeSpanIds = watch(FormFields.TimeSpanIds);
  const patientTimeSpanIds = timeSpans.map((timeSpan) => timeSpan.id);
  const checked = patientTimeSpanIds.some((timeSpanId) => selectedTimeSpanIds.includes(timeSpanId));
  const sortedTimeSpans = sortBy(timeSpans, 'submitted_dt');
  const minSubmittedDt = sortedTimeSpans[0].submitted_dt;
  const maxSubmittedDt = sortedTimeSpans[sortedTimeSpans.length - 1].submitted_dt;

  const appendToSelected = (timeSpanIds: TimeSpan['id'][]) => {
    setValue(FormFields.TimeSpanIds, Array.from(new Set([...getValues(FormFields.TimeSpanIds), ...timeSpanIds])));
  };

  const toggleSelectAllForPatient = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      appendToSelected(patientTimeSpanIds);
    } else {
      const otherSelectedTimeSpanIds = difference(selectedTimeSpanIds, patientTimeSpanIds);
      setValue(FormFields.TimeSpanIds, otherSelectedTimeSpanIds);
    }
  };

  const calculations = sumTotalTimeAndAmount({ selectedTimeSpanIds: patientTimeSpanIds, timeSpans });

  return (
    <tr>
      <td>
        <S.Checkbox type="checkbox" onChange={toggleSelectAllForPatient} checked={checked} disabled={isSubmitting} />
      </td>
      <td>{patient.fullName}</td>
      <td>
        {new Date(minSubmittedDt as string).toLocaleDateString()} - {new Date(maxSubmittedDt as string).toLocaleDateString()}{' '}
        ({timeSpans.length} time spans)
      </td>
      <td>
        {calculations.time.hours} hours {calculations.time.minutes} mins
      </td>
      <td>{formatCurrency(calculations.amount)}</td>
    </tr>
  );
};
