import useGetListings from '../../../hooks/useGetListings';
import { tableIcons } from '../../../components/shared/MaterialTableShared';
import MaterialTable from '@material-table/core';
import useGetListingScoreMedians from '../../../hooks/useGetListingScoreMedians';
import { orderBy } from 'lodash';
import { Link } from 'react-router-dom';
import { COLORS } from '../../../enums/layout-enums';

export const ListingScores = () => {
  const { data: listings } = useGetListings();
  const { data: listingScoreMedians } = useGetListingScoreMedians();

  const activeListings = orderBy(
    listings.filter((listing) => !listing.deactivated_dt && listing.has_completed_profile),
    ['advocate_name'],
  );
  const columns = [
    {
      title: 'Advocate Name',
      field: 'advocate_name',
      render: (row: any) => {
        return (
          <Link to={`/SearchResults/Listings/${row.id}`} style={{ color: COLORS.GREEN_BRAND_PRIMARY }}>
            {row.advocate_name}
          </Link>
        );
      },
    },
    {
      title: 'Adjusted Scores',
      render: (row: any) => {
        const {
          listing_score: { quality_score, efficacy_score, monetary_score, conversion_score },
        } = row;

        return (
          <div>
            <div>Quality: {quality_score}</div>
            <div>Efficacy: {efficacy_score}</div>
            <div>Monetary: {monetary_score}</div>
            <div>Conversion: {conversion_score}</div>
          </div>
        );
      },
    },
    {
      title: 'All Time Scores',
      render: (row: any) => {
        const {
          listing_score: {
            quality_score_all_time,
            efficacy_score_all_time,
            monetary_score_all_time,
            conversion_score_all_time,
          },
        } = row;

        return (
          <div>
            <div>Quality: {quality_score_all_time}</div>
            <div>Efficacy: {efficacy_score_all_time}</div>
            <div>Monetary: {monetary_score_all_time}</div>
            <div>Conversion: {conversion_score_all_time}</div>
          </div>
        );
      },
    },
    {
      title: 'Q',
      field: 'listing_score.quality_score_all_time',
      width: '50px',
      tooltip: 'All Time Quality Score',
      type: 'numeric' as const,
    },
    {
      title: 'E',
      field: 'listing_score.efficacy_score_all_time',
      width: '50px',
      tooltip: 'All Time Efficacy Score',
      type: 'numeric' as const,
    },
    {
      title: 'M',
      field: 'listing_score.monetary_score_all_time',
      width: '50px',
      tooltip: 'All Time Monetary Score',
      type: 'numeric' as const,
    },
    {
      title: 'C',
      field: 'listing_score.conversion_score_all_time',
      width: '50px',
      tooltip: 'All Time Conversion Score',
      type: 'numeric' as const,
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: '48px' }}>
        <h3>Median Scores</h3>
        <div>Quality: {listingScoreMedians?.quality}</div>
        <div>Efficacy: {listingScoreMedians?.efficacy}</div>
        <div>Monetary: {listingScoreMedians?.monetary}</div>
      </div>
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        data={activeListings}
        title="Advocate Scores"
        options={{
          maxColumnSort: 'all_columns',
          search: true,
          pageSize: 50,
          pageSizeOptions: [25, 50, 100],
        }}
      />
    </div>
  );
};
