import useSWR from 'swr';
import fetcher from './fetcher';

type CandidPayer = { payer_uuid: string; payer_id: string; payer_name: string };

const useGetCandidPayers = ({ id }: { id: string }) => {
  const shouldFetch = id.length >= 5;

  const { data, error, mutate } = useSWR<CandidPayer[]>(shouldFetch ? `/v1/api/candid_payers/${id}` : null, fetcher);

  return {
    data: data || [],
    loading: shouldFetch && data === undefined,
    error,
    refresh: mutate,
  };
};

export default useGetCandidPayers;
