import { Button, Flex, Modal, Typography, useForm } from '@solace-health/ui';
import PatientInfoForm from './PatientInfoForm';
import { useEffect, useState } from 'react';
import useGetUser from '../../../hooks/useGetUser';
import { UpsertPatientDto } from '../../../hooks/usePatient';
import { HereFor, InsuranceProgramId } from '../../../types/prospect';
import useGetProspect from '../../../hooks/prospects/useGetProspect';
import { useNavigate } from 'react-router-dom';

type Props = {
  isOpen?: boolean;
  patientId?: string;
  onClose?: () => void;
  onUpdate?: () => void;
  prospectId?: string;
};

const defaultFormValues = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  sex: '',
  dob: '',
  member_id: '',
  referred_by: HereFor.Self,
  payor: {
    insurance_company_id: '',
    insurance_program_id: InsuranceProgramId.Medicare,
  },
  loved_one: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  },
} as const;

const UpsertPatientModal = ({
  isOpen = false,
  patientId = undefined,
  onClose = () => null,
  onUpdate = () => null,
  prospectId,
}: Props) => {
  const history = useNavigate();

  const [confirmOpen, setConfirmOpen] = useState(false);

  const { data: user } = useGetUser({
    id: patientId,
    includes: ['referral.payor', 'address', 'referral.prospect'],
  });

  const { data: prospect } = useGetProspect({ id: prospectId });

  const form = useForm<UpsertPatientDto>({
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    if (user?.id && !form.formState.isDirty) {
      form.reset({
        first_name: user?.first_name || '',
        last_name: user?.last_name || '',
        email: user?.email || '',
        phone: user?.phone || '',
        sex: user?.referral?.sex || '',
        dob: user?.dob || '',
        member_id: '',
        referred_by: user?.referral?.prospect?.here_for || HereFor.Self,
        ...(user?.address ? { address: user.address } : {}),
        payor: {
          insurance_company_id: user?.referral?.payor?.insurance_company_id || '',
          insurance_program_id: user?.referral?.payor?.insurance_program_id || InsuranceProgramId.Medicare,
        },
      });
    }

    if (prospect?.id) {
      form.reset({
        first_name: prospect?.first_name || '',
        last_name: prospect?.last_name || '',
        email: prospect?.email || '',
        phone: prospect?.phone || '',
        sex: prospect?.payload?.patient_sex || '',
        dob: prospect?.payload?.patient_dob || '',
      });
    }
  }, [user?.id, prospect?.id]);

  const onSubmit = () => {
    form.reset(defaultFormValues);
    onClose();
    onUpdate();
  };

  const handleOnClose = () => {
    setConfirmOpen(false);
    form.reset(defaultFormValues);
    if (prospectId) history('/patients', { replace: true });
    onClose();
  };

  const confirmClose = () => setConfirmOpen(true);

  return (
    <>
      <Modal isOpen={isOpen} onClose={confirmClose} destroyOnClose>
        <PatientInfoForm form={form} onSubmit={onSubmit} currentPatient={user} prospectId={prospectId} />
      </Modal>

      <Modal isOpen={confirmOpen} onClose={() => setConfirmOpen(false)} destroyOnClose>
        <Flex vertical gap={8}>
          <Typography.Header>Are you sure about that?</Typography.Header>
          <Typography.Body>Exiting will remove all unsaved progress...</Typography.Body>
        </Flex>
        <Flex gap={20} style={{ marginTop: 20 }}>
          <Button.Outline onClick={() => setConfirmOpen(false)}>Go Back</Button.Outline>
          <Button.Primary onClick={handleOnClose}>Confirm</Button.Primary>
        </Flex>
      </Modal>
    </>
  );
};

export default UpsertPatientModal;
