import useSWR from 'swr';
import fetcher from './fetcher';
import { AxiosResponse } from 'axios';
import { SolaceAPI } from '../utils/api';
import qs from 'qs';
import { Referral } from '../types/referrals';
import { Trait, TraitId, User } from '../types/user';
import { Document } from '../types/forms';
import { CareRelationship } from '../types/careRelationship';

export type GetUserResponse<T = User> = User &
  T & {
    address?: { state: string };
    identities?: { provider: 'auth0' | 'google-oauth2' | 'facebook' }[];
    referral?: Referral & { referral_documents?: { id: string; document: Document }[] };
    traits?: Trait[];
    sharee_care_relationships?: CareRelationship[];
  };

const useGetUser = <T>({
  id,
  includes = [],
}: {
  id?: User['id'];
  includes?: string[];
}): {
  data: GetUserResponse<T> | null;
  loading: boolean;
  refresh: () => void;
  error: Error;
  deleteUser: ({ id }: { id: string }) => Promise<AxiosResponse<any>>;
  createTrait: ({ id, traitIds }: { id: string; traitIds: TraitId[] }) => Promise<AxiosResponse<any>>;
  deleteTrait: ({ id }: { id: string; traitId: TraitId }) => Promise<AxiosResponse<any>>;
  loginAs: ({ id }: { id: string }) => Promise<AxiosResponse<any>>;
} => {
  const shouldFetch = !!id;

  const urlParams = qs.stringify({ includes });
  const {
    data: user,
    error,
    mutate,
  } = useSWR<GetUserResponse>(shouldFetch ? `/v1/api/user/${id}?${urlParams}` : null, fetcher);

  const createTrait = async ({ id, traitIds }: { id: string; traitIds: TraitId[] }) => {
    return SolaceAPI.post({ path: `/api/user/${id}/traits`, body: { traitIds } });
  };

  const deleteTrait = async ({ id, traitId }: { id: string; traitId: TraitId }) => {
    return SolaceAPI.delete({ path: `/api/user/${id}/traits/${traitId}` });
  };

  const loginAs = async ({ id }: { id: string }) => {
    return SolaceAPI.post({ path: `/auth/login_as`, body: { login_as_user_id: id } });
  };

  const deleteUser = async ({ id }: { id: string }) => {
    return SolaceAPI.delete({ path: `/api/user/${id}` });
  };

  return {
    data: (user as GetUserResponse<T>) || null,
    loading: user === undefined,
    error,
    refresh: mutate,
    deleteUser,
    createTrait,
    deleteTrait,
    loginAs,
  };
};

export default useGetUser;
