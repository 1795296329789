import { Typography, styled, Button as UIButton } from '@solace-health/ui';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  padding: var(--Spacing-lg, 1rem);
  border: 1px solid var(--border-color-dark, #555);
  border-radius: var(--border-radius-rounded-xl, 0.75rem);
  gap: var(--Spacing-lg, 1rem);
`;

export const Content = styled(Typography.Body)`
  white-space: pre-line;
`;

export const Button = styled(UIButton.Unstyled)`
  &.ant-btn.ant-btn-block {
    width: auto;
    height: auto;
  }
`;
