import styled from '@emotion/styled';
import { Flex, Tooltip as SolaceTooltip, Icons } from '@solace-health/ui';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import { Trait, TraitId } from '../../../../types/user';

const Tooltip = styled(SolaceTooltip)`
  max-width: unset;
  width: unset;
`;

export const TraitIcon = ({ trait }: { trait: Trait }) => {
  let content;
  let Icon = null;

  if (trait.id === TraitId.SelfPayAdvocate) {
    Icon = AttachMoneyIcon;
    content = 'Self Pay Advocate';
  }

  if (trait.id === TraitId.CommunityHealthAdvocate) {
    Icon = CreditCardIcon;
    content = 'CHW';
  }

  if (trait.id === TraitId.CommunityHealthPhysician) {
    Icon = MedicalInformationIcon;
    content = 'Physician';
  }

  if (trait.id === TraitId.SolaceHealthAdvocate) {
    Icon = Icons.SolaceHealthAdvocate;
    content = 'Solace Health Advocate';
  }

  if (!Icon) return null;

  return (
    <Tooltip content={content}>
      <Flex align="center">
        <Icon size={28} />
      </Flex>
    </Tooltip>
  );
};
