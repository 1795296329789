import styled from '@emotion/styled';
import { Button } from '@solace-health/ui';

export const HighlightButton = styled(Button.Unstyled)<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: #f4f8f7 !important;
  border: 1px solid #285e50;

  &.ant-btn-text:hover {
    border: 1px solid #d7a13b;
    background-color: #fff7e9 !important;
  }

  ${({ selected }) =>
    selected
      ? `
  border: 1px solid #d7a13b;
  background: #fff7e9 !important;
`
      : `
  border: 1px solid  #555;
  background:  #FFF
    `}
`;
