import useSWR from 'swr';
import fetcher from './fetcher';

type Listing = {
  id: string;
  deactivated_dt: string;
  has_completed_profile: boolean;
  advocate_name: string;
};

type Data = Listing[];

const useGetListings = (): { data: Data; loading: boolean; error: Error } => {
  const { data, error } = useSWR<Data>(`/v1/api/listings/all`, fetcher);

  return {
    data: data || [],
    loading: data === undefined && !error,
    error,
  };
};

export default useGetListings;
