import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Main from './Main';

if (process.env.REACT_APP_SOLACE_TIER && process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://43777f109c73491cb23f8f2279902835@o4504085081817088.ingest.sentry.io/4504087047307264',
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SOLACE_TIER,
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
