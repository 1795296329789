import useSWR from 'swr';
import fetcher from './fetcher';
import { Booking } from '../types/booking';
import { PhysicianVisit } from '../types/physicianVisit';
import qs from 'qs';

export enum CareTimelineTypes {
  Visit = 'visit',
  Encounter = 'encounter',
}
type VisitResponse = {
  type: CareTimelineTypes.Visit;
  data: Booking;
};
type EncounterResponse = {
  type: CareTimelineTypes.Encounter;
  data: PhysicianVisit;
};

export type CareTimelineResponse = VisitResponse | EncounterResponse;

type Props = {
  userId?: string;
  includes?: ('advocate.traits' | 'client' | 'video_call')[];
};

export default function useGetUserCareTimeline({ userId, includes = [] }: Props) {
  const urlParams = qs.stringify({
    includes,
  });
  const {
    data,
    isLoading,
    error,
    mutate: refresh,
  } = useSWR<CareTimelineResponse[]>(!!userId ? `/v1/api/admin/users/${userId}/care_timeline?${urlParams}` : null, fetcher);

  return { data: data ?? [], isLoading, error, refresh };
}
