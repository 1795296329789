import { Flex, SearchInput } from '@solace-health/ui';
import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { StyledHeader, StyledTable } from './style';
import antdOnChangeToQueryParams, { QueryParamInputs } from './antdOnChangeToQueryParams';

export const AdminTable = ({
  columns,
  data,
  clickable = true,
  showSearch = true,
  actionItem = undefined,
  onRowClick = () => null,
  defaultSearch = undefined,
  onPageChange,
  onChange,
  pagination,
  showPagination,
  onTextFilter,
  loading,
}: {
  columns: Record<string, unknown>[];
  data: Record<string, unknown>[];
  clickable?: boolean;
  showSearch?: boolean;
  defaultSearch?: string;
  actionItem?: ReactNode;
  onRowClick?: (record: any) => void;
  onPageChange?: (newPage: number) => void;
  pagination?: { current: number; pageSize: number; total: number; defaultPageSize: number };
  showPagination?: boolean;
  onChange?: (input: QueryParamInputs) => void;
  onTextFilter?: (text: string) => void;
  loading?: boolean;
}) => {
  const [filterTable, setFilterTable] = useState<Record<string, unknown>[] | null>(null);
  const [searchValue, setSearchValue] = useState(defaultSearch || '');

  const defaultOnSearch = (text: string) => {
    setSearchValue(text);

    const filterTable = data.filter((o) =>
      Object.keys(o).some((k) => String(o[k]).toLowerCase().includes(text.toLowerCase())),
    );

    setFilterTable(filterTable);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (onTextFilter) {
      onTextFilter(value);
    } else {
      defaultOnSearch(value);
    }

    setSearchValue(value);
  };

  useEffect(() => {
    if (!defaultSearch) return;
    onSearch({ target: { value: defaultSearch } } as ChangeEvent<HTMLInputElement>);
  }, [data, defaultSearch]);

  return (
    <Flex vertical gap="1.5rem">
      {(actionItem || showSearch) && (
        <StyledHeader>
          {actionItem}
          {showSearch && <SearchInput onChange={onSearch} value={searchValue} />}
        </StyledHeader>
      )}
      <StyledTable
        clickable={clickable}
        columns={columns}
        dataSource={(filterTable || data).map((item) => ({ key: item.id, ...item }))}
        loading={loading}
        showPagination={showPagination || data.length > 10}
        pagination={{
          defaultPageSize: 10,
          ...(pagination ?? {}),
        }}
        onChange={(tablePagination, filters, sorter, extra) => {
          if (onPageChange && tablePagination.current) {
            onPageChange(tablePagination.current);
          }

          if (!tablePagination.current || !onChange) {
            return;
          }

          onChange(antdOnChangeToQueryParams(tablePagination, filters, sorter));
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              onRowClick(record);
            },
          };
        }}
      />
    </Flex>
  );
};
