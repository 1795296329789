import { Button, styled } from '@solace-health/ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCloseButton = styled(Button.Outline)`
  max-width: 4.75rem;
  align-items: center;
  justify-content: center;
`;

export const Divider = styled.div`
  border-bottom: 1px solid #afc8bf;
  margin: 1.5rem 0px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
