import { Collapse, Flex, Size, Typography, styled } from '@solace-health/ui';
import { User } from '../../../../../types/user';
import { CareRelationship } from '../../../../../types/careRelationship';
import { Link } from 'react-router-dom';
import { Referral } from '../../../../../types/referrals';

const Container = styled(Flex)`
  margin: 0.5rem 0;

  &&&& .ant-collapse {
    border-color: #000;
  }

  &&&& .ant-collapse-arrow {
    display: none;
  }

  &&&&& .ant-collapse-item {
    border-color: #000;
    border-radius: 10px;
    border-left: 8px solid #e9cc95;
  }
`;

type Props = {
  user: User & {
    referral: Referral;
    sharee_care_relationships: CareRelationship[];
    client_care_relationships: CareRelationship[];
  };
};

const LovedOneFlag = ({ user }: Props) => {
  const lovedOne = user?.client_care_relationships?.[0].sharee;

  return (
    <Container vertical>
      <Collapse
        collapsible="disabled"
        items={[
          {
            key: user?.id,
            label: (
              <Flex justify="space-between" gap="1rem">
                <Typography.Body color="#000000" size={Size.MD}>
                  Active Loved One Account - <Link to={`/patients/${lovedOne?.id}`}>{lovedOne?.fullName} </Link>
                </Typography.Body>
              </Flex>
            ),
            children: <></>,
          },
        ]}
      />
    </Container>
  );
};

export default LovedOneFlag;
