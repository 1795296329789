import useSWR from 'swr';
import { Payor } from '../types/payor';
import { SolaceAPI } from '../utils/api';
import { SolaceApiError } from '../utils/errors';
import fetcher from './fetcher';
import qs from 'qs';

export type UpsertPayorDto = {
  id?: string;
  name: string;
  state: string;
  insurance_company_id: string | null;
  insurance_program_id: string;
  trading_partner_service_id: string;
  candid_payer_uuid: string;
  candid_payer_id: string;
  candid_payer_name: string;
  is_enabled: boolean;
  physicians: string[];
  pverify_payer_code: string;
};

const useGetPayor = ({
  id,
  include,
}: {
  id?: Payor['id'];
  include?: 'physicians'[];
}): {
  data: Payor | null;
  loading: boolean;
  refresh: () => void;
  error: Error;
  createPayor: (body: Omit<UpsertPayorDto, 'id'>) => Promise<Payor>;
  updatePayor: (id: Payor['id'], body: Partial<UpsertPayorDto>) => Promise<Payor>;
} => {
  const shouldFetch = !!id;

  const query = qs.stringify({ include });
  const { data: payor, error, mutate } = useSWR<Payor>(shouldFetch ? `/v1/api/payors/${id}?${query}` : null, fetcher);

  const createPayor = async (body: Omit<UpsertPayorDto, 'id'>): Promise<Payor> => {
    try {
      const { data: payor } = await SolaceAPI.post<Payor>({
        path: `/api/payors`,
        body,
      });
      return payor;
    } catch (e: any) {
      const error = e as SolaceApiError;
      throw new SolaceApiError(error);
    }
  };

  const updatePayor = async (id: Payor['id'], body: Partial<UpsertPayorDto>): Promise<Payor> => {
    try {
      const { data: payor } = await SolaceAPI.put<Payor>({
        path: `/api/payors/${id}`,
        body,
      });
      return payor;
    } catch (e: any) {
      const error = e as SolaceApiError;
      throw new SolaceApiError(error);
    }
  };

  return {
    data: payor || null,
    loading: payor === undefined,
    error,
    refresh: mutate,
    createPayor,
    updatePayor,
  };
};

export default useGetPayor;
