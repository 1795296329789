import { Flex, Form, styled } from '@solace-health/ui';
import { Table as UnstyledTable } from './Table';

export const Container = styled(Flex)`
  flex-direction: column;
  gap: 1rem;
`;

export const Table = styled(UnstyledTable)`
  border-collapse: separate;
  border-spacing: 0;

  th {
    border-bottom: 1px solid #555;
    text-align: left;
    padding: 0.5rem 0;
  }

  td {
    padding: 0.3rem 0;
  }

  /* Header row */
  tr:nth-of-type(1) {
    th {
      border: none;
    }
  }

  /* Summary row */
  tr:nth-last-of-type(2) {
    position: sticky;
    bottom: 0;
    background: white;
    padding: 10px;

    td {
      border-top: 1px solid #555;
      padding: 0.5rem 0;
    }
  }
`;

export const FormContainer = styled(Form.Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding: 1rem;
`;

export const Checkbox = styled.input`
  transform: scale(1.5);
`;
