import { Button, Flex, Modal, Table, TagStatus, Tags, Tooltip, Typography } from '@solace-health/ui';
import useGetTimeSpans from '../../../../../../hooks/useGetTimeSpans';
import { User, UserTimeZone } from '../../../../../../types/user';
import { getDurationInHoursAndMinutes, getPrettyStartEndTime } from '../../../../../../utils/general';
import dayjs from 'dayjs';
import useTimeSpan from '../../../../../../hooks/useTimeSpan';
import timezone from 'dayjs/plugin/timezone';
import { useState } from 'react';
import { PayoutStatus } from '../../../../../../types/payout';
import { DateFormat } from '../../../../../../shared/utils/date-utils';
import { capitalize } from 'lodash';
import DeletedTimespansTable from './DeletedTimespansTable';

dayjs.extend(timezone);

export type TimespanTable = {
  id: string;
  start_dt: string;
  end_dt: string | null;
  duration: number;
  stripe_transfer_id: string | null;
  submitted_dt: string;
  deleted_dt?: string;
  deleted_by?: { first_name: string; last_name: string };
  advocate_patient: {
    advocate: {
      time_zone: UserTimeZone;
    };
    patient: {
      internal_id: number;
      first_name: string;
      last_name: string;
    };
  };
  payout: {
    status: PayoutStatus;
    status_dt: string;
    updated_at: string;
  };
};

export type TimespansProps = {
  advocateId: User['id'];
};

export const baseTimespanTableColumns = [
  {
    title: 'Date',
    dataIndex: 'start_dt',
    key: 'date',
    render: (date: string, record: TimespanTable) =>
      dayjs(date).tz(record?.advocate_patient?.advocate.time_zone).format('MM/DD/YYYY'),
    sorter: (a: TimespanTable, b: TimespanTable) => new Date(a.start_dt).getTime() - new Date(b.start_dt).getTime(),
    defaultSortOrder: 'descend' as const,
  },
  {
    title: 'Patient',
    key: 'patient',
    render: (record: TimespanTable) => {
      const patient = record?.advocate_patient?.patient;

      return `${patient?.first_name} ${patient?.last_name} (#${patient?.internal_id})`;
    },
    sorter: (a: TimespanTable, b: TimespanTable) =>
      (a?.advocate_patient?.patient.internal_id || 0) - (b?.advocate_patient?.patient.internal_id || 0),
    defaultSortOrder: 'descend' as const,
  },
  {
    title: 'Start',
    dataIndex: 'start_dt',
    key: 'start_dt',
    render: (start_dt: string, record: TimespanTable) =>
      getPrettyStartEndTime({ startDate: start_dt, timezone: record?.advocate_patient?.advocate.time_zone }).start,
  },
  {
    title: 'End',
    dataIndex: 'end_dt',
    key: 'end_dt',
    render: (end_dt: string, record: TimespanTable) =>
      getPrettyStartEndTime({
        endDate: end_dt,
        timezone: record?.advocate_patient?.advocate.time_zone,
      }).end,
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
    render: (duration: number) => {
      const { hours, mins } = getDurationInHoursAndMinutes(duration);

      return `${hours}h ${mins}m`;
    },
  },
  {
    title: 'Submitted',
    dataIndex: 'submitted_dt',
    key: 'submitted_dt',
    sorter: (a: TimespanTable, b: TimespanTable) => new Date(a.submitted_dt).getTime() - new Date(b.submitted_dt).getTime(),
    render: (submitted_dt: string, record: TimespanTable) =>
      submitted_dt
        ? dayjs(submitted_dt).tz(record?.advocate_patient?.advocate.time_zone).format('MMMM Do [at] h:mm A')
        : null,
  },
];

export default function Timespans({ advocateId }: TimespansProps) {
  const { data, mutate: refresh } = useGetTimeSpans({
    advocateId,
    includes: ['advocate_patient.advocate', 'advocate_patient.patient', 'payout'],
  });

  const { deleteTimeSpan } = useTimeSpan();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timeSpanId, setTimeSpanId] = useState<string | null>(null);

  if (!data) return <Typography.Header>No TimeSpans found</Typography.Header>;

  const handleDeleteTimeSpan = (id: string) => {
    deleteTimeSpan({
      id,
      onSuccess: () => {
        refresh();
        setTimeSpanId(null);
        setIsModalOpen(false);
      },
    });
  };

  const tableColumns = [
    ...baseTimespanTableColumns,
    {
      title: 'Paid',
      dataIndex: 'payout_id',
      key: 'payout_id',
      render: (payout_id: string | null, record: TimespanTable) => {
        if (!payout_id) return <Tags.Rectangle tagStatus={TagStatus.Warning}>Unpaid</Tags.Rectangle>;
        if (record.payout?.status === PayoutStatus.Paid)
          return (
            <Tags.Rectangle tagStatus={TagStatus.Success}>
              {dayjs(record.payout.status_dt).format(DateFormat.Short)}
            </Tags.Rectangle>
          );
        return <Tags.Rectangle tagStatus={TagStatus.Warning}>{capitalize(record.payout?.status)}</Tags.Rectangle>;
      },
      sorter: (a: TimespanTable, b: TimespanTable) => {
        if (a.payout?.status === PayoutStatus.Paid && b.payout?.status !== PayoutStatus.Paid) return -1;
        if (a.payout?.status !== PayoutStatus.Paid && b.payout?.status === PayoutStatus.Paid) return 1;
        if (!a.payout) return -1;
        if (!b.payout) return 1;
        return new Date(a.payout?.updated_at).getTime() - new Date(b.payout?.updated_at).getTime();
      },
    },
    {
      title: 'Delete',
      dataIndex: 'id',
      key: 'delete',
      render: (key: string, record: TimespanTable) => {
        return (
          <Tooltip content="Unable to delete TimeSpans that have been paid" disabled={!record.stripe_transfer_id}>
            <Button.Primary
              onClick={() => {
                setIsModalOpen(true);
                setTimeSpanId(key);
              }}
              disabled={!!record.stripe_transfer_id}
            >
              Delete
            </Button.Primary>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <Flex vertical={true}>
        <Table
          style={{ width: '100%' }}
          columns={tableColumns}
          dataSource={data}
          showPagination={data.length > 10}
          pagination={{ defaultPageSize: 10 }}
        />

        <DeletedTimespansTable advocateId={advocateId} />
      </Flex>

      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setTimeSpanId(null);
          setIsModalOpen(false);
        }}
      >
        <Flex vertical gap="1rem">
          <Typography.Header>Are you sure you want to delete this time span?</Typography.Header>
          <Flex gap=".75rem">
            <Button.Primary onClick={() => (timeSpanId ? handleDeleteTimeSpan(timeSpanId) : null)}>Confirm</Button.Primary>
            <Button.Secondary onClick={() => setIsModalOpen(false)}>Cancel</Button.Secondary>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
