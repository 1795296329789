import qs from 'qs';
import useSWR from 'swr';
import { Payor } from '../types/payor';
import fetcher from './fetcher';

type Props = {
  include?: 'insurance_company'[];
};

const useGetInsuranceCompanies = (jsonApiQuery: Props = {}) => {
  const urlParams = qs.stringify(jsonApiQuery);
  const { data, error, mutate, isLoading } = useSWR<Payor[]>(`/v1/api/insurance_companies?${urlParams}`, fetcher);

  return {
    insuranceCompanies: data || [],
    error,
    isLoading,
    refresh: mutate,
  };
};

export default useGetInsuranceCompanies;
