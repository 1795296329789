import { TimeSpan } from '../types/timeSpan';
import { SolaceAPI } from '../utils/api';
import { useSnackbar } from 'notistack';

type DeleteTimeSpanProps = {
  id: TimeSpan['id'];
  onSuccess?: () => void;
};

export default function useTimeSpan() {
  const { enqueueSnackbar } = useSnackbar();

  const deleteTimeSpan = async ({ id, onSuccess = () => null }: DeleteTimeSpanProps) => {
    try {
      await SolaceAPI.delete({ path: `/api/time_span/${id}` });

      enqueueSnackbar('Time span deleted', { variant: 'success' });
      onSuccess();
    } catch (error) {
      enqueueSnackbar('Failed to delete time span', { variant: 'error' });
    }
  };

  return { deleteTimeSpan };
}
