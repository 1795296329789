import useSWR from 'swr';
import qs from 'qs';
import fetcher from './fetcher';
import { TraitId } from '../types/user';
import { Booking } from '../types/booking';

export default function useGetVisits({ trait }: { trait: TraitId | null }) {
  const {
    data,
    error,
    mutate: refresh,
    isLoading,
  } = useSWR<Booking[]>(
    `/v1/api/admin/visits?${
      trait ? qs.stringify({ filter: { advocate: { traits: { id: trait } } }, include: ['video_call'] }) : ''
    }`,
    fetcher,
  );

  return { data: data || [], error, refresh, isLoading };
}
