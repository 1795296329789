import useSWR from 'swr';
import fetcher from './fetcher';

export type Skill = {
  id: string;
  name: string;
  is_diagnosis: boolean;
};

const useSkills = (): { data: Skill[]; loading: boolean; error: Error } => {
  const { data, error } = useSWR<Skill[]>('/v1/api/skills', fetcher);

  return {
    data: data || [],
    loading: data === undefined,
    error,
  };
};

export default useSkills;
