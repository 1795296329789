import { Button } from '@solace-health/ui';
import { HealthRecord } from '../../../types/healthRecord';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useState } from 'react';
import useHealthRecords from '../../../hooks/useHealthRecords';
import { useSnackbar } from 'notistack';

export const RefreshRecord = ({ healthRecord }: { healthRecord: HealthRecord }) => {
  const { upsertForUser, refresh: refreshHealthRecords } = useHealthRecords();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onClick = async () => {
    setIsRefreshing(true);

    await upsertForUser(healthRecord.user_id)
      .then(() => {
        enqueueSnackbar('Health record refreshed', { variant: 'success' });
        refreshHealthRecords();
      })
      .catch((e) => {
        enqueueSnackbar(`Health record refresh failed: ${e.message}`, { variant: 'error' });
      })
      .finally(() => {
        setIsRefreshing(false);
      });
  };

  return (
    <Button.Outline type="button" style={{ width: '60px' }} onClick={onClick} isSubmitting={isRefreshing}>
      {!isRefreshing && <RefreshIcon />}
    </Button.Outline>
  );
};
