import { Flex, Icons, Typography } from '@solace-health/ui';
import { GetUserResponse } from '../../../../../hooks/useGetUser';
import { phoneNumberFormat, timeZoneName } from '../../../../../utils/general';
import dayjs from 'dayjs';
import { capitalize, isEmpty } from 'lodash';
import PatientStatusPill from '../../../../../components/user/PatientStatusPill';
import { IDENTITY } from '../../UserViewPage';

const Divider = () => <Typography.Body> | </Typography.Body>;

type PatientDetailsProps = {
  user: GetUserResponse;
};

export default function PatientDetails({ user }: PatientDetailsProps) {
  return (
    <Flex align="center" gap="1rem" wrap="wrap">
      <Flex align="center" gap=".5rem">
        <PatientStatusPill status={user.patient_status} />
      </Flex>
      <Divider />
      {user?.phone && (
        <Flex align="center" gap=".5rem">
          <Icons.Phone color="#000" />
          <Typography.Body>{phoneNumberFormat(user.phone, '')}</Typography.Body>
        </Flex>
      )}
      <Divider />
      {user?.email && (
        <Flex align="center" gap=".5rem">
          <Icons.Email color="#000" />
          <Typography.Body>{user.email.match(/referral(.*)@solace.health/) ? 'N/A' : user.email}</Typography.Body>
        </Flex>
      )}
      <Divider />
      {user?.referral?.prospect && (
        <>
          <Flex align="center" gap=".5rem">
            <Typography.Body>{`Type: ${capitalize(user.referral.prospect.here_for?.replace('_', ' '))}`}</Typography.Body>
          </Flex>
          <Divider />
        </>
      )}
      <Flex align="center" gap=".5rem">
        <Icons.LocationPin color="#000" />
        <Typography.Body>{timeZoneName(user.time_zone, { includeAbbr: true })}</Typography.Body>
      </Flex>
      <Divider />
      {user.identities && !isEmpty(user.identities) && (
        <>
          <Flex align="center" gap=".5rem">
            <Typography.Body>
              Login Method: {user.identities.map((identity) => IDENTITY[identity.provider]).join(', ')}
            </Typography.Body>
          </Flex>
          <Divider />
        </>
      )}
      <Typography.Body>Patient Created: </Typography.Body>
      {user.referral && <Typography.Body>{dayjs(user.referral?.created_at).format('MM/DD/YYYY')}</Typography.Body>}
    </Flex>
  );
}
