import { Button, styled } from '@solace-health/ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: sticky;
  top: 64px;
  background: #fff;
  overflow: hidden;
  z-index: 99;
`;

export const OmniscientContainer = styled.div`
  display: flex;
  flex: 1 1 0;
  background-color: #a21212;
  flex-direction: column;
  align-items: center;
  padding: 12px 0px;
`;

export const StyledButton = styled(Button.Primary)`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  max-width: 260px;
`;
