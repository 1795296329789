import useSWR from 'swr';
import fetcher from './fetcher';
import { SolaceAPI } from '../utils/api';
import { Skill } from './useSkills';

export enum SearchModelSlug {
  GRAY = 'gray',
  YELLOW = 'yellow',
  ORANGE = 'orange',
  PINK = 'pink',
  GREEN = 'green',
}

export type SearchModel = {
  id: string;
  slug: SearchModelSlug;
  engine_id: string;
  name: string;
  search_codes: SearchCode[];
};

export type SearchCode = {
  id: string;
  code: string;
  model: SearchModel;
  description: string;
  priority: number;
  distance_score_multiplier: number;
  value: number;
  advocate_profile_context: string;
  search_result_context: string;
  skills: { id: string }[];
};

const useSearchModels = () => {
  const {
    data: searchModels,
    error,
    mutate: refresh,
  } = useSWR<SearchModel[]>('/v1/api/search_models?include[]=search_codes.skills', fetcher);

  const saveModel = async ({ slug, body }: { slug: SearchModel['slug']; body: Pick<SearchModel, 'name' | 'engine_id'> }) => {
    return SolaceAPI.put({
      path: `/api/search_models/${slug}`,
      body,
    }).then((res) => {
      refresh();
      return res;
    });
  };

  const saveCode = async ({ searchCode, skills }: { searchCode: Omit<SearchCode, 'skills'>; skills: Skill['id'][] }) => {
    return SolaceAPI.post({
      path: `/api/search_codes`,
      body: { ...searchCode, skills: Object.values(skills) },
    }).then((res) => {
      refresh();
      return res;
    });
  };

  return {
    loading: searchModels === undefined,
    data: searchModels || [],
    error,
    saveModel,
    saveCode,
  };
};

export default useSearchModels;
