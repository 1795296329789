import useSWR from 'swr';
import fetcher from './fetcher';
import { Listing } from '../types/listing';
import { User } from '../types/user';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import qs from 'qs';

dayjs.extend(objectSupport);

export type CommunityHealthListing = Listing & { bookings_count: number } & {
  user: User & { address: { city: string; state: string } | null };
};

export type CommunityHealthAppointments = {
  [date: string]: {
    [time: string]: {
      start: string;
      end: string;
      listings: CommunityHealthListing[];
    };
  };
};

export const usePhysicianAvailability = ({ month, year, user }: { month: number; year: number; user: User }) => {
  const monthOffset = month - 1;
  const startDate = dayjs({ year, month: monthOffset }).toISOString();
  const endDate = dayjs({ year, month }).endOf('month').toISOString();

  const { data, error, mutate } = useSWR<CommunityHealthAppointments>(
    user?.id
      ? `/v1/api/admin/physician_availability?${qs.stringify(
          { start_dt: startDate, end_dt: endDate, time_zone: user.time_zone, user_id: user.id },
          { skipNulls: true },
        )}`
      : null,
    fetcher,
  );

  return {
    loading: data === undefined,
    data: data || ({} as CommunityHealthAppointments),
    error,
    refresh: mutate,
  };
};
