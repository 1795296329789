const errors: { [key: string]: string } = {
  INVALID_PARAMS: 'Error: Invalid params',
  TOKEN_DOES_NOT_EXIST: "Error: Token Doesn't exist",
  TOKEN_EXPIRED: 'Error: This token has expired',
  UNKNOWN_ERROR: 'Error: An unknown error has ocurred',
};

export enum BadEligibilityCheckRequestInvalidField {
  Name = 'name',
  Dob = 'dob',
  MemberId = 'memberId',
  NameOrDob = 'nameOrDob',
}

// An error class that translates errors received from solace-health-server
export class SolaceApiError extends Error {
  error: string;

  errorCode: string;

  status: number;

  invalidField?: BadEligibilityCheckRequestInvalidField;

  rawResponse: unknown;

  requestId?: string;

  constructor(data: {
    error: string;
    errorCode: string;
    status: number;
    message?: string | string[];
    invalidField?: string;
    request_id?: string;
  }) {
    const error = Array.isArray(data.message) ? data.message.join(', ') : data.error || data.message;
    super(error);

    this.name = this.constructor.name;
    this.error = error || '';
    this.errorCode = data.errorCode;
    this.status = data.status;
    this.invalidField = data?.invalidField as BadEligibilityCheckRequestInvalidField;
    this.rawResponse = data as unknown;
    this.requestId = data.request_id || 'Unknown';
  }
}

export default errors;
