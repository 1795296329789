import styled from '@emotion/styled';
import { Flex } from '@solace-health/ui';

export const PatientCard = styled(Flex)`
  padding: 1rem;
  border-radius: var(--border-radius-lg, 0.5rem);
  border: 1px solid #afc8bf;
  background: var(--green-100, #fff);

  box-shadow:
    0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.1);
`;
