import { Flex, Icons, Radio, Size, Typography, styled } from '@solace-health/ui';
import { timeZoneName, timeInOriginalTimeZone, getAppointmentDurationInMinutes } from '../../../../../../utils/general';
import { StyledButton } from '../../style';
import { AvailableAppointments } from '../AvailableAppointments';
import { SolaceAPI } from '../../../../../../utils/api';
import { SolaceApiError } from '../../../../../../utils/errors';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { CommunityHealthAppointments, CommunityHealthListing } from '../../../../../../hooks/useCHWAvailability';
import { HighlightButton } from '../style';
import { GetUserResponse } from '../../../../../../hooks/useGetUser';
import { Booking } from '../../../../../../types/booking';

const TimeBlock = styled(HighlightButton)<{ selected: boolean }>`
  padding: 12px 16px;
`;

type Props = {
  availability: CommunityHealthAppointments;
  date: {
    full: string;
    month: number;
    year: number;
  };
  refresh: () => void;
  user: GetUserResponse;
  setShowConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  setBooking: (booking: Booking & { listing: CommunityHealthListing }) => void;
  showPhysicianAvailability: boolean;
  isIntroCall: boolean;
  originalBooking?: Booking | null;
};

export const MeetingInfo = ({
  availability,
  date,
  refresh,
  user,
  setShowConfirmation,
  setBooking,
  showPhysicianAvailability,
  isIntroCall,
  originalBooking,
}: Props) => {
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [selectedListingId, setSelectedListingId] = useState<string | null>(null);
  const [callType, setCallType] = useState<string>('phone');

  useEffect(() => {
    setSelectedTime(times[0]);
  }, [date.full]);

  const timesObj = availability[date.full] || {};
  const rawTimes = Object.keys(timesObj || {});
  const times = rawTimes.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  const timeSlot = selectedTime ? timesObj[selectedTime] : null;
  let listings: CommunityHealthListing[] = [];
  let firstAvailableListing: CommunityHealthListing | null = null;

  if (times.length > 0 && selectedTime && timesObj[selectedTime]) {
    const allListings = timesObj[selectedTime].listings;

    const sortedListings = allListings.sort((a, b) => a.bookings_count - b.bookings_count);
    firstAvailableListing = sortedListings[0];
    listings = sortedListings.slice(1);
  } else {
    firstAvailableListing = null;
    listings = [];
  }

  const selectedListing: CommunityHealthListing | null =
    [firstAvailableListing, ...listings].find((listing) => listing?.id === selectedListingId) || null;

  const { enqueueSnackbar } = useSnackbar();

  const onScheduleMeeting = () => {
    if (!timeSlot) return;

    const response = originalBooking
      ? SolaceAPI.post<Booking>({
          path: `/api/booking/${originalBooking.id}/reschedule`,
          body: {
            start: timeSlot.start,
            end: timeSlot.end,
            listing_id: selectedListingId,
            call_type_requested: callType,
          },
        })
      : SolaceAPI.post<Booking>({
          path: '/api/booking/community_health',
          body: {
            client_id: user.sharetribe_uuid,
            start: timeSlot.start,
            end: timeSlot.end,
            listing_id: selectedListingId,
            is_intro_call: isIntroCall,
            is_video_call: true,
            call_type_requested: callType,
          },
        });

    response
      .then((response) => {
        setBooking({ ...response.data, listing: selectedListing as CommunityHealthListing });
        refresh();
        setShowConfirmation(true);
      })
      .catch((response: SolaceApiError) => {
        enqueueSnackbar(`Failed to ${originalBooking ? 'reschedule' : 'schedule'} meeting - ${response.error}`, {
          variant: 'error',
        });
      });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography.Header size={Size.XS}>{timeZoneName(user.time_zone, { includeAbbr: true })}</Typography.Header>
        <Typography.Header size={Size.XS}>
          <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Icons.Schedule /> {getAppointmentDurationInMinutes(timeSlot?.start, timeSlot?.end)} min call
          </span>
        </Typography.Header>
      </div>

      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px', width: '386px' }}>
        {times.map((time) => (
          <div key={time} style={{ width: '118px' }}>
            <TimeBlock onClick={() => setSelectedTime(time)} selected={time === selectedTime}>
              <div>{timeInOriginalTimeZone(time)}</div>
            </TimeBlock>
          </div>
        ))}
      </div>

      {!firstAvailableListing ? (
        <Typography.Header>No available appointments</Typography.Header>
      ) : (
        <AvailableAppointments
          firstAvailableListing={firstAvailableListing}
          selectedListingId={selectedListingId}
          setSelectedListingId={setSelectedListingId}
          listings={listings}
          showPhysicianAvailability={showPhysicianAvailability}
        />
      )}

      <Flex vertical gap="1rem">
        <Flex vertical>
          <Typography.Header size={Size.XS}>Call Type</Typography.Header>
          <Typography.Body size={Size.XS}>
            How does the patient want to meet with the {showPhysicianAvailability ? 'physician' : 'advocate'}?
          </Typography.Body>
        </Flex>
        <Radio
          options={[
            { label: 'Phone Call', value: 'phone' },
            { label: 'Video Call', value: 'video' },
          ]}
          onChange={(e) => setCallType(e.target.value)}
          value={callType}
        />
      </Flex>

      {firstAvailableListing && (
        <Flex justify="center">
          <StyledButton onClick={onScheduleMeeting} disabled={!selectedListingId}>
            <Icons.Video color="#FFF" />
            <Typography.Header size={Size.SM}>{originalBooking ? 'Reschedule' : 'Schedule'} Meeting</Typography.Header>
          </StyledButton>
        </Flex>
      )}
    </div>
  );
};
