import { Button, Flex, Segmented, TagStatus, Tags, Tooltip, Typography } from '@solace-health/ui';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ArchiveIcon from '@mui/icons-material/Archive';
import { useMemo, useState } from 'react';
import { useQuery } from '../../../../hooks/useQuery';
import {
  BookingHelpRequest,
  BookingHelpRequestFilter,
  BookingHelpRequestStatus,
  BookingHelpRequestType,
} from '../../../../hooks/prospects/useGetBookingHelpRequests';
import { AdminTable } from '../../../../components/shared/Table';
import { phoneNumberFormat } from '../../../../utils/general';
import { SolaceAPI } from '../../../../utils/api';
import dayjs from 'dayjs';
import { snakeToSentenceCase } from '../../../../shared/utils/gen-utils';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { SolaceApiError } from '../../../../utils/errors';
import { LastContactButton } from '../../../../shared/components/LastContactButton';
import { useGetPaginatedBookingHelpRequests } from '../../../../hooks/prospects/useGetPaginatedBookingHelpRequests';

enum Tabs {
  Pending = 'pending',
  Complete = 'complete',
  Archived = 'archived',
}

export const ProspectsPage = () => {
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const query = useQuery();
  const tab = query.get('tab') as Tabs;
  const searchTerm = query.get('search') || undefined;
  const [activeTab, setActiveTab] = useState<Tabs>(tab || Tabs.Pending);

  let filter: BookingHelpRequestFilter = { type: BookingHelpRequestType.RequestCallback };
  if (activeTab === Tabs.Pending) filter = { ...filter, isNull: ['completed_dt', 'deleted_dt'] };
  if (activeTab === Tabs.Complete) filter = { ...filter, notNull: 'completed_dt' };
  if (activeTab === Tabs.Archived) filter = { ...filter, notNull: 'deleted_dt' };

  const {
    data: bookingHelpRequests,
    loading,
    refresh,
    setQueryParams,
    resetQueryParams,
    pagination,
  } = useGetPaginatedBookingHelpRequests({ filter, search: searchTerm });

  const createPatient = async ({ prospectId }: { prospectId: string }) => {
    history(`/patients?p_id=${prospectId}`);
  };

  const archiveBookingHelpRequest = async ({ id }: { id: BookingHelpRequest['id'] }) => {
    try {
      await SolaceAPI.put({ path: `/api/booking_help_requests/${id}`, body: { deleted_dt: new Date().toISOString() } });
      refresh();
    } catch (e) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`Failed to archive prospect - ${error.message}`, { variant: 'error' });
      return;
    }

    enqueueSnackbar(`Successfully archived prospect`, { variant: 'success' });
  };

  const updateLastContact = async ({ id }: { id: string }) => {
    let response;
    try {
      response = await SolaceAPI.put<BookingHelpRequest>({
        path: `/api/booking_help_requests/${id}`,
        body: { last_contact_dt: new Date().toISOString() },
      });
      refresh();
    } catch (e) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`Failed to update last contact - ${error.message}`, { variant: 'error' });
      return;
    }

    let message = `Successfully updated last contact.`;
    if (response.data.deleted_dt) message += ' Prospect contacted 3 times, auto archiving...';
    enqueueSnackbar(message, { variant: 'success' });
  };

  const { columns, tableData } = useMemo(() => {
    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        render: (date: string) => dayjs(date).format('MM/DD/YYYY hh:mm a z'),
        defaultSortOrder: 'descend',
        sorter: (a: BookingHelpRequest, b: BookingHelpRequest) => {
          return new Date(a.requested_dt).getTime() - new Date(b.requested_dt).getTime();
        },
      },
      {
        title: 'Short ID',
        dataIndex: 'short_id',
      },
      {
        title: 'Prospect Name',
        dataIndex: 'full_name',
      },
      {
        title: 'Phone #',
        dataIndex: 'phone',
        render: (phone: string) => phoneNumberFormat(phone),
      },
      {
        title: 'Status',
        onFilter: (value: BookingHelpRequestStatus, bookingHelpRequest: BookingHelpRequest) => {
          if (value === BookingHelpRequestStatus.Complete) {
            return !!bookingHelpRequest.completed_dt;
          } else if (value === BookingHelpRequestStatus.Pending) {
            return !bookingHelpRequest.completed_dt;
          }

          return true;
        },
        filters: [
          { text: 'Complete', value: BookingHelpRequestStatus.Complete },
          { text: 'Pending', value: BookingHelpRequestStatus.Pending },
        ],
        render: (bookingHelpRequest: BookingHelpRequest) => {
          if (bookingHelpRequest.completed_dt) {
            return (
              <Tooltip
                content={`Completed by ${bookingHelpRequest.resolved_by.first_name} on ${dayjs(
                  bookingHelpRequest.completed_dt,
                ).format('MM/DD/YYYY hh:mm a z')}`}
              >
                <Tags.Rectangle tagStatus={TagStatus.Completed}>Complete</Tags.Rectangle>
              </Tooltip>
            );
          }

          if (bookingHelpRequest.deleted_dt) {
            return (
              <Tooltip
                content={`Archived by ${bookingHelpRequest.resolved_by.first_name} on ${dayjs(
                  bookingHelpRequest.deleted_dt,
                ).format('MM/DD/YYYY hh:mm a z')}`}
              >
                <Tags.Rectangle tagStatus={TagStatus.DefaultBlack}>Archived</Tags.Rectangle>
              </Tooltip>
            );
          }

          return <Tags.Rectangle tagStatus={TagStatus.Warning}>Pending</Tags.Rectangle>;
        },
      },
      {
        title: 'Last Contact',
        render: (bookingHelpRequest: BookingHelpRequest) => {
          return (
            <Flex vertical>
              <LastContactButton onClick={() => updateLastContact({ id: bookingHelpRequest.id })}>
                <Typography.Body>
                  {bookingHelpRequest.last_contact_dt
                    ? new Date(bookingHelpRequest.last_contact_dt).toLocaleDateString()
                    : 'N/A'}
                </Typography.Body>
              </LastContactButton>
              <sub># of Contacts: {bookingHelpRequest.contacted_count}</sub>
              {bookingHelpRequest.last_contact_by && (
                <sub>Last Contact By: {bookingHelpRequest.last_contact_by.first_name}</sub>
              )}
            </Flex>
          );
        },
      },
      {
        title: ' ',
        render: (bookingHelpRequest: BookingHelpRequest) => (
          <Flex align="center" gap="1rem">
            {!bookingHelpRequest.completed_dt ? (
              <Tooltip content={'Create patient'}>
                <Button.Link onClick={() => createPatient({ prospectId: bookingHelpRequest.prospect_id })}>
                  <AddBoxIcon style={{ color: '#1D4339' }} />
                </Button.Link>
              </Tooltip>
            ) : null}
            {!bookingHelpRequest.completed_dt && !bookingHelpRequest.deleted_dt ? (
              <Tooltip content={'Archive patient'}>
                <Button.Link onClick={() => archiveBookingHelpRequest({ id: bookingHelpRequest.id })}>
                  <ArchiveIcon style={{ color: '#BFA26C' }} />
                </Button.Link>
              </Tooltip>
            ) : null}
          </Flex>
        ),
      },
    ];

    const tableData = bookingHelpRequests.map((bookingHelpRequest) => ({
      date: bookingHelpRequest.requested_dt,
      short_id: bookingHelpRequest.prospect.short_id,
      full_name: `${bookingHelpRequest.prospect.first_name} ${bookingHelpRequest.prospect.last_name}`,
      ...bookingHelpRequest,
    }));

    return { columns, tableData };
  }, [bookingHelpRequests]);

  const handleTabChange = (val: Tabs) => {
    setActiveTab(val);
    resetQueryParams();
  };

  return (
    <Flex vertical gap="2rem">
      <Typography.Display>Prospects</Typography.Display>
      <div>
        <Segmented
          defaultValue={Tabs.Pending}
          options={Object.values(Tabs).map((type) => ({
            label: snakeToSentenceCase(type),
            value: type,
          }))}
          onChange={(e) => handleTabChange(e as Tabs)}
        />
      </div>

      <AdminTable
        showPagination={true}
        columns={columns}
        data={tableData}
        loading={loading}
        showSearch
        defaultSearch={searchTerm}
        onRowClick={() => null}
        pagination={pagination}
        onChange={setQueryParams}
      />
    </Flex>
  );
};
