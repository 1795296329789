import { Button, Flex, Modal, Typography } from '@solace-health/ui';
import { useState } from 'react';
import PayorInfoForm from './PayorInfoForm';

type Props = {
  isOpen?: boolean;
  payorId?: string;
  onClose?: () => void;
  onUpdate?: () => void;
};

const UpsertPayorModal = ({ isOpen = false, payorId = undefined, onClose = () => null, onUpdate = () => null }: Props) => {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const onSubmit = () => {
    onClose();
    onUpdate();
  };

  const handleOnClose = () => {
    setConfirmOpen(false);
    onClose();
  };

  const confirmClose = () => setConfirmOpen(true);

  return (
    <>
      <Modal isOpen={isOpen} onClose={confirmClose} destroyOnClose>
        <PayorInfoForm onSubmit={onSubmit} currentPayorId={payorId} />
      </Modal>

      <Modal isOpen={confirmOpen} onClose={() => setConfirmOpen(false)} destroyOnClose>
        <Flex vertical gap={8}>
          <Typography.Header>Are you sure about that?</Typography.Header>
          <Typography.Body>Exiting will remove all unsaved progress...</Typography.Body>
        </Flex>
        <Flex gap={20} style={{ marginTop: 20 }}>
          <Button.Outline onClick={() => setConfirmOpen(false)}>Go Back</Button.Outline>
          <Button.Primary onClick={handleOnClose}>Confirm</Button.Primary>
        </Flex>
      </Modal>
    </>
  );
};

export default UpsertPayorModal;
