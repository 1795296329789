import { Button, Flex, Icons, Segmented, Typography } from '@solace-health/ui';
import { AdminTable } from '../../../../components/shared/Table';
import { useState } from 'react';
import useGetVisits from '../../../../hooks/useGetVisits';
import { TraitId, UserTimeZone } from '../../../../types/user';
import dayjs from 'dayjs';
import { hasTrait } from '../../../../utils/user';
import { timeZoneAbbr } from '../../../../utils/general';
import { Booking, BookingState } from '../../../../types/booking';
import { textCompare } from '../../../../shared/utils/table-utils';
import StatusPill from '../../../../components/sharedStatusPill/StatusPill';
import { useNavigate } from 'react-router-dom';
import timezone from 'dayjs/plugin/timezone';
import VisitsAndEncounterDrawer from '../PatientPage/VisitsAndEncounters/VisitsAndEncounterDrawer/VisitsAndEncounterDrawer';
import { CareTimelineTypes } from '../../../../hooks/useGetUserCareTimeline';
import { SchedulingDrawer } from '../PatientPage/SchedulingDrawer/SchedulingDrawer';
import useGetUser, { GetUserResponse } from '../../../../hooks/useGetUser';

dayjs.extend(timezone);

type TableBooking = {
  startDate: string;
  startTime: string;
  patientName: string;
  advocateName: string;
  type: string;
  status: BookingState;
} & Booking;

const statusPillOptions = (state: BookingState) => {
  switch (state) {
    case BookingState.Completed:
      return {
        borderColor: 'var(--border-color-dark-green, #285E50)',
        backgroundColor: 'var(--green-100, #F4F8F7)',
        text: 'Occurred',
      };
    case BookingState.ClientNoShow:
      return {
        borderColor: 'var(--border-color-danger, #DA4D54)',
        backgroundColor: 'var(--danger-bg-100, #F1E1E2)',
        text: 'Patient no-show',
      };
    case BookingState.Rescheduled:
      return {
        borderColor: 'var(--border-color-dark, #555)',
        backgroundColor: 'var(--grey-100, #EEE)',
        text: 'Rescheduled',
      };
    case BookingState.Accepted:
      return {
        borderColor: 'var(--border-color-warning, #D7A13B)',
        backgroundColor: 'var(--gold-100, #FFF7E9)',
        text: 'Scheduled',
      };
    default:
      return {
        borderColor: '',
        backgroundColor: '',
        text: '',
      };
  }
};

function getConditionalAdvocateColumnConfig(selectedTab: TraitId | null, visitData: Array<{ advocateName: string }>) {
  const advocateNamesForVisits = visitData.map((visit) => {
    return visit.advocateName;
  });

  const uniqueAdvocateNames = new Set(advocateNamesForVisits);

  if (selectedTab === TraitId.CommunityHealthPhysician) {
    return {
      filters: Array.from(uniqueAdvocateNames).map((advocateName) => {
        return { text: advocateName, value: advocateName };
      }),
      onFilter: (value: string, record: TableBooking) => record.advocateName === value,
    };
  }

  return {
    sorter: (a: TableBooking, b: TableBooking) => textCompare(a, b, 'advocateName'),
  };
}

export default function VisitsPage() {
  const history = useNavigate();
  const activeUsersTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [activeAppointment, setActiveAppointment] = useState<Booking | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [schedulingDrawerOpen, setSchedulingDrawerOpen] = useState(false);

  const [activeFilter, setFilter] = useState<TraitId | null>(TraitId.CommunityHealthPhysician);

  const { data, refresh, isLoading } = useGetVisits({ trait: activeFilter });

  const { data: user } = useGetUser({
    id: activeAppointment?.client_id,
    includes: [
      'address',
      'client_bookings.advocate.traits',
      'identities',
      'patient_care_plans',
      'physician_visits',
      'referral.prospect',
      'referral.user.client_bookings',
      'referral.user.patient_care_plans',
      'referral.user.physician_visits',
      'traits',
    ],
  });

  const appointmentType = (booking: Booking) => {
    if (!booking?.advocate) return 'n/a';
    if (hasTrait({ user: booking.advocate, traitId: TraitId.CommunityHealthPhysician })) return 'Clinician Visit';
    if (booking.is_intro_call) return 'CHW Intro';
    return 'CHW Follow-up';
  };

  const formattedData = data.map((visit) => ({
    ...visit,
    startDate: dayjs(visit.start).tz(activeUsersTimeZone).format('MM/DD/YYYY'),
    startTime: `${dayjs(visit.start).tz(activeUsersTimeZone).format('h:mma')} ${timeZoneAbbr(
      activeUsersTimeZone as UserTimeZone,
    )}`,
    patientName: `${visit.client?.first_name} ${visit.client?.last_name}`,
    advocateName: `${visit.advocate?.first_name} ${visit.advocate?.last_name}`,
    type: appointmentType(visit),
    status:
      dayjs(visit.end).isBefore(dayjs()) && visit.state === BookingState.Accepted ? BookingState.Completed : visit.state,
    video_call: visit.video_call,
    key: visit.id,
  }));

  const columns = [
    {
      title: 'Date',
      dataIndex: 'startDate',
      defaultSortOrder: 'descend',
      sorter: (a: TableBooking, b: TableBooking) =>
        dayjs(a.start).tz(activeUsersTimeZone).diff(dayjs(b.start).tz(activeUsersTimeZone)),
    },
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      sorter: (a: TableBooking, b: TableBooking) =>
        dayjs(a.start).tz(activeUsersTimeZone).diff(dayjs(b.start).tz(activeUsersTimeZone)),
    },
    {
      title: 'Patient Name',
      dataIndex: 'patientName',
      render: (patientName: string, record: TableBooking) => (
        <Button.Link onClick={() => history(`/patients/${record.client_id}`)} color="#285E50">
          {patientName}
        </Button.Link>
      ),
      sorter: (a: TableBooking, b: TableBooking) => textCompare(a, b, 'patientName'),
    },
    {
      title: 'Advocate',
      dataIndex: 'advocateName',
      render: (advocateName: string, record: TableBooking) => (
        <Button.Link onClick={() => history(`/patients/${record.advocate_id}`)} color="#285E50">
          {advocateName}
        </Button.Link>
      ),
      ...getConditionalAdvocateColumnConfig(activeFilter, formattedData),
    },

    {
      title: 'Type',
      dataIndex: 'type',
      filters: [
        { text: 'Clinician Visit', value: 'Clinician Visit' },
        { text: 'CHW Intro', value: 'CHW Intro' },
        { text: 'CHW Follow-up', value: 'CHW Follow-up' },
        { text: 'Unknown', value: 'n/a' },
      ],
      onFilter: (value: string, record: TableBooking) => record.type.indexOf(value) === 0,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: BookingState) => {
        const { borderColor, backgroundColor, text } = statusPillOptions(status);
        if (!text) return 'n/a';
        return (
          <StatusPill
            borderColor={borderColor}
            backgroundColor={backgroundColor}
            strikeThrough={status === BookingState.Rescheduled}
          >
            {text}
          </StatusPill>
        );
      },
      filters: [
        { text: 'Scheduled', value: BookingState.Accepted },
        { text: 'Rescheduled', value: BookingState.Rescheduled },
        { text: 'Completed', value: BookingState.Completed },
        { text: 'Patient no-show', value: BookingState.ClientNoShow },
      ],
      onFilter: (value: string, record: TableBooking) => record.status.indexOf(value) === 0,
    },
    {
      title: '',
      dataIndex: 'key',
      render: () => (
        <Button.Unstyled>
          <Icons.Caret direction="right" />
        </Button.Unstyled>
      ),
    },
  ];

  const handleClose = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <Flex vertical gap="1rem">
        <Typography.Display>Clinician Visits</Typography.Display>
        <div>
          <Segmented
            options={[
              { label: 'Clinician Visit', value: TraitId.CommunityHealthPhysician },
              { label: 'CHW Visit', value: TraitId.CommunityHealthAdvocate },
              { label: 'All Visits', value: '' },
            ]}
            onChange={(e) => (!!e ? setFilter(e as TraitId) : setFilter(null))}
          />
        </div>
        <AdminTable
          columns={columns}
          data={formattedData}
          onRowClick={(record: TableBooking) => {
            setActiveAppointment(record);
            setDrawerOpen(true);
          }}
          loading={isLoading}
        />
      </Flex>

      <VisitsAndEncounterDrawer
        isOpen={drawerOpen}
        data={{ type: CareTimelineTypes.Visit, data: activeAppointment ?? ({} as Booking) }}
        onClose={handleClose}
        refresh={refresh}
        clearSelectedData={() => setActiveAppointment(null)}
        setSchedulingDrawerOpen={setSchedulingDrawerOpen}
      />

      <SchedulingDrawer
        user={user ?? ({} as GetUserResponse)}
        isOpen={schedulingDrawerOpen}
        handleClose={() => {
          setSchedulingDrawerOpen(false);
          setActiveAppointment(null);
        }}
        refresh={refresh}
        originalBooking={activeAppointment}
      />
    </>
  );
}
