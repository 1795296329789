import useSWR from 'swr';
import fetcher from './fetcher';
import qs from 'qs';

type Props = {
  include?: ('roles' | 'invitee_invitations' | 'stripe_account' | 'invitee_invitations.inviter')[];
};

const useCurrentUser = <T = unknown>({ include = [] }: Props) => {
  const urlParams = qs.stringify({ include });
  const { data, error } = useSWR<T>(`/v1/api/current_user?${urlParams}`, fetcher);

  return {
    data,
    loading: data === undefined,
    error,
  };
};

export default useCurrentUser;
