import { orderBy } from 'lodash';
import useSWR from 'swr';
import fetcher from './fetcher';
import { Skill } from './useSkills';

export type SearchSkill = {
  id: string;
  skill_id: Skill['id'];
  keywords: string[];
  value: number;
  distance_score_multiplier: number;
  priority: number;
  search_result_context: string | null;
  advocate_profile_context: string | null;
  deactivated_dt: Date | null;
  skill: Skill;
};

const useGetSearchSkills = () => {
  const { data: searchSkills, error, mutate } = useSWR<SearchSkill[]>('/v1/api/search_skills?include[]=skill', fetcher);

  return {
    loading: searchSkills === undefined,
    data: orderBy(searchSkills, 'skill.name') || [],
    error,
    refresh: mutate,
  };
};

export default useGetSearchSkills;
