import { Button, Icons, styled } from '@solace-health/ui';
const StyledButton = styled(Button.Link)`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;

  span {
    flex: 1;
    text-decoration: underline;
  }

  &:after {
    content: none;
  }
`;

type Props = {
  label: string;
  onClick: () => void;
};

const BackButton = ({ onClick, label }: Props) => {
  return (
    <StyledButton onClick={onClick}>
      <Icons.Caret direction="left" />
      <span>{label}</span>
    </StyledButton>
  );
};

export default BackButton;
