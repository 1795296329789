import { User } from './user';
import { Address } from './forms';
import { Prospect } from './prospect';
import { Payor } from './payor';

export enum ReferralStatus {
  Eligible = 'eligible',
  Ineligible = 'ineligible',
  Unchecked = 'unchecked',
}

export enum PlaceOfServiceCode {
  Telehealth = '02',
  TelehealthPatientHome = '10',
}

export enum DependentRelationshipType {
  Spouse = 'Spouse',
  Grandparent = 'Grandparent',
  Grandchild = 'Grandchild',
  NieceNephew = 'Niece/Nephew',
  FosterChild = 'Foster Child',
  WardOfTheCourt = 'Ward of the Court',
  Stepchild = 'Stepchild',
  Self = 'Self',
  Child = 'Child',
  Employee = 'Employee',
  Unknown = 'Unknown',
  HandicappedDependent = 'Handicapped Dependent',
  SponsoredDependent = 'Sponsored Dependent',
  DependentOfMinorDependent = 'Dependent of Minor Dependent',
  SignificantOther = 'Significant Other',
  Mother = 'Mother',
  Father = 'Father',
  EmancipatedMinor = 'Emancipated Minor',
  OrganDonor = 'Organ Donor',
  CadaverDonor = 'Cadaver Donor',
  InjuredPlaintiff = 'Injured Plaintiff',
  ChildNoFinancialResponsibility = 'Child (No Financial Responsibility)',
  LifePartner = 'Life Partner',
  OtherRelationship = 'Other Relationship',
}

export type Provider = {
  id: string;
  referring_provider_name: string;
  practice_name: string;
  address_id: string;
  npi_number: string;
  phone: string;
  fax: string;
  provider_specialty: string;
  created_at: Date;
  updated_at: Date;
  address: Address;
};

export type Referral = {
  id: string;
  user_id: string;
  provider_id: string;
  email: string | null;
  eligibility_check_data: ReferralFormInfo;
  status: ReferralStatus;
  last_submitted_by_id: string;
  deleted_dt: Date | null;
  updated_at: Date;
  created_at: Date;
  last_contact_dt: Date | null;
  user: User;
  state: ReferralState;
  payor_id: string | null;
  cpt_config_id: string | null;
  prospect_id?: string | null;
  prospect?: Prospect;
  insurance_address_id: string | null;
  insurance_address: Address | null;
  first_name?: string;
  last_name?: string;
  sex: PatientSexOptions;
  payor?: Payor;
};

export enum ReferralEligibilityCheckStatus {
  Unchecked = 'unchecked',
  Failed = 'failed',
  Eligible = 'eligible',
  Ineligible = 'ineligible',
}

export type ReferralEligibilityCheck = {
  id: string;
  submitted_dt: string;
  request_payload: Record<string, unknown>;
  result_payload: Record<string, unknown>;
  status: ReferralEligibilityCheckStatus;
  status_reason: string;
};

export enum ReferralState {
  NeedsScheduled = 'needs_scheduled',
  NeedsInsuranceCheck = 'needs_insurance_check',
  CallScheduled = 'call_scheduled',
  CarePlanOnFile = 'care_plan_on_file',
  NA = 'n/a',
}

export enum PatientSexOptions {
  Male = 'male',
  Female = 'female',
}

export enum ContactMethods {
  Phone = 'phone',
  Email = 'email',
}

export enum ReferralPriorityOptions {
  Routine = 'routine',
  Urgent = 'urgent',
}

export enum ReferralUnavailableOptions {
  Yes = 'yes',
  No = 'no',
  Contact = 'contact',
}

export enum InsuranceType {
  MediCal = 'CACAI1',
}

export enum ReferralReasons {
  Consultation = 'consultation',
  SecondOpinion = 'secondOpinion',
  Procedure = 'procedure',
  Other = 'other',
}

export type ReferralFormGeneralFields = {
  sender_fax: string;
  sender_phone?: string;
  sender_message?: string;
  patient_first_name: string;
  patient_middle_name?: string;
  patient_last_name: string;
  patient_sex?: PatientSexOptions | null;
  patient_dob: string;
  patient_email?: string;
  patient_phone: string;
  patient_alt_contact?: string;
  patient_preferred_contact: ContactMethods;
  patient_address_street?: string;
  patient_address_city?: string;
  patient_address_state?: string;
  patient_address_zip?: string;
  patient_address_extra?: string;
  patient_interpreter_needed?: boolean;
  patient_language?: string;
  referral_priority?: ReferralPriorityOptions;
  referral_details?: string;
  referral_icd_code: string;
  referral_icd_description: string;
  referral_clinic?: string;
  referral_physician?: string;
  referral_location?: string;
  referral_unavailable_options?: ReferralUnavailableOptions;
  referral_reason?: ReferralReasons;
  provider_id: string;
  attachments?: string[];
  referral_form?: string;
  payor_id?: string;
  cpt_config_id?: string;
  primary_insurance?: {
    member_id?: string;
    ssn?: string;
    card_num?: string;
  };
  secondary_insurances: {
    member_id?: string;
    ssn?: string;
    payor_id?: string;
    card_num?: string;
  }[];
};

export type ReferralFormDependentFields = {
  dependent: boolean;
  dependent_relationship: DependentRelationshipType | null;
  subscriber_first_name: string;
  subscriber_last_name: string;
  subscriber_dob: string;
  subscriber_sex: PatientSexOptions | null;
};

export type ReferralFormInfo =
  | (ReferralFormDependentFields & ReferralFormGeneralFields)
  | ({ dependent: false } & ReferralFormGeneralFields);

export enum ReferralFormTypes {
  REFERRAL_FORM = 'referral_form',
  OTHER = 'other',
}
