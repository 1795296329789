import { Accordion, AccordionDetails, AccordionSummary, List, ListItemText } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { get } from 'lodash';
import useGetListing from '../../../hooks/useGetListing';
import { User } from '../../../types/user';
import { Flex, Size, Typography } from '@solace-health/ui';

type Props = {
  user: User;
};

export const Listing = ({ user }: Props) => {
  const {
    data: { listing, profileSteps },
    loading,
  } = useGetListing({ id: user.sharetribe_default_listing_uuid as string });
  const serviceAreas = get(listing, 'service_areas', []);

  if (loading || !listing) return null;

  const hasCompletedProfile = listing.has_completed_profile && Object.keys(profileSteps).every((step) => profileSteps[step]);

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography.Header>Advocate Profile - {hasCompletedProfile ? 'Complete ✅' : 'Incomplete ❌'}</Typography.Header>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {Object.keys(profileSteps).map((step) => (
              <div key={step}>
                <strong>
                  {step
                    .split(/(?=[A-Z])/)
                    .slice(1)
                    .join(' ')}
                </strong>
                : <span style={{ color: profileSteps[step] ? 'green' : 'red' }}>{profileSteps[step] ? 'Yes' : 'No'}</span>
              </div>
            ))}
          </List>
          <div>
            <Typography.Header size={Size.SM}>Service Areas</Typography.Header>
            <Flex vertical gap={12}>
              {listing.is_nationwide && <ListItemText primary="Nationwide" />}
              {serviceAreas.map((serviceArea) => (
                <ListItemText primary={serviceArea.name} key={serviceArea.id} />
              ))}
            </Flex>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
