export const Top = () => {
  return (
    <svg
      width="450"
      height="207"
      viewBox="0 0 450 207"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: 'absolute', left: 0, zIndex: 0, top: 0 }}
    >
      <path
        d="M59.2161 58.1741L59.2151 58.1721C27.6098 -3.7744 26.9666 -78.6167 52.7492 -142.471C78.9189 -206.11 131.211 -258.669 199.628 -285.72C268.078 -312.784 352.495 -314.594 410.593 -275.973C468.798 -237.041 501.061 -157.696 490.588 -93.9324C485.355 -62.0718 469.498 -34.1674 448.306 -8.02008C428.381 16.563 403.766 39.5632 378.869 62.8269C377.28 64.3122 375.689 65.7985 374.098 67.2864C373.351 67.9857 372.603 68.6852 371.855 69.3847C346.095 93.4851 320.278 117.639 293.938 136.051C266.833 154.997 239.231 167.821 210.635 168.268C181.841 168.61 151.822 156.618 125.07 136.757C98.3208 116.898 74.8703 89.1939 59.2161 58.1741Z"
        stroke="white"
      />
      <path
        d="M284.562 -372.26L284.564 -372.259C348.317 -344.479 396.343 -287.074 416.978 -221.375C437.177 -155.597 430.161 -81.7888 394.489 -17.4434C358.802 46.9318 294.741 101.939 225.336 109.001C155.651 115.89 80.3401 75.0887 47.9365 19.1823C31.7456 -8.75215 26.2738 -40.3775 26.0394 -74.0339C25.8189 -105.677 30.2269 -139.076 34.6853 -172.857C34.9699 -175.014 35.2548 -177.172 35.5387 -179.332C35.6721 -180.347 35.8054 -181.362 35.9387 -182.377C40.5324 -217.353 45.1363 -252.407 53.7908 -283.356C62.6966 -315.204 75.8743 -342.639 97.6794 -361.144C119.705 -379.695 150.509 -389.495 183.787 -391.142C217.062 -392.789 252.77 -386.281 284.562 -372.26Z"
        stroke="#AFC8BF"
      />
      <path
        d="M3.06872 -200.444C21.2211 -249.882 37.1966 -305.182 72.755 -324.037C108.599 -343.241 163.995 -326.316 210.968 -310.48C258.227 -294.992 296.431 -280.53 329.334 -258.19C362.586 -235.565 390.252 -204.714 419.015 -165.977C447.779 -127.24 477.64 -80.6156 477.099 -34.1925C476.559 12.2306 445.903 58.1048 405.548 86.7069C365.163 114.992 315.397 125.974 267.312 134.552C218.911 143.161 172.222 149.682 122.416 144.039C72.2929 138.427 18.7663 120.998 -11.3936 84.3174C-41.8702 47.6676 -48.9489 -7.91793 -42.3548 -57.8159C-35.7296 -107.397 -15.0836 -151.005 3.06872 -200.444Z"
        fill="url(#paint0_linear_2693_5761)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2693_5761"
          x1="-50.7381"
          y1="-74.0829"
          x2="468.784"
          y2="-125.298"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BED3CC" stopOpacity="0.6" />
          <stop offset="1" stopColor="#BED3CC" stopOpacity="0.56" />
        </linearGradient>
      </defs>
    </svg>
  );
};
