import { Booking } from './booking';
import { User } from './user';

export enum CarePlanStatus {
  AwaitingVisit = 'awaiting_visit',
  Incomplete = 'incomplete',
  Submitted = 'submitted',
  Denied = 'denied',
  Cancelled = 'cancelled',
  Approved = 'approved',
}

export type CarePlan = {
  id: string;
  status: CarePlanStatus;
  drafted_dt: string;
  draft_denial_reason?: string | null;
  dueDt: string;
  submitted_dt: string | null;
  payor_rate_id: string;
  created_at: string;
  updated_at: string;
  patient_id: string;
  advocate_id: string;
  patient: User;
  advocate: User;
  form_id: string;
  initial_booking_id: string;
  initial_booking: Booking;
  last_updated_by_user_id: string | null;
  last_updated_by_user: User | null;
  last_denial_dt: string | null;
  last_denial_reason: string | null;
  approved_dt: string | null;
};
