import useSWR from 'swr';
import fetcher from '../fetcher';
import { Prospect } from '../../types/prospect';

const useGetProspect = ({ id }: { id?: string } = {}) => {
  const { data, error, isLoading } = useSWR<Prospect>(id ? `/v1/api/prospects/${id}` : null, fetcher);

  return {
    data,
    loading: isLoading,
    error,
  };
};

export default useGetProspect;
