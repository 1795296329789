import { useMemo, useState } from 'react';
import { Address, PatientLink } from '../../../../types/commonwell';
import { addressAsString } from '../../../../utils/commonwell';
import { Button } from '@solace-health/ui';
import { AdminTable } from '../../../../components/shared/Table';
import { ConfirmActionModal } from './ConfirmActionModal';
import { capitalize } from 'lodash';
import dayjs from 'dayjs';

export const PatientLinkTable = ({
  patientLinks,
  isLoading,
  actionText,
  onConfirmAction,
}: {
  patientLinks: PatientLink[];
  isLoading: boolean;
  actionText: 'link' | 'unlink';
  onConfirmAction: (linkId: string) => Promise<void>;
}) => {
  const [selectedPatientLink, setSelectedPatientLink] = useState<PatientLink>();

  const { columns, tableData } = useMemo(() => {
    if (isLoading) return { columns: [], tableData: [] };

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Gender',
        dataIndex: 'gender',
      },
      {
        title: 'Birth date',
        dataIndex: 'birthDate',
        render: (birthDate: string) => (birthDate ? dayjs(birthDate).format('MM/DD/YYYY') : 'N/A'),
      },
      {
        title: 'Addresses',
        dataIndex: 'addresses',
        render: (addresses: Address[]) => {
          return addresses.map((address) => addressAsString(address));
        },
      },
      {
        title: 'Organization',
        dataIndex: 'organization',
      },
      {
        render: (patientLink: PatientLink) => {
          return <Button.Link onClick={() => setSelectedPatientLink(patientLink)}>{capitalize(actionText)}</Button.Link>;
        },
      },
    ];

    return {
      columns,
      tableData: patientLinks.map((patientLink) => {
        const name = patientLink.names[0];

        return {
          ...patientLink,
          name: `${name.given[0]} ${name.family[0]}`,
          organization: patientLink.managingOrganization.name,
        };
      }),
    };
  }, [patientLinks, isLoading, actionText]);

  if (isLoading) return <>Loading patient links...</>;

  const onClose = () => {
    setSelectedPatientLink(undefined);
  };

  const onConfirm = async () => {
    if (!selectedPatientLink) return;

    await onConfirmAction(selectedPatientLink.linkId).then(() => {
      onClose();
    });
  };

  return (
    <>
      <AdminTable columns={columns} data={tableData} showSearch={false} />
      <ConfirmActionModal
        onClose={onClose}
        isOpen={!!selectedPatientLink}
        onConfirm={onConfirm}
        prompt={`Are you sure you want to ${actionText} this patient?`}
        confirmText={`Yes, ${actionText}`}
      />
    </>
  );
};
