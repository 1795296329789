import useSWR from 'swr';
import fetcher from './fetcher';
import { SolaceAPI } from '../utils/api';

type Notification = {
  title: string;
  count: number;
  action_dt: string;
};

const useBroadcastNotifications = () => {
  const { data, error, mutate } = useSWR<Notification[]>(`/v1/api/notifications/broadcast`, fetcher);

  const createNotification = ({
    body,
  }: {
    body: { title: string; body: string; action_path: string; user_type: string[] };
  }) => {
    return SolaceAPI.post({ path: `/api/notifications/broadcast`, body });
  };

  return {
    loading: data === undefined,
    data: data || [],
    error,
    refresh: mutate,
    createNotification,
  };
};

export default useBroadcastNotifications;
