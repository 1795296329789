import { useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';
import Loading from '../../../components/general/Loading';
import useGetUser from '../../../hooks/useGetUser';
import { Email, Phone } from '@mui/icons-material';
import LoginIcon from '@mui/icons-material/Login';
import { useSnackbar } from 'notistack';
import { AxiosResponse } from 'axios';
import { SolaceAPI } from '../../../utils/api';

export const IDENTITY = {
  'google-oauth2': 'Google',
  auth0: 'Username/password',
  facebook: 'Facebook',
};

function Users() {
  const { enqueueSnackbar } = useSnackbar();

  const [inOmniscientMode, setInOmniscientMode] = useState(false);

  const [checked, setChecked] = useState<string[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: { message: string | null } }>({
    isListingActive: { message: null },
    isLoginActive: { message: null },
  });

  const params = useParams<{ userId: string }>();

  const {
    data: user,
    loading: loadingUser,
    refresh,
    deleteUser,
    loginAs,
  } = useGetUser<{ identities: { provider: 'auth0' | 'google-oauth2' | 'facebook' }[] }>({
    id: params.userId,
    includes: ['identities'],
  });

  useEffect(() => {
    const initialChecked = [];
    if (user?.active) initialChecked.push('isLoginActive');

    setChecked(initialChecked);
  }, [user]);

  if (loadingUser || !user) return <Loading loading={true} />;

  const withSetErrors = (fn: Promise<AxiosResponse<any>>, value: string, message: string | null) => {
    fn.then(() => {
      setErrors({ ...errors, [value]: { message: null } });
    }).catch(() => {
      setErrors({ ...errors, [value]: { message } });
    });
  };

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    const shouldActivate = currentIndex === -1;

    if (shouldActivate) {
      newChecked.push(value);

      withSetErrors(
        SolaceAPI.put({
          path: `/api/user/${user.id}`,
          body: { active: true },
        }),
        value,
        'Failed to activate user',
      );
    } else {
      newChecked.splice(currentIndex, 1);

      withSetErrors(
        SolaceAPI.put({
          path: `/api/user/${user.id}`,
          body: { active: false },
        }),
        value,
        'Failed to deactivate user login',
      );
    }

    setChecked(newChecked);
  };

  const onDelete = () => {
    deleteUser({ id: user.id })
      .then(() => {
        refresh();
        enqueueSnackbar('Successfully deleted user.', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar('Failed to deleted user. Reach out to engineering for help.', { variant: 'error' });
      });
  };

  const onLoginAs = () => {
    loginAs({ id: user.sharetribe_uuid as string })
      .then((response) => {
        enqueueSnackbar(response.data.message, { variant: 'success' });
        setInOmniscientMode(true);
      })
      .catch(() => {
        enqueueSnackbar('Failed to login as user. Reach out to engineering for help.', { variant: 'error' });
      });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '48px',
        }}
      >
        <h1 style={{ margin: 0 }}>
          {user.first_name} {user.last_name}
        </h1>
        {user.has_patient_profile && !user.email?.match(/deleted/) && (
          <Button variant="outlined" color="error" onClick={onDelete}>
            Delete User
          </Button>
        )}
      </Box>
      <h3>Attributes</h3>
      <List sx={{ width: '100%' }}>
        <ListItem>
          <ListItemIcon sx={{ minWidth: 90 }}>
            <Email />
          </ListItemIcon>
          <ListItemText primary={user.email} />
        </ListItem>
        <ListItem>
          <ListItemIcon sx={{ minWidth: 90 }}>
            <Phone />
          </ListItemIcon>
          <ListItemText primary={user.phone} />
        </ListItem>
        <ListItem>
          <ListItemIcon sx={{ minWidth: 90 }}>
            <LoginIcon />
          </ListItemIcon>
          {/* TODO: The (|| []) can be removed when server changes are live */}
          <ListItemText primary={(user.identities || []).map((identity) => IDENTITY[identity.provider]).join(', ')} />
        </ListItem>
      </List>

      <h3>Capabilities</h3>
      <List sx={{ width: '100%', maxWidth: 150 }}>
        <ListItem>
          <ListItemText>
            <Typography sx={{ fontSize: 16, fontWeight: 800 }}>Login</Typography>
          </ListItemText>
          <Switch edge="end" onChange={handleToggle('isLoginActive')} checked={checked.indexOf('isLoginActive') !== -1} />
        </ListItem>
      </List>
      {errors.isListingActive.message && <Typography sx={{ color: 'red' }}>{errors.isListingActive.message}</Typography>}
      {errors.isLoginActive.message && <Typography sx={{ color: 'red' }}>{errors.isLoginActive.message}</Typography>}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <h3>Omniscient Mode</h3>
        </AccordionSummary>
        <AccordionDetails>
          <small>This will give you the ability to log into the app as this user.</small>
          <Box marginY="40px">
            <Button
              style={{
                padding: '20px',
                borderRadius: '8px',
                backgroundColor: 'red',
                color: 'white',
                textDecoration: 'none',
              }}
              onClick={onLoginAs}
            >
              <strong>
                Login as {user.first_name} {user.last_name}
              </strong>
            </Button>
          </Box>
          {inOmniscientMode && (
            <>
              <h4 style={{ color: 'red' }}>
                You are now in Omniscient Mode for {user.first_name} {user.last_name}.
              </h4>
              <a href={`${process.env.REACT_APP_SOLACE_APP_URL}/login_as`}>Go to Dashboard</a>
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default Users;
