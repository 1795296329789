import { Button, Flex, Form, Modal, Typography } from '@solace-health/ui';
import { useState } from 'react';
import useHealthRecordEligibleUsers from '../../../hooks/users/useHealthRecordEligibleUsers';
import useHealthRecords from '../../../hooks/useHealthRecords';
import { useSnackbar } from 'notistack';
import { capitalize } from 'lodash';

export const CreatePatient = () => {
  const { data: users, isLoading, refresh: refreshEligibleUsers } = useHealthRecordEligibleUsers();
  const { upsertForUser, refresh: refreshHealthRecords } = useHealthRecords();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const onSubmit = async (values: any) => {
    setIsSubmitting(true);

    await upsertForUser(values.userId)
      .then(() => {
        enqueueSnackbar('Health record created', { variant: 'success' });
        refreshHealthRecords();
        refreshEligibleUsers();
        onClose();
      })
      .catch((e) => {
        enqueueSnackbar(`Health record creation failed: ${e.message}`, { variant: 'error' });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const patientOptions = users.map((user) => {
    return {
      label: (
        <Flex vertical style={{ overflow: 'auto' }}>
          <div>
            {capitalize(user.last_name)}, {capitalize(user.first_name)}
          </div>
          <div>
            <small>
              <i>{user.email}</i>
            </small>
          </div>
        </Flex>
      ),
      value: user.id,
    };
  });

  if (isLoading) return <>Loading...</>;

  return (
    <>
      <Button.Primary style={{ maxWidth: '200px' }} onClick={() => setIsOpen(true)}>
        Create For Patient
      </Button.Primary>
      <Modal isOpen={isOpen} onClose={onClose} destroyOnClose>
        <Flex vertical gap="1rem">
          <Typography.Header>Create For Patient</Typography.Header>
          <Form.Container onSubmit={onSubmit}>
            <Form.SelectMenu
              name="userId"
              options={patientOptions}
              placeholder="Select Patient (Lastname, Firstname)... "
              formOptions={{ required: true }}
            />
            <Form.Submit isSubmitting={isSubmitting} />
          </Form.Container>
        </Flex>
      </Modal>
    </>
  );
};
