import useSWR from 'swr';
import fetcher from '../fetcher';
import { User } from '../../types/user';

const useHealthRecordEligibleUsers = () => {
  const { data, error, isLoading, mutate } = useSWR<User[]>(`/v1/api/health_records/eligible_users`, fetcher);

  return {
    data: data || [],
    isLoading,
    error,
    refresh: mutate,
  };
};

export default useHealthRecordEligibleUsers;
