import { Button, Flex, Icons, Typography } from '@solace-health/ui';
import { AdminTable } from '../../../../components/shared/Table';
import { User } from '../../../../types/user';
import { useNavigate } from 'react-router-dom';
import useGetPaginatedInternalUsers from '../../../../hooks/users/useGetPaginatedInternalUsers';
import { useState } from 'react';
import { CreateInternalUserModal } from './CreateInternalUserModal/CreateInternalUserModal';

export default function InternalUsersPage() {
  const [isOpen, setOpen] = useState(false);

  const { data, pagination, setQueryParams, setSearch, isLoading, refresh } = useGetPaginatedInternalUsers({});

  const navigate = useNavigate();

  const handleOnRowClick = (user: User) => {
    navigate(`/internal-users/${user?.id}`);
  };

  const columns: any = [
    {
      title: 'Name',
      dataIndex: 'fullName',
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
    },
  ];

  return (
    <Flex vertical gap="1rem">
      <Flex justify="space-between">
        <Flex style={{ width: '100%' }}>
          <Typography.Display>Internal Users</Typography.Display>
        </Flex>
        <Flex>
          <Button.Primary onClick={() => setOpen(true)}>
            <Icons.AddPlus color={'#fff'} size={16} />
            Add Internal User
          </Button.Primary>
        </Flex>
      </Flex>

      <AdminTable
        showPagination={true}
        pagination={pagination}
        onChange={setQueryParams}
        columns={columns}
        data={data}
        loading={isLoading}
        onRowClick={handleOnRowClick}
        onTextFilter={setSearch}
      />

      <CreateInternalUserModal isOpen={isOpen} onClose={() => setOpen(false)} onSubmit={refresh} />
    </Flex>
  );
}
