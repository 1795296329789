import { Form, styled } from '@solace-health/ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  position: relative;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 600px;
`;

export const StickyButtonContainer = styled.div<{ show?: boolean }>`
  position: fixed;
  bottom: 24px;
  z-index: 999;
  right: 24px;
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export const Address = styled(Form.Text)`
  height: 50px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #5a5a5a;
  background: #eeeeee;
  color: #7f7f7f;
  padding: 10px 23px;

  :hover,
  :focus {
    border: 1px solid #5a5a5a;
    background: #eeeeee;
    color: #7f7f7f;
    box-shadow: none;
  }
`;
