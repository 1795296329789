import { Typography } from '@solace-health/ui';
import useProbableLinks from '../../../hooks/commonwell/useProbableLinks';
import { HealthRecord } from '../../../types/healthRecord';
import { useSnackbar } from 'notistack';
import usePatientLinks from '../../../hooks/commonwell/usePatientLinks';
import { PatientLinkTable } from './shared/PatientLinkTable';

export const ProbableLinks = ({ healthRecordId }: { healthRecordId: HealthRecord['id'] }) => {
  const { probableLinks, isLoading, refresh: refreshProbableLinks } = useProbableLinks({ healthRecordId });
  const { createPatientLink, refresh: refreshPatientLinks } = usePatientLinks({ healthRecordId });
  const { enqueueSnackbar } = useSnackbar();

  const confirmlinkPatient = async (linkId: string) => {
    return await createPatientLink(linkId)
      .then(() => {
        enqueueSnackbar('Successfully linked', { variant: 'success' });
        refreshProbableLinks();
        refreshPatientLinks();
      })
      .catch((e) => {
        enqueueSnackbar(`Failed to link: ${e.message}`, { variant: 'error' });
      });
  };

  return (
    <>
      <Typography.Header>Probable Links</Typography.Header>
      <PatientLinkTable
        patientLinks={probableLinks}
        isLoading={isLoading}
        actionText="link"
        onConfirmAction={confirmlinkPatient}
      />
    </>
  );
};
