const fetcher = (path: string) => {
  const token = localStorage.getItem('accessToken');
  const headers = { Authorization: `Bearer ${token}`, 'solace-client': 'solace-health-admin' };
  return fetch(`${process.env.REACT_APP_API_HOST}${path}`, { headers }).then((res) => {
    if (res.status === 401) {
      localStorage.setItem('redirectUrl', window.location.pathname);
      localStorage.removeItem('accessToken');
      window.location.assign('/');
      return;
    }

    if (res.status >= 400) throw new Error(res.statusText);

    return res.json();
  });
};

export default fetcher;
