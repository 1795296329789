import useSWR from 'swr';
import fetcher from '../fetcher';
import qs from 'qs';
import { PatientStatus, TraitId, User } from '../../types/user';
import { Referral, ReferralStatus } from '../../types/referrals';
import { useMemo } from 'react';

type UseGetUsersProps = {
  filter?: {
    patients?: boolean;
    loved_ones?: boolean;
    advocates?: boolean;
    clients?: boolean;
    traits?: TraitId[];
  };
  include?: (
    | 'address'
    | 'client_bookings'
    | 'referral.prospect'
    | 'client_bookings.advocate.traits'
    | 'patient_care_plans'
    | 'physician_visits'
    | 'referral'
    | 'referral.user.client_bookings'
    | 'referral.user.patient_care_plans'
    | 'referral.user.physician_visits'
    | 'traits'
  )[];
  search?: string;
};

const useGetUsers = <Includes extends Record<string, unknown>>({ filter, include, search }: UseGetUsersProps) => {
  const urlParams = qs.stringify({ filter, include, search });
  const { data, error, mutate, isLoading, isValidating } = useSWR<(User & { referral?: Referral } & Includes)[]>(
    `/v1/api/user?${urlParams}`,
    fetcher,
  );

  const { engagedPatients, needsAttentionPatients, unverifiedPatients, activePatients, archivedPatients } = useMemo(() => {
    const engagedPatients: (User & { referral: Referral } & Includes)[] = [];
    const needsAttentionPatients: (User & { referral: Referral } & Includes)[] = [];
    const unverifiedPatients: (User & { referral: Referral } & Includes)[] = [];
    const activePatients: (User & { referral: Referral } & Includes)[] = [];
    const archivedPatients: (User & { referral: Referral } & Includes)[] = [];

    data?.forEach((user) => {
      if (user.archived_dt) return archivedPatients.push(user as User & { referral: Referral } & Includes);

      if (user.patient_status === PatientStatus.Unverified)
        return unverifiedPatients.push(user as User & { referral: Referral } & Includes);

      if (user.patient_status === PatientStatus.Active)
        return activePatients.push(user as User & { referral: Referral } & Includes);

      if (
        user.referral?.status === ReferralStatus.Eligible &&
        (user.patient_status === PatientStatus.Chw_Visit || user.patient_status === PatientStatus.Clinician_Visit)
      )
        return engagedPatients.push(user as User & { referral: Referral } & Includes);

      needsAttentionPatients.push(user as User & { referral: Referral } & Includes);
    });

    return { engagedPatients, needsAttentionPatients, unverifiedPatients, activePatients, archivedPatients };
  }, [data]);

  return {
    data: data || [],
    activePatients: activePatients,
    archivedPatients: archivedPatients,
    engagedPatients: engagedPatients,
    needsAttentionPatients: needsAttentionPatients,
    unverifiedPatients: unverifiedPatients,
    isLoading,
    isValidating,
    error,
    refresh: mutate,
  };
};

export default useGetUsers;
