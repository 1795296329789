import useSWR from 'swr';
import fetcher from './fetcher';
import { CarePlan } from '../types/carePlan';

export default function useGetUserCarePlans({ userId }: { userId?: string }) {
  const {
    data,
    error,
    mutate: refresh,
    isLoading,
  } = useSWR<CarePlan[]>(userId ? `/v1/api/admin/users/${userId}/care_plans` : null, fetcher);

  return {
    loading: isLoading,
    data: data ?? [],
    error,
    refresh,
  };
}
