import axios from 'axios';
import { ReferralFormInfo } from '../../types/referrals';

export type UpdateRefferalDto = Pick<ReferralFormInfo, 'provider_id'> & Partial<ReferralFormInfo>;

const useReferrals = () => {
  const token = localStorage.getItem('accessToken');
  const headers = { Authorization: `Bearer ${token}` };

  const updateLastContact = async ({ id, lastContact }: { id: string; lastContact: Date }) =>
    await axios.put(
      `${process.env.REACT_APP_API_HOST}/v1/api/referrals/${id}/last_contact`,
      { last_contact_dt: lastContact },
      { headers: { ...headers, 'Content-Type': 'application/json' } },
    );

  return {
    updateLastContact,
  };
};

export default useReferrals;
