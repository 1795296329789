import useSWR from 'swr';
import fetcher from './../fetcher';
import { HealthRecord } from '../../types/healthRecord';
import { Patient } from '../../types/commonwell';
import { SolaceAPI } from '../../utils/api';

const usePatient = ({ healthRecordId }: { healthRecordId: HealthRecord['id'] }) => {
  const { data, error, mutate, isLoading } = useSWR<Patient>(
    healthRecordId && `/v1/api/commonwell/patients/${healthRecordId}`,
    fetcher,
  );

  const mergePatients = async (nonSurvivingHealthRecordId: HealthRecord['id']) => {
    return await SolaceAPI.put({
      path: `/api/commonwell/patients/${healthRecordId}/merge`,
      body: { non_surviving_health_record_id: nonSurvivingHealthRecordId },
    });
  };

  const deletePatient = async () => {
    return await SolaceAPI.delete({ path: `/api/commonwell/patients/${healthRecordId}` });
  };

  return {
    patient: data,
    error,
    refresh: mutate,
    isLoading,
    mergePatients,
    deletePatient,
  };
};

export default usePatient;
