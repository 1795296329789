import { Button, Flex, Icons, Size, Typography } from '@solace-health/ui';
import { AdminTable } from '../../../../../components/shared/Table';
import { CareTimelineResponse, CareTimelineTypes } from '../../../../../hooks/useGetUserCareTimeline';
import dayjs from 'dayjs';
import { timeZoneAbbr } from '../../../../../utils/general';
import { TraitId, User, UserTimeZone } from '../../../../../types/user';
import timezone from 'dayjs/plugin/timezone';
import { textCompare } from '../../../../../shared/utils/table-utils';
import { BookingState } from '../../../../../types/booking';
import StatusPill from '../../../../../components/sharedStatusPill/StatusPill';
import { useState } from 'react';
import { EncounterStatus } from '../../../../../types/physicianVisit';
import VisitsAndEncounterDrawer from './VisitsAndEncounterDrawer/VisitsAndEncounterDrawer';
import { hasTrait } from '../../../../../utils/user';
import { SchedulingDrawer } from '../SchedulingDrawer/SchedulingDrawer';
import useGetUser from '../../../../../hooks/useGetUser';

dayjs.extend(timezone);

export const statusPillOptions = (state: BookingState | EncounterStatus) => {
  switch (state) {
    case BookingState.Completed:
      return {
        borderColor: 'var(--border-color-dark-green, #285E50)',
        backgroundColor: 'var(--green-100, #F4F8F7)',
        text: 'Occurred',
      };
    case EncounterStatus.Submitted:
      return {
        borderColor: 'var(--border-color-dark-green, #285E50)',
        backgroundColor: 'var(--green-100, #F4F8F7)',
        text: state,
      };
    case BookingState.ClientNoShow:
      return {
        borderColor: 'var(--border-color-danger, #DA4D54)',
        backgroundColor: 'var(--danger-bg-100, #F1E1E2)',
        text: 'Client no-show',
      };
    case BookingState.Rescheduled:
      return {
        borderColor: 'var(--border-color-dark, #555)',
        backgroundColor: 'var(--grey-100, #EEE)',
        text: 'Rescheduled',
      };
    case BookingState.Cancelled:
      return {
        borderColor: 'var(--border-color-dark, #555)',
        backgroundColor: 'var(--grey-100, #EEE)',
        text: 'Cancelled',
      };
    case BookingState.Accepted:
      return {
        borderColor: 'var(--border-color-warning, #D7A13B)',
        backgroundColor: 'var(--gold-100, #FFF7E9)',
        text: 'Scheduled',
      };
    case EncounterStatus.Pending:
      return {
        borderColor: 'var(--border-color-warning, #D7A13B)',
        backgroundColor: 'var(--gold-100, #FFF7E9)',
        text: state,
      };
    default:
      return {
        borderColor: 'var(--border-color-dark, #555)',
        backgroundColor: 'var(--grey-100, #EEE)',
        text: 'Unknown',
      };
  }
};

export default function VisitsAndEncounters({
  patient,
  careTimelineData,
  refresh,
}: {
  patient: User;
  careTimelineData: CareTimelineResponse[];
  refresh: () => void;
}) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeData, setActiveData] = useState<CareTimelineResponse | null>(null);
  const [schedulingDrawerOpen, setSchedulingDrawerOpen] = useState(false);

  const { data: user } = useGetUser({
    id: activeData?.type === CareTimelineTypes.Visit ? activeData?.data?.client_id : undefined,
    includes: [
      'address',
      'client_bookings.advocate.traits',
      'identities',
      'patient_care_plans',
      'physician_visits',
      'referral.prospect',
      'referral.user.client_bookings',
      'referral.user.patient_care_plans',
      'referral.user.physician_visits',
      'traits',
    ],
  });

  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const patientTimezone = patient.time_zone;

  const appointmentType = (item: CareTimelineResponse) => {
    if (item.type === CareTimelineTypes.Encounter) return 'Encounter';
    const { data } = item;
    if (!data?.advocate) return '';
    if (hasTrait({ user: data.advocate, traitId: TraitId.CommunityHealthPhysician })) return 'Clinician Visit';
    if (data.is_intro_call) return 'CHW Intro';
    return 'CHW Follow-up';
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      render: (date: string) => dayjs(date).tz(browserTimezone).format('MM/DD/YYYY'),
      sorter: (a: CareTimelineResponse & { date: string }, b: CareTimelineResponse & { date: string }) =>
        dayjs(a.date).diff(dayjs(b.date)),
    },
    {
      title: 'Time',
      dataIndex: 'time',
      sorter: (a: CareTimelineResponse, b: CareTimelineResponse) => dayjs(a.data.created_at).diff(dayjs(b.data.created_at)),
      render: (time: string) => (
        <Flex gap={5}>
          <Typography.Body>
            {dayjs(time).tz(browserTimezone).format('h:mma')} {timeZoneAbbr(browserTimezone as UserTimeZone)}
          </Typography.Body>
          <Typography.Body size={Size.SM} color={'#555'}>
            {patientTimezone === browserTimezone
              ? '—'
              : `(${dayjs(time).tz(patientTimezone).format('h:mma')} ${timeZoneAbbr(patientTimezone as UserTimeZone)})`}
          </Typography.Body>
        </Flex>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a: CareTimelineResponse & { name: string }, b: CareTimelineResponse & { name: string }) =>
        textCompare(a, b, 'name'),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (_: CareTimelineTypes, record: CareTimelineResponse) => appointmentType(record),
      sorter: (a: CareTimelineResponse & { type: string }, b: CareTimelineResponse & { type: string }) =>
        textCompare(a, b, 'type'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a: CareTimelineResponse & { status: string }, b: CareTimelineResponse & { status: string }) =>
        textCompare(a, b, 'status'),
      render: (status: BookingState | EncounterStatus) => {
        const { borderColor, backgroundColor, text } = statusPillOptions(status);
        const shouldStrikeThrough = status === BookingState.Rescheduled || status === BookingState.Cancelled;

        return (
          <StatusPill borderColor={borderColor} backgroundColor={backgroundColor} strikeThrough={shouldStrikeThrough}>
            {text}
          </StatusPill>
        );
      },
    },
    {
      title: 'Details',
      dataIndex: 'key',
      render: (key: string) => (
        <Button.Unstyled key={key}>
          <Icons.Caret direction="right" />
        </Button.Unstyled>
      ),
    },
  ];

  const getStatus = (item: CareTimelineResponse): EncounterStatus | BookingState => {
    if (item.type === CareTimelineTypes.Encounter) {
      return !!item.data.submitted_dt ? EncounterStatus.Submitted : EncounterStatus.Pending;
    }
    return item.data.state;
  };

  const formattedData = careTimelineData.map((item) => {
    const date = item.type === CareTimelineTypes.Visit ? item.data.start : item.data.created_at;
    return {
      ...item,
      date,
      time: date,
      name:
        item.type === CareTimelineTypes.Encounter
          ? `${item.data.physician?.first_name} ${item.data.physician?.last_name}`
          : `${item.data.advocate?.first_name} ${item.data.advocate?.last_name}`,
      status: getStatus(item),
      type: item.type,
      video_call: item.type === CareTimelineTypes.Visit ? item.data.video_call : undefined,
      key: item.data.id,
    };
  });

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <AdminTable
        columns={columns}
        data={formattedData}
        onRowClick={(record: CareTimelineResponse) => {
          setDrawerOpen(true);
          setActiveData(record);
        }}
        actionItem={<Typography.Header>Visits & Encounters</Typography.Header>}
      />

      <VisitsAndEncounterDrawer
        isOpen={drawerOpen}
        onClose={handleDrawerClose}
        data={activeData ?? ({} as CareTimelineResponse)}
        refresh={refresh}
        clearSelectedData={() => setActiveData(null)}
        setSchedulingDrawerOpen={setSchedulingDrawerOpen}
      />

      {user && (
        <SchedulingDrawer
          user={user}
          isOpen={schedulingDrawerOpen}
          handleClose={() => {
            setSchedulingDrawerOpen(false);
            setDrawerOpen(false);
            setActiveData(null);
          }}
          refresh={refresh}
          originalBooking={activeData?.type === CareTimelineTypes.Visit ? activeData.data : null}
        />
      )}
    </>
  );
}
