import { useMemo, useState } from 'react';
import * as S from './style';

import { Button, Flex, Icons, Toggle, Typography } from '@solace-health/ui';

import { ReferralState } from '../../../../types/referrals';
import { copyToClipBoard, getFullStateName, phoneNumberFormat } from '../../../../utils/general';
import useReferrals from '../../../../hooks/referrals/useReferrals';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import timezone from 'dayjs/plugin/timezone';
import { PatientStatus, User } from '../../../../types/user';
import { SchedulingDrawer } from '../PatientPage/SchedulingDrawer/SchedulingDrawer';
import { AdminTable } from '../../../../components/shared/Table';
import useGetBookingsForPatient from '../../../../hooks/bookings/useGetBookings';
import PatientStatusPill from '../../../../components/user/PatientStatusPill';

import { BookingState } from '../../../../types/booking';
import UpsertPatientModal from '../../../../components/shared/UpsertPatientModal';
import { useQuery } from '../../../../hooks/useQuery';
import { debounce } from 'lodash';
import { useGetPatients } from '../../../../hooks/users/useGetPatients';

dayjs.extend(timezone);

type PatientTableType = {
  key: string; // Which is the referral ID
  name: string;
  lastContact: Date | null;
  contactInfo: { phoneNumber?: string; email?: string };
  location: string;
  status?: ReferralState;
} & User;

const Patients = () => {
  const query = useQuery();
  const prospectId = query.get('p_id') as string;

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { updateLastContact } = useReferrals();
  const [isOpen, setOpen] = useState(!!prospectId);
  const [user, setUser] = useState<any | null>(null);

  const { data: appointments, loading: loadingAppointments } = useGetBookingsForPatient({
    id: user?.sharetribe_uuid,
    includes: ['client.referral', 'advocate', 'video_call'],
    filter: { state: ['accepted', 'rescheduled'] },
  });

  const futureAcceptedAppointment = appointments
    .filter((appt) => new Date(appt.start) > new Date() && appt.state === BookingState.Accepted)
    .sort((a, b) => dayjs(a.start).diff(dayjs(b.start)))?.[0];

  const {
    data: allPatients,
    isLoading,
    isValidating,
    refresh,
    pagination,
    setQueryParams,
    setSearch,
  } = useGetPatients({
    include: [
      'referral.user.client_bookings',
      'referral.user.patient_care_plans',
      'referral.user.physician_visits',
      'traits',
      'address',
      'client_bookings.advocate.traits',
      'patient_care_plans',
      'physician_visits',
    ],
    filter: {
      isNull: 'archived_dt',
    },
  });

  const handleUpdateLastContact = async ({ id, lastContact }: { id: string; lastContact: Date }) => {
    try {
      if (dayjs(lastContact) > dayjs()) throw new Error('Cannot set last contact in the future');

      const res = await updateLastContact({ id, lastContact });

      if (res.status !== 200) throw new Error(res.statusText);

      enqueueSnackbar('Updated last contact', { variant: 'success' });
      refresh();
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: 'error' });
    }
  };

  const handleCopyToClipboard = (text: string) => {
    copyToClipBoard({ text });
    enqueueSnackbar(`Successfully copied ${text} to clipboard`, { variant: 'success' });
  };

  const { columns, tableData } = useMemo(() => {
    const processedColumns = [
      {
        title: 'ID',
        dataIndex: 'internal_id',
        defaultSortOrder: 'descend',
        sorter: true,
      },
      {
        title: 'Name',
        dataIndex: 'first_name',
        width: 240,
        sorter: true,
        render: (_: string, record: PatientTableType) => record.name,
      },
      {
        title: 'Contact Info',
        dataIndex: 'contactInfo',
        render: (contactInfo: { phoneNumber?: string; email?: string }) => (
          <Flex vertical gap=".625rem">
            <Button.Link
              color="#285E50"
              onClick={(e: any) => {
                e.stopPropagation();
                handleCopyToClipboard(contactInfo.email ?? '');
              }}
            >
              {contactInfo?.email}
            </Button.Link>
            <Button.Link
              color="#285E50"
              onClick={(e: any) => {
                e.stopPropagation();
                handleCopyToClipboard(contactInfo.phoneNumber ?? '');
              }}
            >
              {phoneNumberFormat(contactInfo?.phoneNumber, '')}
            </Button.Link>
          </Flex>
        ),
      },
      {
        title: 'Location',
        dataIndex: 'location',
      },
      {
        title: 'Last Contact',
        dataIndex: 'lastContact',
        render: (lastContact: Date | null, record: PatientTableType) =>
          record?.key ? (
            <div style={{ maxWidth: 160 }}>
              <S.LastContact
                onClick={(e: any) => {
                  e.stopPropagation();
                  handleUpdateLastContact({ id: record.key, lastContact: new Date() });
                }}
              >
                {lastContact ? new Date(lastContact).toLocaleDateString() : 'N/A'}
              </S.LastContact>
            </div>
          ) : (
            'No Referral On File'
          ),
      },
      {
        title: 'Status',
        dataIndex: 'patient_status',
        render: (status: PatientStatus, data: any) => (
          <Button.Unstyled
            onClick={() => {
              const patient = allPatients.find((patient) => patient.id === data.id);
              setUser(patient);
            }}
          >
            <PatientStatusPill status={status} />
          </Button.Unstyled>
        ),
      },
    ];

    const processTableData = allPatients.map((patient) => ({
      ...patient,
      key: patient.referral?.id as string,
      id: patient.sharetribe_uuid,
      name: patient.fullName ?? '',
      location: getFullStateName(patient?.address?.state),
      lastContact: patient?.referral?.last_contact_dt,
      status: patient?.patient_status || 'needs_insurance_check',
      contactInfo: { phoneNumber: patient.phone, email: patient.email },
    }));

    return { columns: processedColumns, tableData: processTableData };
  }, [allPatients, isValidating]);

  const handleOnRowClick = (record: PatientTableType) => {
    navigate(`/patients/${record?.id}`);
  };

  const handleSearch = debounce((value: string) => {
    setSearch(value);
  }, 300);

  return (
    <>
      <S.Container>
        <Flex justify="space-between">
          <Typography.Display>Patients</Typography.Display>
          <Button.Primary onClick={() => setOpen(true)} style={{ maxWidth: 200 }}>
            <Icons.AddPlus color={'#fff'} />
            Add Patient
          </Button.Primary>
        </Flex>
        <Flex gap="1rem">
          <Flex gap=".5rem" align="center">
            <Typography.Body>Search by Loved Ones</Typography.Body>
            <Toggle
              name="loved-one-search"
              value={'Search by Loved one'}
              handleEvent={(isToggled) => setQueryParams({ searchByLovedOne: isToggled })}
            />
          </Flex>
          <Flex gap=".5rem" align="center">
            <Typography.Body>Include Archived Patients</Typography.Body>
            <Toggle
              name="Include-archived-patients"
              value={'Include Archived Patients'}
              handleEvent={(isToggled) => setQueryParams({ filter: isToggled ? {} : { isNull: 'archived_dt' } })}
            />
          </Flex>
        </Flex>

        <AdminTable
          columns={columns}
          data={tableData}
          loading={isLoading}
          showSearch
          onRowClick={handleOnRowClick}
          onTextFilter={handleSearch}
          showPagination
          pagination={pagination}
          onChange={setQueryParams}
        />
      </S.Container>

      {user && !loadingAppointments && !futureAcceptedAppointment && (
        <SchedulingDrawer
          user={user}
          isOpen={!!user}
          handleClose={() => {
            setUser(null);
          }}
          refresh={() => {
            refresh();
          }}
        />
      )}
      <UpsertPatientModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onUpdate={() => refresh()}
        prospectId={prospectId}
      />
    </>
  );
};

export default Patients;
