import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Listing } from '../../../../../../types/listing';
import { Flex, Typography } from '@solace-health/ui';

export const ListingDetails = ({ listing }: { listing: Listing | null }) => {
  if (!listing) return null;

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography.Header>Listing Details</Typography.Header>
      </AccordionSummary>
      <AccordionDetails>
        <Flex wrap="wrap">
          <pre style={{ maxWidth: '800px', textWrap: 'pretty', fontSize: '14px' }}>{JSON.stringify(listing, null, 4)}</pre>
        </Flex>
      </AccordionDetails>
    </Accordion>
  );
};
