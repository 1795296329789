import { Booking } from './booking';
import { PatientSexOptions } from './referrals';
import { User } from './user';

export enum HereFor {
  LovedOne = 'loved_one',
  Self = 'self',
}

export enum InsurancePath {
  Medicare = 'medicare',
  Medicaid = 'medicaid',
  MedicareAdvantage = 'medicare_advantage',
  Other = 'other',
}

export enum InsuranceProgramId {
  Medicare = 'medicare',
  Medicaid = 'medicaid',
}

export type ProspectPayload = {
  insurance_program_id?: InsuranceProgramId;
  insurance_path?: InsurancePath;
  patient_dob?: string;
  patient_sex?: PatientSexOptions;
  subscriber_dob?: string;
  subscriber_sex?: PatientSexOptions;
  subscriber_ssn?: string;
  reason?: string;
};

export type Prospect = {
  id: string;
  short_id: string;
  user_id?: string;
  booking_id?: string;
  payload: ProspectPayload;
  booking_hold_id?: string | null;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  here_for?: HereFor;
  loved_one_name?: string;
  loved_one_relationship?: string;
  category?: string;
  conditions: string[];
  how_can_we_help?: string | null;
  city?: string;
  state?: string;
  lat?: string;
  long?: string;
  requires_in_person?: boolean;
  extra_context?: string;
  last_interaction_dt: Date;
  experiments: { use_ai_search: 'true' | 'false' };
  created_at: Date;
  updated_at: Date;
  booking?: Booking;
  user?: User;
  is_here_for_self?: boolean;
  is_here_for_loved_one?: boolean;
};
