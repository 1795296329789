import { Address } from '../types/forms';
import { User } from '../types/user';
import { useSnackbar } from 'notistack';
import { SolaceAPI } from '../utils/api';
import { SolaceApiError } from '../utils/errors';
import { HereFor, InsuranceProgramId } from '../types/prospect';
import { InsuranceCompanyId } from '../types/payor';
import { BookingHelpRequest } from './prospects/useGetBookingHelpRequests';

export type UpsertPatientDto = {
  id?: string;
  address: Partial<Address>;
  first_name: string;
  last_name: string;
  dob: string;
  sex: string;
  zip: string;
  email?: string;
  phone?: string;
  member_id?: string;
  prospect_id?: string;
  referred_by?: HereFor;
  payor: {
    insurance_program_id: InsuranceProgramId;
    /** Would only be an empty string when selecting in admin */
    insurance_company_id: InsuranceCompanyId | null | '';
  };
  loved_one?: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    relationship_to_client: string;
    preferred_name: string;
  };
};

const usePatient = () => {
  const { enqueueSnackbar } = useSnackbar();

  const checkForPotentialBookingHelpRequests = async ({
    params,
  }: {
    params: Pick<UpsertPatientDto, 'first_name' | 'last_name' | 'phone' | 'dob' | 'loved_one'>;
  }) => {
    const response = await SolaceAPI.get<BookingHelpRequest[]>({
      path: `/api/booking_help_requests/search`,
      params: {
        first_name: params.first_name,
        last_name: params.last_name,
        phone: params.phone || params.loved_one?.phone,
        dob: params.dob,
      },
    });

    return response.data;
  };

  const createPatient = async ({ body, prospectId }: { body: UpsertPatientDto; prospectId?: string }): Promise<User> => {
    try {
      const { data: user } = await SolaceAPI.post<User>({ path: `/api/patients`, body });
      return user;
    } catch (e: any) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
      throw new SolaceApiError(error);
    }
  };

  const updatePatient = async (id: User['id'], body: Partial<UpsertPatientDto>): Promise<User> => {
    try {
      const { data: user } = await SolaceAPI.put<User>({
        path: `/api/patients/${id}`,
        body: { ...body, update_referral: true },
      });
      return user;
    } catch (e: any) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
      throw new SolaceApiError(error);
    }
  };

  const checkPatientInsuranceEligibility = async ({ id }: { id: User['id'] }): Promise<User> => {
    try {
      const { data } = await SolaceAPI.post<User>({
        path: `/api/admin/patients/${id}/check_insurance_eligibility`,
      });
      return data;
    } catch (e: any) {
      const error = e as SolaceApiError;
      throw new SolaceApiError(error);
    }
  };

  return {
    checkForPotentialBookingHelpRequests,
    createPatient,
    updatePatient,
    checkPatientInsuranceEligibility,
  };
};

export default usePatient;
