import useSWR from 'swr';
import qs from 'qs';
import fetcher from './fetcher';

export enum TimesheetStatus {
  /**
   * The timesheet is awaiting submission to Candid
   */
  Draft = 'draft',
  /**
   * The timesheet is pending submission to Candid
   */
  PendingSubmission = 'pending_submission',
  /**
   * The timesheet has been successfully submitted to Candid
   */
  Submitted = 'submitted',
  /**
   * The timesheet is unable to be submitted to Candid. (Check status_reason for why)
   */
  Rejected = 'rejected',
  /**
   * The timesheet failed to submit to Candid. We should resolve the reason it failed and resubmit
   */
  Failed = 'failed',
}

export type Timesheet = {
  id: string;
  status: TimesheetStatus;
  advocate_patient: {
    advocate: {
      first_name: string;
      last_name: string;
    };
  };
};

const useTimesheets = ({ status }: { status: TimesheetStatus[] }) => {
  const query = qs.stringify({
    filter: { status },
    include: ['advocate_patient.advocate'],
    sort: ['-draft_dt'],
  });
  const { data, error, isLoading, mutate } = useSWR<Timesheet[]>(`/v1/api/timesheets?${query}`, fetcher);

  return {
    data: data || [],
    loading: isLoading,
    error,
    refresh: mutate,
  };
};

export default useTimesheets;
