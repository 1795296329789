import { Button, Flex, Segmented, Typography } from '@solace-health/ui';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { AdminTable } from '../../../../components/shared/Table';
import useTimesheets, { Timesheet, TimesheetStatus } from '../../../../hooks/useTimesheets';
import StatusPill from '../../../../components/sharedStatusPill/StatusPill';
import { SolaceAPI } from '../../../../utils/api';
import { titleCase } from '../../../../shared/utils/gen-utils';

const TABS = {
  ALL: 'all',
  DRAFT: 'draft',
  REJECTED: 'rejected',
  FAILED: 'failed',
  PENDING: 'pending',
  SUBMITTED: 'submitted',
};

type Tab = keyof typeof TABS;

const TAB_TO_STAUSES = {
  [TABS.ALL]: [],
  [TABS.DRAFT]: [TimesheetStatus.Draft],
  [TABS.REJECTED]: [TimesheetStatus.Rejected],
  [TABS.FAILED]: [TimesheetStatus.Failed],
  [TABS.SUBMITTED]: [TimesheetStatus.Submitted],
};

export const TimesheetsPage = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [activeTab, setActiveTab] = useState(TABS.DRAFT);

  const status = TAB_TO_STAUSES[activeTab];
  const { data: timesheets, loading, refresh } = useTimesheets({ status });

  const onResubmit = async (id: string) => {
    try {
      await SolaceAPI.post({ path: `/api/timesheets/${id}/encounters` });
      enqueueSnackbar('Resubmitted timesheet.', { variant: 'success' });

      refresh();
    } catch (e) {
      enqueueSnackbar('Failed to resubmit timesheet', { variant: 'error' });
    }
  };

  const { columns, tableData } = useMemo(() => {
    const columns = [
      {
        title: 'AP ID',
        dataIndex: 'advocate_patient_id',
      },
      {
        title: 'Draft Dt',
        dataIndex: 'draft_dt',
        render: (draftDt: string) => (draftDt ? new Date(draftDt).toLocaleDateString('en-us') : 'N/A'),
      },
      {
        title: 'Advocate Name',
        render: (timesheet: Timesheet) => (
          <Typography.Body>
            {timesheet.advocate_patient.advocate.first_name} {timesheet.advocate_patient.advocate.last_name}
          </Typography.Body>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (status: string) => (
          <StatusPill borderColor="var(--border-color-dark, #555)" backgroundColor="var(--grey-100, #EEE)">
            {titleCase(status)}
          </StatusPill>
        ),
      },
      ...([TABS.FAILED, TABS.REJECTED].includes(activeTab)
        ? [
            {
              title: 'Status Reason',
              dataIndex: 'status_reason',
            },
          ]
        : []),
      ...(activeTab === TABS.SUBMITTED
        ? [
            {
              title: 'Submitted Dt',
              dataIndex: 'submitted_dt',
              render: (draftDt: string) => (draftDt ? new Date(draftDt).toLocaleDateString('en-us') : 'N/A'),
            },
          ]
        : []),
      ...([TABS.FAILED, TABS.REJECTED].includes(activeTab)
        ? [
            {
              title: '',
              dataIndex: 'id',
              render: (id: string) => {
                return <Button.Link onClick={() => onResubmit(id)}>Resubmit</Button.Link>;
              },
            },
          ]
        : [{}]),
    ];

    return { columns, tableData: timesheets };
  }, [timesheets]);

  return (
    <Flex vertical gap="1rem">
      <Typography.Display>Timesheets</Typography.Display>

      <Flex>
        <Segmented
          defaultValue={activeTab}
          options={[
            {
              label: 'Draft',
              value: TABS.DRAFT,
            },
            {
              label: 'Rejected',
              value: TABS.REJECTED,
            },
            {
              label: 'Failed',
              value: TABS.FAILED,
            },
            {
              label: 'Pending',
              value: TABS.PENDING,
            },
            {
              label: 'Submitted',
              value: TABS.SUBMITTED,
            },
            {
              label: 'All',
              value: TABS.ALL,
            },
          ]}
          onChange={(e) => setActiveTab(e as Tab)}
        />
      </Flex>

      <AdminTable columns={columns} data={tableData} loading={loading} showSearch={false} onRowClick={() => null} />
    </Flex>
  );
};
