import { User } from './user';

export enum RetrievalStatus {
  Failed = 'failed',
  Pending = 'pending',
  Complete = 'complete',
  NotFound = 'not_found',
}
export type HealthRecord = {
  id: string;
  xref_patient_id?: string | null;
  retrieval_status: RetrievalStatus;
  retrieval_attempts: number;
  last_retrieval_attempt_dt: string | null; // timestamp
  first_name: string | null;
  last_name: string | null;
  user_id: User['id'];
  user: { first_name: string; last_name: string; email: string };
};
