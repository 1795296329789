export enum PayoutStatus {
  Paid = 'paid',
  Pending = 'pending',
  InTransit = 'in_transit',
  Canceled = 'canceled',
  Failed = 'failed',
}

export type Payout = {
  id: string;
  amount: number;
  status: PayoutStatus;
  status_dt: string;
  est_arrival_dt: string;
  created_at: string;
  updated_at: string;
};
