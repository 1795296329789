import { useState } from 'react';
import { SearchResults } from './SearchResults';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { ListingScores } from './ListingScores';
import { ListingScoreDetails } from './ListingScoreDetails';
import useGetSearchResults from '../../../hooks/useGetSearchResults';
import { TestAISearch } from './TestAISearch';

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

type Props = { listingView?: boolean };

export const SearchResultsPage = ({ listingView = false }: Props) => {
  const [page, setPage] = useState(1);
  const [value, setValue] = useState(0);

  const { loading } = useGetSearchResults({ page });

  if (listingView) return <ListingScoreDetails />;

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const pages = [];
  for (let i = 0; i < page; i++) {
    pages.push(<SearchResults page={i + 1} key={i} />);
  }

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Search Result History" />
          <Tab label="Advocate Scores" />
          <Tab label="Test AI Search" />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <div>
          {pages}
          {!loading && (
            <Button variant="outlined" onClick={() => setPage(page + 1)}>
              Load More
            </Button>
          )}
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ListingScores />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <TestAISearch />
      </CustomTabPanel>
    </>
  );
};
