import { styled } from '@solace-health/ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: sticky;
  top: 64px;
  background: #fff;
  overflow: hidden;
  z-index: 99;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 600px;
`;

export const StickyButtonContainer = styled.div<{ show?: boolean }>`
  position: fixed;
  bottom: 24px;
  z-index: 999;
  right: 24px;
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export const Toggle = styled.div`
  .ant-switch-inner-unchecked {
    padding-left: 5px;
  }
`;
