import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

const AdminSeed = () => {
  const [uuid, setUuid] = useState('');
  const [content, setContent] = useState('Best advocate out there');
  const [rating, setRating] = useState('5');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [unControlledUpload, setUncontrolledUpload] = useState('');
  const [showTextBox, setShowTextBox] = useState('no');
  const isFilledOut = (!!uuid && !!content && !!firstname && !!lastname && !!rating) || !isEmpty(unControlledUpload);
  const url = process.env.REACT_APP_API_HOST;
  const adminKey = process.env.REACT_APP_ADMIN_KEY;
  const { enqueueSnackbar } = useSnackbar();

  const onSubmitReview = () => {
    if (showTextBox === 'yes') {
      try {
        const body = JSON.parse(unControlledUpload);
        axios
          .post(`${url}/v1/seeds/reviews`, body, { headers: { 'admin-api-key': adminKey } })
          .then(() => {
            enqueueSnackbar('Successfully submitted seed review', { variant: 'success' });
          })
          .catch((err) => {
            enqueueSnackbar('Could not create seed review', { variant: 'error' });
          });
      } catch (err) {
        enqueueSnackbar('Could not create seed review', { variant: 'error' });
        console.log(err);
      }
    } else {
      try {
        const body = { uuid, rating, content, firstname, lastname };
        axios
          .post(`${url}/v1/seeds/reviews`, [body], { headers: { 'admin-api-key': adminKey } })
          .then(() => {
            enqueueSnackbar('Successfully submitted seed review', { variant: 'success' });
          })
          .catch((err) => {
            enqueueSnackbar('Could not create seed review', { variant: 'error' });
          });
      } catch (err) {
        enqueueSnackbar('Could not create seed review', { variant: 'error' });
        console.log(err);
      }
    }
  };

  return (
    <Grid container flexDirection="column" padding="20px 100px" maxWidth="1000px">
      <h1>Upload a Review</h1>
      <FormControl>
        <FormLabel>Do you want to just copy and paste in the data?</FormLabel>
        <RadioGroup value={showTextBox} onChange={(e) => setShowTextBox(e.target.value)}>
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
      {showTextBox === 'no' && (
        <Grid item>
          <Grid container flexDirection="column">
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <TextField
                  required
                  id="outlined-required"
                  label="Sharetribe User UUID"
                  margin="normal"
                  fullWidth
                  onChange={(e) => setUuid(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  select
                  margin="normal"
                  required
                  id="outlined-required"
                  label="Rating"
                  onChange={(e) => setRating(e.target.value)}
                  value={rating}
                >
                  {[1, 2, 3, 4, 5].map((num) => (
                    <MenuItem key={num} value={num}>
                      {num}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  required
                  id="outlined-required"
                  label="Reviewer First Name"
                  margin="normal"
                  fullWidth
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  required
                  id="outlined-required"
                  label="Reviewer Last Name"
                  margin="normal"
                  fullWidth
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                />
              </Grid>
            </Grid>

            <TextField
              margin="normal"
              required
              id="outlined-required"
              label="Review Feedback"
              multiline
              rows={5}
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </Grid>
        </Grid>
      )}

      {showTextBox === 'yes' && (
        <TextField
          fullWidth
          multiline
          minRows={10}
          margin="normal"
          value={unControlledUpload}
          onChange={(e) => {
            setUncontrolledUpload(e.target.value);
          }}
          helperText={
            <>
              <b>Tip: </b>
              <span>This was built for CSV files. Use a CSV to JSON tool, and then copy and paste into here!</span>
            </>
          }
        />
      )}
      <Button variant="contained" size="large" onClick={onSubmitReview} disabled={!isFilledOut} sx={{ marginTop: '8px' }}>
        Submit Review
      </Button>
    </Grid>
  );
};

export default AdminSeed;
