import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  IconButton,
  TableCell,
  TableCellProps,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import useSkills, { Skill } from '../../../../hooks/useSkills';
import { SearchCode as ISearchCode } from '../../../../hooks/useSearchModels';
import { SolaceApiError } from '../../../../utils/errors';
import { orderBy } from 'lodash';
import { IFormik } from '../../../../types';
import InfoIcon from '@mui/icons-material/Info';
import useSearchModels from '../../../../hooks/useSearchModels';

type Props = {
  searchCode: ISearchCode;
};

const EditableTableCell = ({
  editing,
  valueKey,
  formik,
  ...rest
}: TableCellProps & {
  editing: boolean;
  valueKey: string;
  formik: IFormik;
}) => {
  return (
    <TableCell {...rest}>
      {editing ? (
        <TextField
          multiline
          variant="standard"
          onChange={(e) => {
            const newValue: string | number = e.target.value;
            formik.setFieldValue(valueKey, String(newValue));
          }}
          value={formik.values[valueKey]}
        />
      ) : (
        formik.values[valueKey]
      )}
    </TableCell>
  );
};

export const SearchCode = ({ searchCode }: Props) => {
  const { saveCode } = useSearchModels();

  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);

  const { data: skills, loading: loadingSkills } = useSkills();

  const SKILLS = useMemo(() => {
    const all: { [id: string]: Skill } = {};
    skills.forEach((skill) => (all[skill.id] = skill));

    return all;
  }, [skills]);

  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      ...searchCode,
      skills: searchCode.skills.map((skill) => skill.id),
    },
    onSubmit: (values) => {
      const { skills, ...rest } = values;
      saveCode({ searchCode: rest, skills: skills })
        .then(() => {
          enqueueSnackbar(`Successfully updated search code ${searchCode.id}`, { variant: 'success' });
          setEditing(false);
        })
        .catch((err: SolaceApiError) => {
          console.log(err.message);
          enqueueSnackbar(`Failed to update search code ${searchCode.id} - ${err.error}`, { variant: 'error' });
        });
    },
  });

  const handleChange = (value: string[]) => {
    formik.setFieldValue('skills', value);
  };

  const handleSetEditing = () => {
    setEditing(true);
    setOpen(true);
  };

  const handleSubmit = (e: any) => {
    formik.handleSubmit(e);
    setEditing(false);
  };

  if (loadingSkills) return null;

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <strong style={{ fontSize: 16 }}>{searchCode.code}</strong>
        </TableCell>
        <EditableTableCell colSpan={2} width="48px" formik={formik} valueKey="description" editing={editing} />
        <EditableTableCell width="12px" formik={formik} valueKey="priority" editing={editing} />
        <EditableTableCell width="12px" formik={formik} valueKey="distance_score_multiplier" editing={editing} />
        <EditableTableCell width="12px" formik={formik} valueKey="value" editing={editing} />

        <TableCell>
          {editing ? (
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setEditing(false);
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button variant="contained" size="small" sx={{ marginLeft: '5px' }} onClick={handleSubmit}>
                Save
              </Button>
            </Box>
          ) : (
            <Button variant="outlined" size="small" sx={{ marginLeft: 5 }} onClick={handleSetEditing}>
              Edit
            </Button>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: '24px 0' }}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Tooltip
                  title="Will be shown on the advocates profile if this code is their highest ranking priority match."
                  placement="top"
                  sx={{ cursor: 'pointer' }}
                >
                  <Typography variant="h6" component="div" lineHeight="22px">
                    Advocate Profile Context
                    <InfoIcon htmlColor="black" sx={{ marginLeft: '5px' }} fontSize="small" />
                  </Typography>
                </Tooltip>
              </Box>
              <Typography component="p" fontSize={12} m="0 0 12px"></Typography>
              {editing ? (
                <TextField
                  fullWidth
                  multiline
                  name="advocate_profile_context"
                  variant="standard"
                  onChange={formik.handleChange}
                  value={formik.values.advocate_profile_context}
                />
              ) : (
                <Typography component="p">{formik.values.advocate_profile_context}</Typography>
              )}
            </Box>

            <Box sx={{ margin: '24px 0' }}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Tooltip
                  title="Will be shown on the advocate's search result if this code is their highest ranking priority match."
                  placement="top"
                  sx={{ cursor: 'pointer' }}
                >
                  <Typography variant="h6" component="div" lineHeight="22px">
                    Search Result Context
                    <InfoIcon htmlColor="black" sx={{ marginLeft: '5px' }} fontSize="small" />
                  </Typography>
                </Tooltip>
              </Box>
              <Typography component="p" fontSize={12} m="0 0 12px"></Typography>
              {editing ? (
                <TextField
                  fullWidth
                  multiline
                  name="search_result_context"
                  variant="standard"
                  onChange={formik.handleChange}
                  value={formik.values.search_result_context}
                />
              ) : (
                <Typography component="p">{formik.values.search_result_context}</Typography>
              )}
            </Box>

            <Box sx={{ margin: '24px 0' }}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h6" component="div">
                  Skills
                </Typography>
              </Box>
              {!editing && Object.keys(formik.values.skills).length === 0 && <h4>No skills set</h4>}
              {Object.keys(formik.values.skills).length > 0 &&
                orderBy(Object.values(formik.values.skills)).map((skillId) => (
                  <Box key={`${searchCode.id}-${skillId}`}>{SKILLS[skillId].name}</Box>
                ))}
              {editing && (
                <Autocomplete
                  multiple
                  options={orderBy(Object.keys(SKILLS), 'id')}
                  getOptionLabel={(skillId: string) => SKILLS[skillId].name}
                  freeSolo
                  defaultValue={orderBy(searchCode.skills, 'id').map((skill) => skill.id)}
                  onChange={(_, newInputValue) => {
                    handleChange(newInputValue as Skill['id'][]);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} name="skill" variant="standard" placeholder="Search skills..." />
                  )}
                />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
