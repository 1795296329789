import { Paper, Accordion, AccordionSummary, AccordionDetails, LinearProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useGetSearchResults from '../../../hooks/useGetSearchResults';

const Title = ({ children }: { children: React.ReactNode }) => {
  return <strong>{children}</strong>;
};

type Props = { page: number };

export const SearchResults = ({ page }: Props) => {
  const { data: searchResults, loading } = useGetSearchResults({ page });

  if (loading) {
    return (
      <div>
        <h1>Loading...</h1>
        <LinearProgress style={{ height: '2px', width: '500px', marginTop: '24px' }} />
      </div>
    );
  }

  return (
    <div>
      {searchResults.map((searchResult) => (
        <Paper sx={{ marginBottom: '24px', padding: '12px' }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon style={{ marginRight: '24px', width: '100%' }} />}>
              <div>
                <h2>{new Date(searchResult.search_dt).toLocaleString()}</h2>
                <div>{searchResult.prospect?.how_can_we_help || 'No information provided'}</div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <Title>Date:</Title> {new Date(searchResult.search_dt).toLocaleString()}
              </div>
              <div>
                <Title>Params:</Title> <pre>{JSON.stringify(searchResult.params, null, 2)}</pre>
              </div>
              <h2>Listings</h2>
              {searchResult.search_result_listings.map((searchResultListing) => (
                <div style={{ border: '1px solid black', padding: '24px', marginBottom: '5px' }}>
                  <h3>
                    #{searchResultListing.rank}:{' '}
                    <Link to={`/Users/${searchResultListing.listing.user.id}`}>
                      {searchResultListing.listing.advocate_name}
                    </Link>
                  </h3>
                  <div>
                    <Title>Scores:</Title> <pre>{JSON.stringify(searchResultListing.scores, null, 2)}</pre>
                  </div>
                  <h3>AI Matching Details</h3>
                  <div>
                    <div>
                      <Title>Attribute:</Title> {searchResultListing.vector_match_profile_attribute}
                    </div>
                    <div>
                      <Title>Content:</Title> {searchResultListing.vector_match_content}
                    </div>
                  </div>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
        </Paper>
      ))}
    </div>
  );
};
