import useSWR from 'swr';
import fetcher from './fetcher';

type SearchResult = {
  id: string;
  search_dt: string;
  params: Record<string, string>;
  prospect: Record<string, string>;
  search_result_listings: {
    rank: number;
    rankings: { distance: string; efficacy: string; monetary: string; availability: string; is_ai_result: boolean };
    scores: { distance: string; efficacy: string; monetary: string; availability: string; is_ai_result: boolean };
    vector_match_profile_attribute: string;
    vector_match_content: string;
    listing: {
      advocate_name: string;
      user: {
        id: number;
      };
    };
  }[];
};

const useGetSearchResults = ({ page }: { page: number }): { data: SearchResult[]; loading: boolean; error: Error } => {
  const { data, error } = useSWR<SearchResult[]>(`/v1/api/search_results?page=${page}&perPage=20`, fetcher);

  return {
    data: data || [],
    loading: data === undefined,
    error,
  };
};

export default useGetSearchResults;
