import styled from '@emotion/styled';
import { Button, Typography } from '@solace-health/ui';
import { textCompare } from '../../../../shared/utils/table-utils';
import { phoneNumberFormat } from '../../../../utils/general';

import { useNavigate } from 'react-router-dom';
import { AdminTable } from '../../../../components/shared/Table';
import useGetUsers from '../../../../hooks/users/useGetUsers';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ClientsPage = () => {
  const navigate = useNavigate();
  const { data: clients, isLoading } = useGetUsers({ filter: { clients: true } });

  const columns = [
    {
      title: 'ID',
      dataIndex: 'internal_id',
      render: (id: string, data: any) => <Button.Link onClick={() => navigate(`/Users/${data.id}`)}>{id}</Button.Link>,
      sorter: (a: { internal_id: number }, b: { internal_id: number }) => a.internal_id - b.internal_id,
      defaultSortOrder: 'descend' as const,
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      sorter: (a: { first_name: string }, b: { first_name: string }) => textCompare(a, b, 'first_name'),
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      sorter: (a: { last_name: string }, b: { last_name: string }) => textCompare(a, b, 'last_name'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a: { email: string }, b: { email: string }) => textCompare(a, b, 'email'),
      render: (email: string) => <div style={{ maxWidth: '300px' }}>{email}</div>,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      sorter: (a: { phone: string }, b: { phone: string }) => Number(a.phone) - Number(b.phone),
      render: (phone: string) => phoneNumberFormat(phone, ''),
    },
  ];

  const tableData = clients.map((client) => ({
    key: client.internal_id,
    id: client.id,
    internal_id: client.internal_id,
    full_name: `${client.first_name} ${client.last_name}`,
    first_name: client.first_name,
    last_name: client.last_name,
    email: client.email,
    phone: client.phone,
  }));

  return (
    <Container>
      <Typography.Display>Clients</Typography.Display>

      <AdminTable
        loading={isLoading}
        columns={columns}
        data={tableData}
        onRowClick={(user) => {
          navigate(`/Users/${user.id}`);
        }}
      />
    </Container>
  );
};

export default ClientsPage;
