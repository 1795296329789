import { User } from './user';
import { VideoCall } from './videoCall';

export type DefaultBookingIncludes = { advocate: false; client: false };

export enum BookingState {
  Accepted = 'accepted',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Pending = 'pending',
  NotaMatch = 'not_a_match',
  Proposed = 'proposed',
  Rejected = 'rejected',
  Rescheduled = 'rescheduled',
  Stale = 'stale',
  AdvocateNoShow = 'advocate_no_show',
  ClientNoShow = 'client_no_show',
}

// TODO-This needs to be updated with the Care Plan status assuming work that will be completing in the upcoming CHW CARE PLAN project
export enum ApptStatusEnum {
  CallRescheduled = 'call_rescheduled',
  CallScheduled = 'call_scheduled',
  CarePlanOnFile = 'care_plan_on_file',
  NeedsCarePlan = 'needs_care_plan',
  AdvocateNoShow = 'advocate_no_show',
  ClientNoShow = 'client_no_show',
  CallCancelled = 'call_cancelled',
}

export enum BookingWith {
  Physician = 'physician',
  Advocate = 'advocate',
}

export type Booking = {
  id: string;
  advocate_id: string;
  advocate?: User;
  client_id: string;
  client?: User;
  end: string;
  is_free: boolean;
  is_intro_call: boolean;
  start: string;
  state: BookingState;
  transaction_id: string;
  stripe_transfer_id?: string;
  original_booking_id: string;
  previous_booking_id: string;
  video_call_id: string | null;
  video_call: VideoCall;
  cost: number;
  created_at: Date;
  booking_with?: BookingWith; // requires advocate.traits to be requested
  call_transcript_items?: { id: string }[];
};
