import useSWR from 'swr';
import fetcher from './fetcher';
import qs from 'qs';
import { User } from '../types/user';

export type GetInternalUserResponse<T> = User & T;

export const useGetInternalUser = <T>({
  id,
  includes = [],
}: {
  id?: User['id'];
  includes?: string[];
}): {
  data: GetInternalUserResponse<T> | null;
  loading: boolean;
  refresh: () => void;
  error: Error;
} => {
  const shouldFetch = !!id;

  const urlParams = qs.stringify({ includes });
  const {
    data: user,
    error,
    mutate,
  } = useSWR<GetInternalUserResponse<T>>(shouldFetch ? `/v1/api/internal_users/${id}?${urlParams}` : null, fetcher);

  return {
    data: (user as GetInternalUserResponse<T>) || null,
    loading: user === undefined,
    error,
    refresh: mutate,
  };
};
