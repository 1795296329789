import { Typography } from '@solace-health/ui';
import usePatientLinks from '../../../hooks/commonwell/usePatientLinks';
import { HealthRecord } from '../../../types/healthRecord';
import { useSnackbar } from 'notistack';
import { PatientLinkTable } from './shared/PatientLinkTable';

export const PatientLinks = ({ healthRecordId }: { healthRecordId: HealthRecord['id'] }) => {
  const { patientLinks, refresh, isLoading, deletePatientLink } = usePatientLinks({ healthRecordId });
  const { enqueueSnackbar } = useSnackbar();

  const confirmUnlinkPatient = async (linkId: string) => {
    return await deletePatientLink(linkId)
      .then(() => {
        enqueueSnackbar('Successfully unlinked', { variant: 'success' });
        refresh();
      })
      .catch((e) => {
        enqueueSnackbar(`Failed to unlink: ${e.message}`, { variant: 'error' });
      });
  };

  return (
    <>
      <Typography.Header>Patient Links</Typography.Header>
      <PatientLinkTable
        patientLinks={patientLinks}
        isLoading={isLoading}
        actionText="unlink"
        onConfirmAction={confirmUnlinkPatient}
      />
    </>
  );
};
