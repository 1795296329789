import { Box, Button, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import useSearchModels, { SearchModel, SearchModelSlug } from '../../../../hooks/useSearchModels';
import { useState } from 'react';
import { useFormik } from 'formik';
import { SolaceApiError } from '../../../../utils/errors';
import { useSnackbar } from 'notistack';

export const EditModel = ({ searchModel }: { searchModel: SearchModel }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isEditing, setIsEditing] = useState(false);

  const { saveModel } = useSearchModels();

  const formik = useFormik({
    initialValues: searchModel,
    onSubmit: (values) => {
      saveModel({ slug: values.slug, body: { name: values.name, engine_id: values.engine_id } })
        .then(() => {
          enqueueSnackbar(`Successfully updated search model ${values.name}`, { variant: 'success' });
          setIsEditing(false);
        })
        .catch((err: SolaceApiError) => {
          console.log(err.message);
          enqueueSnackbar(`Failed to update search model ${searchModel.name} - ${err.error}`, { variant: 'error' });
        });
    },
  });

  const handleSubmit = (e: any) => {
    formik.handleSubmit(e);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        {isEditing ? (
          <Button variant="outlined" sx={{ padding: '5px', minWidth: '0', marginRight: '10px' }} onClick={handleSubmit}>
            Save
          </Button>
        ) : (
          <Button
            variant="outlined"
            sx={{ padding: '5px', minWidth: '0', marginRight: '10px' }}
            onClick={() => setIsEditing(true)}
          >
            <EditIcon fontSize="small" />
          </Button>
        )}
        {isEditing ? (
          <TextField multiline variant="standard" name="name" onChange={formik.handleChange} value={formik.values.name} />
        ) : (
          <h1>{searchModel.name}</h1>
        )}
      </Box>

      {isEditing && searchModel.slug !== SearchModelSlug.GRAY ? (
        <TextField
          multiline
          variant="standard"
          name="engine_id"
          onChange={formik.handleChange}
          value={formik.values.engine_id}
          fullWidth
          sx={{ maxWidth: '500px' }}
        />
      ) : (
        <code>{searchModel.engine_id}</code>
      )}
    </Box>
  );
};
