import { TableRow, TableCell, TextField, Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useFormik } from 'formik';
import useAlgorithmVariables, {
  AlgorithmVariableSlug,
  AlgorithmVariable as IAlgorithmVariable,
} from '../../../../hooks/useAlgorithmVariables';
import { SolaceApiError } from '../../../../utils/errors';
import { useSnackbar } from 'notistack';

type Props = {
  algorithmVariable: IAlgorithmVariable;
};

const formatValues = (algorithmVariable: IAlgorithmVariable) => {
  let newConfig: Omit<IAlgorithmVariable, 'value'> & { value: string };

  let value;
  if (typeof algorithmVariable.value === 'object') {
    value = JSON.stringify(algorithmVariable.value);
  } else {
    value = algorithmVariable.value.toString();
  }

  newConfig = { ...algorithmVariable, value };

  return newConfig;
};

const hasJSONValue = (slug: AlgorithmVariableSlug) => {
  return [
    AlgorithmVariableSlug.ADVOCATE_BONUSES,
    AlgorithmVariableSlug.CHW_BONUSES,
    AlgorithmVariableSlug.AVAILABILITY_TIERS,
    AlgorithmVariableSlug.DISTANCE_TIERS,
    AlgorithmVariableSlug.PHYSICIAN_BONUSES,
  ].includes(slug);
};

const isValidJSON = (json: string) => {
  try {
    JSON.parse(json);
    return true;
  } catch (e) {
    return false;
  }
};

const Value = ({ editing, formik, slug }: { editing: boolean; formik: any; slug: AlgorithmVariableSlug }) => {
  const value = formik.values.value;

  if (hasJSONValue(slug)) {
    return editing ? (
      <>
        <TextField
          fullWidth
          multiline
          name="value"
          variant="standard"
          onChange={(e) => {
            formik.setFieldValue('value', e.target.value);
          }}
          value={value}
        />
        {isValidJSON(value) ? null : <span style={{ color: 'var(--failColor)' }}>{slug} is not valid JSON</span>}
      </>
    ) : (
      <pre>{JSON.stringify(JSON.parse(value), null, 4)}</pre>
    );
  }

  return editing ? (
    <TextField
      fullWidth
      multiline
      name="value"
      variant="standard"
      onChange={formik.handleChange}
      value={formik.values.value}
    />
  ) : (
    <Typography component="p">{formik.values.value}</Typography>
  );
};

export const AlgorithmVariable = ({ algorithmVariable }: Props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { refresh, saveAlgorithmVariable } = useAlgorithmVariables();

  const [editing, setEditing] = useState(false);

  const formik = useFormik({
    initialValues: formatValues(algorithmVariable),
    onSubmit: (values) => {
      let newConfig: IAlgorithmVariable;

      let value = values.value;
      if (hasJSONValue(algorithmVariable.slug)) {
        value = JSON.parse(values.value);
      }

      newConfig = { ...values, value };

      saveAlgorithmVariable({ algorithmVariable: newConfig })
        .then(() => {
          refresh();
          enqueueSnackbar(`Successfully updated ${algorithmVariable.slug}`, { variant: 'success' });
          setEditing(false);
        })
        .catch((err: SolaceApiError) => {
          console.log(err.message);
          const key = enqueueSnackbar(`Failed to update - ${err.error}`, {
            variant: 'error',
            persist: true,
            onClick: () => {
              closeSnackbar(key);
            },
          });
        });
    },
  });

  const handleSetEditing = () => {
    setEditing(true);
  };

  return (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell>
        {editing ? (
          <TextField
            fullWidth
            multiline
            name="name"
            variant="standard"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
        ) : (
          <strong style={{ fontSize: 16 }}>{algorithmVariable.name}</strong>
        )}
      </TableCell>
      <TableCell>
        <code>{algorithmVariable.slug}</code>
      </TableCell>
      <TableCell>
        <Value editing={editing} formik={formik} slug={algorithmVariable.slug} />
      </TableCell>
      <TableCell>
        {new Date(algorithmVariable.active_since_dt).toLocaleString('en-US', {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })}
      </TableCell>
      <TableCell>
        {editing ? (
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                formik.setValues(formatValues(algorithmVariable));
                setEditing(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="small"
              sx={{ marginLeft: '5px' }}
              onClick={(e: any) => formik.handleSubmit(e)}
            >
              Save
            </Button>
          </Box>
        ) : (
          <Button variant="outlined" size="small" sx={{ marginLeft: 5 }} onClick={handleSetEditing}>
            Edit
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};
