/**
 * Warning: This code is ugly and might have bugs. It was a quick prototype to be able to view care plans in JSON format
 * because the data is encrypted in the database.
 */
import { FormElementOption, FormElementResponse } from '../../../../types/forms';

export const parseContent = ({
  formElementOptions,
  content,
}: {
  formElementOptions: FormElementOption;
  content: FormElementResponse['content'];
}) => {
  try {
    if (formElementOptions.type === 'select') {
      const selection = formElementOptions.options.options.find((option) => option.value === content);
      return `${selection?.label || 'N/A'} ${formElementOptions.options.unit}`;
    }

    if (formElementOptions.type === 'nested_checkbox') {
      const checkboxContent = content as { text: string; checkedKeys: string[] };
      if (!checkboxContent.checkedKeys) return '';

      let str = '';
      const options = formElementOptions.options.options;
      options.forEach((option) => {
        if (checkboxContent.checkedKeys.includes(option.key)) {
          str += option.title;
        } else {
          return;
        }

        str += ' (';
        option.children.forEach((child) => {
          if (checkboxContent.checkedKeys.includes(child.key)) {
            str += child.title;
          }
        });

        str += '). ';
      });

      return str + checkboxContent.text;
    }

    if (Array.isArray(content)) return content.map((c) => c.text).join('. ');

    return content.toString();
  } catch (e) {
    console.error(e);
    return 'Failed to parse form content';
  }
};
