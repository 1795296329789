import { formatInTimeZone } from 'date-fns-tz';
import { roundNumberFixed2 } from '../utils/gen-utils';
export const zeroPad = (num: any, places: any) => String(num).padStart(places, '0');

export const dateToBusinessDayString = (inpDate: Date): string => {
  const dt = inpDate;
  let business_day = formatInTimeZone(inpDate, 'America/Chicago', 'yyyy-MM-dd');

  const parts = getTimeZonedParts(dt, 'America/Chicago');

  // group 2AM - 1:59AM push back entries to previouse day if 0-2
  if (parts.HH < 2) {
    const shiftedDate = new Date(business_day);
    shiftedDate.setDate(shiftedDate.getDate() - 1);
    business_day = [
      shiftedDate.getUTCFullYear(),
      zeroPad(shiftedDate.getUTCMonth() + 1, 2),
      zeroPad(shiftedDate.getUTCDate(), 2),
    ].join('-'); //format(shiftedDate, 'yyyy-MM-dd HH:mm:ss');
  }
  return business_day;
};

export const getMontlyProratePercent = () => {
  const today = new Date();
  const totalDays = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
  const daysLeft = totalDays - today.getDate() - 1;
  return roundNumberFixed2(daysLeft / totalDays);
};

export const safeFormatInTimeZone = (dateVal: any, timeZone: any, timeFormat: any) => {
  let val = '';
  try {
    val = formatInTimeZone(dateVal, timeZone, timeFormat);
  } catch (e) {
    console.log(e);
  }
  return val;
};

//'America/Chicago'
export const getTimeZonedParts = (dt: Date, timezone: string) => {
  return {
    yyyy: Number(formatInTimeZone(dt, timezone, 'yyyy')),
    MM: Number(formatInTimeZone(dt, timezone, 'MM')),
    dd: Number(formatInTimeZone(dt, timezone, 'dd')),
    HH: Number(formatInTimeZone(dt, timezone, 'HH')),
    mm: Number(formatInTimeZone(dt, timezone, 'mm')),
  };
};

export const getUTCParts = (dt: Date) => {
  return {
    yyyy: dt.getUTCFullYear(),
    m: dt.getUTCMonth(),
    dd: dt.getUTCDate(),
    hh: dt.getUTCHours(),
    mm: dt.getUTCMinutes(),
  };
};

export enum DateFormat {
  Short = 'MM/DD/YYYY',
}

export const dateFormatted = (date: any, format: string, seperator = '/') => {
  try {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hour = d.getHours(),
      minute = d.getMinutes(),
      second = d.getSeconds();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    switch (format) {
      case 'yyyymmdd':
        return [year, month, day].join(seperator);
      case 'yyyymmddhh':
        return [year, month, day].join(seperator) + ' ' + zeroPad(hour, 2) + ':00:00';
      case 'yyyymmddhhmmss':
        return (
          [year, month, day].join(seperator) + ' ' + zeroPad(hour, 2) + ':' + zeroPad(minute, 2) + ':' + zeroPad(second, 2)
        );
      case 'mmddyyyy':
      default:
        return [month, day, year].join(seperator);
    }
  } catch (e) {
    return '';
  }
};

export const mmddyyyySort = (a: any, b: any) => {
  if (a && b) {
    const aDate = new Date(a);
    const bDate = new Date(b);
    return aDate.getTime() - bDate.getTime();
  } else {
    return 0;
  }
};

export const dateFmt = (dateStr: string | undefined) => {
  try {
    if (!dateStr) {
      return '';
    }
    const dt = new Date(dateStr);
    dt.setMilliseconds(0);
    return dt.toISOString().replace(/T/, ' ').replace(/.000Z/, '');
  } catch (e) {
    return '';
  }
};

export const isValidDate = (date: any) => {
  return new Date(date).toString() !== 'Invalid Date';
};

export const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
