import { useNavigate, useParams } from 'react-router-dom';
import { HealthRecord } from '../../../types/healthRecord';
import { Patient } from './Patient';
import { PatientLinks } from './PatientLinks';
import { Button, Flex, Tabs } from '@solace-health/ui';
import { ResetPatientLinks } from './ResetPatientLinks';
import { ProbableLinks } from './ProbableLinks';
import { MergePatient } from './MergePatient';
import { DeletePatient } from './DeletePatient';

export const HealthRecordPage = () => {
  const navigate = useNavigate();
  const { id: healthRecordId } = useParams<{ id: HealthRecord['id'] }>();

  if (!healthRecordId) return null;

  return (
    <Flex vertical gap="1rem">
      <Button.Outline style={{ width: '100px' }} onClick={() => navigate('/health_records')}>
        Back
      </Button.Outline>
      <Patient healthRecordId={healthRecordId} />
      <Flex gap="1rem" wrap="wrap">
        <ResetPatientLinks healthRecordId={healthRecordId} />
        <MergePatient healthRecordId={healthRecordId} />
        <DeletePatient healthRecordId={healthRecordId} />
      </Flex>

      <Tabs
        defaultActiveKey="patient_links"
        items={[
          {
            label: 'Patient Links',
            key: 'patient_links',
            children: <PatientLinks healthRecordId={healthRecordId} />,
          },
          {
            label: 'Probable Links',
            key: 'probable_links',
            children: <ProbableLinks healthRecordId={healthRecordId} />,
          },
        ]}
      />
    </Flex>
  );
};
