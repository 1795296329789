import { Button, Divider, Flex, Modal, Size, Typography } from '@solace-health/ui';
import { GetUserResponse } from '../../../../../hooks/useGetUser';
import { Referral, ReferralEligibilityCheck, ReferralEligibilityCheckStatus } from '../../../../../types/referrals';
import StatusPill from '../../../../../components/sharedStatusPill/StatusPill';
import { capitalize } from 'lodash';
import dayjs from 'dayjs';
import { useState } from 'react';
import { AdminTable } from '../../../../../components/shared/Table';
import useGetUserEligibilityChecks from '../../../../../hooks/referrals/useGetUserEligibilityChecks';

type EligibilityCheckSectionProps = {
  patient: GetUserResponse;
};

export default function EligibilityCheckSection({ patient }: EligibilityCheckSectionProps) {
  const { data } = useGetUserEligibilityChecks({ userId: patient.sharetribe_uuid });
  const [referral, setReferral] = useState<(typeof data)[0]['referral']>();
  const [eligibilityCheck, setEligibilityCheck] = useState<(typeof data)[0]>();
  const onClick = (idForModal: string) => {
    setEligibilityCheck(data.find((x) => x.id === idForModal));
  };

  const formattedData = data.map((item) => ({
    name: `${item.referral?.user?.first_name} ${item.referral?.user?.last_name?.[0]}.`,
    ...item,
  }));

  const pillColorOptions = {
    [ReferralEligibilityCheckStatus.Eligible]: {
      backgroundColor: 'var(--green-100, #F4F8F7)',
      borderColor: 'var(--border-color-dark-green, #285E50)',
    },
    [ReferralEligibilityCheckStatus.Unchecked]: {
      backgroundColor: 'var(--grey-100, #EEE)',
      borderColor: 'var(--border-color-dark, #555)',
    },
    [ReferralEligibilityCheckStatus.Failed]: {
      backgroundColor: 'var(--danger-bg-100, #F1E1E2)',
      borderColor: 'var(--border-color-danger, #DA4D54)',
    },
    [ReferralEligibilityCheckStatus.Ineligible]: {
      backgroundColor: 'var(--danger-bg-100, #F1E1E2)',
      borderColor: 'var(--border-color-danger, #DA4D54)',
    },
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'submitted_dt',
      sorter: (a: ReferralEligibilityCheck, b: ReferralEligibilityCheck) => dayjs(b.submitted_dt).diff(a.submitted_dt),
      render: (date: Date | null) => (date ? new Date(date).toLocaleDateString() : '-'),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Policy Holder',
      render: (row: ReferralEligibilityCheck & { referral?: Referral; name: string }) => row.name,
    },
    {
      title: 'Insurance Type',
      render: (row: ReferralEligibilityCheck & { referral?: Referral; insuranceType: string }) =>
        capitalize(row.referral?.prospect?.payload.insurance_path),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: ReferralEligibilityCheckStatus) => (
        <StatusPill
          backgroundColor={pillColorOptions[status]?.backgroundColor || 'var(--grey-100, #EEE)'}
          borderColor={pillColorOptions[status]?.borderColor || 'var(--border-color-dark, #555)'}
        >
          {capitalize(status)}
        </StatusPill>
      ),
      onFilter: (value: ReferralEligibilityCheckStatus, record: ReferralEligibilityCheck) =>
        record.status.indexOf(value) === 0,
      filters: [
        { text: capitalize(ReferralEligibilityCheckStatus.Eligible), value: ReferralEligibilityCheckStatus.Eligible },
        { text: capitalize(ReferralEligibilityCheckStatus.Unchecked), value: ReferralEligibilityCheckStatus.Unchecked },
        { text: capitalize(ReferralEligibilityCheckStatus.Failed), value: ReferralEligibilityCheckStatus.Failed },
        { text: capitalize(ReferralEligibilityCheckStatus.Ineligible), value: ReferralEligibilityCheckStatus.Ineligible },
      ],
    },

    {
      title: '',
      render: (
        row: ReferralEligibilityCheck & { referral: Referral & { prospect: { first_name: string; last_name: string } } },
      ) => <Button.Link onClick={() => setReferral(row.referral)}>View Request</Button.Link>,
    },
    {
      title: '',
      render: (row: ReferralEligibilityCheck & { referral: Referral }) => (
        <Button.Link onClick={() => onClick(row.id)}>View Response</Button.Link>
      ),
    },
  ];

  return (
    <>
      <Flex vertical gap="1rem">
        <Typography.Header>Eligibility Checks</Typography.Header>
        <AdminTable columns={columns} data={formattedData ?? data} showSearch={false} />
      </Flex>

      <Modal isOpen={!!eligibilityCheck} onClose={() => setEligibilityCheck(undefined)}>
        <Typography.Header
          size={Size.LG}
        >{`${eligibilityCheck?.referral?.prospect?.first_name} ${eligibilityCheck?.referral?.prospect?.last_name?.[0]}.`}</Typography.Header>
        <Typography.Body>Submitted: {new Date(eligibilityCheck?.submitted_dt || '').toLocaleDateString()}</Typography.Body>
        <Divider />
        <pre>{JSON.stringify(eligibilityCheck?.result_payload, null, 2)}</pre>
      </Modal>
      <Modal isOpen={!!referral} onClose={() => setReferral(undefined)}>
        <Typography.Header
          size={Size.LG}
        >{`${referral?.prospect?.first_name} ${referral?.prospect?.last_name?.[0]}.`}</Typography.Header>
        <Divider />
        <pre>{JSON.stringify(referral?.eligibility_check_data, null, 2)}</pre>
      </Modal>
    </>
  );
}
