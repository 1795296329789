import useSWR from 'swr';
import fetcher from './fetcher';

type Data = {
  quality: number;
  efficacy: number;
  monetary: number;
};

const useGetListingScoreMedians = (): { data?: Data; loading: boolean; error: Error } => {
  const { data, error } = useSWR<Data>(`/v1/api/listing_scores/medians`, fetcher);

  return {
    data,
    loading: data === undefined && !error,
    error,
  };
};

export default useGetListingScoreMedians;
