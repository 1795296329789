import { Button, Flex, Modal, Typography } from '@solace-health/ui';

export const ConfirmActionModal = ({
  isOpen,
  onClose,
  prompt,
  confirmText,
  onConfirm,
}: {
  isOpen: boolean;
  prompt: string | JSX.Element;
  confirmText: string;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Flex vertical gap="1rem">
        <Typography.Header>{prompt}</Typography.Header>
        <Flex gap="1rem">
          <Button.Secondary onClick={onClose}>Cancel</Button.Secondary>
          <Button.Primary onClick={onConfirm}>{confirmText}</Button.Primary>
        </Flex>
      </Flex>
    </Modal>
  );
};
