import useSWR from 'swr';
import fetcher from './fetcher';
import axios from 'axios';
import { User } from '../types/user';

type Query = {
  is_public?: 'true' | 'false';
};

export type Review = {
  id: string;
  deleted_dt: Date;
  is_public: boolean;
  reviewer_id: number;
  reviewer?: User;
  reviewee?: User;
};

const useReviews = ({ query = {} }: { query?: Query } = {}) => {
  const urlParams = new URLSearchParams(query);
  const { data, error, mutate } = useSWR<Review[]>(`/v1/api/reviews?${urlParams.toString()}`, fetcher);

  const updateReview = ({ id, body }: { id: string; body: { deleted_dt?: Date; is_public?: boolean } }) => {
    const token = localStorage.getItem('accessToken');
    const headers = { Authorization: `Bearer ${token}` };
    return axios.put(`${process.env.REACT_APP_API_HOST}/v1/api/reviews/${id}`, body, { headers });
  };

  return {
    data: data || [],
    error,
    refresh: mutate,
    updateReview,
  };
};

export default useReviews;
