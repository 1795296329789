import { Flex, Icons, Size, Typography } from '@solace-health/ui';
import { User, UserNote } from '../../../../../../types/user';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { SolaceAPI } from '../../../../../../utils/api';
import { useSnackbar } from 'notistack';
import * as S from './style';
import { timeZoneAbbr } from '../../../../../../utils/general';
import useCurrentUser from '../../../../../../hooks/useCurrentUser';

dayjs.extend(timezone);

type NoteItemProps = {
  note: UserNote;
  onEditNote: (userNote: UserNote | null) => void;
  refresh: () => void;
};

export default function NoteItem({ note, onEditNote, refresh }: NoteItemProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { data: currentUser } = useCurrentUser<User>({});
  const { description, fromUser, created_at } = note;

  const deleteNote = async () => {
    try {
      SolaceAPI.delete({ path: `/api/note/${note.id}` });
      enqueueSnackbar('Successfully deleted note', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`Error: ${error}`, { variant: 'error' });
    } finally {
      refresh();
    }
  };

  return (
    <S.Wrapper>
      <S.Content>{description}</S.Content>
      <Flex vertical gap=".25rem">
        <Typography.Header size={Size.SM}>{`${fromUser?.first_name} ${fromUser?.last_name}`}</Typography.Header>
        <Flex justify="space-between">
          <Typography.Body color="#555555">
            {dayjs(created_at).tz(currentUser?.time_zone).format('MM/DD/YY h:mma')}{' '}
            {currentUser?.time_zone && `(${timeZoneAbbr(currentUser?.time_zone)})`}
          </Typography.Body>
          <Flex gap="1rem">
            <S.Button onClick={() => onEditNote(note)}>
              <Flex gap=".25rem" align="center">
                <Icons.Pencil color="#285E50" />
                <Typography.Header color="#285E50" size={Size.SM}>
                  Edit
                </Typography.Header>
              </Flex>
            </S.Button>
            <S.Button onClick={deleteNote}>
              <Flex gap=".25rem" align="center">
                <Icons.Trash fill="#555555" />
                <Typography.Header color="#555555" size={Size.SM}>
                  Delete
                </Typography.Header>
              </Flex>
            </S.Button>
          </Flex>
        </Flex>
      </Flex>
    </S.Wrapper>
  );
}
