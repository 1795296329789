import { Flex, Icons, Size, Typography, styled } from '@solace-health/ui';
import { GetUserResponse } from '../../../../../hooks/useGetUser';
import dayjs from 'dayjs';
import { Calendar } from './Calendar';
import { Booking } from '../../../../../types/booking';

const InfoItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const InfoItem = ({ icon, label, value }: { icon: React.ReactNode; label: string; value: string }) => {
  return (
    <Flex align="center" gap=".75rem">
      <Flex align="center">{icon}</Flex>
      <Flex align="flex-start" vertical>
        <Typography.Header size={Size.XS}>{label}</Typography.Header>
        <Typography.Body size={Size.LG}>{value}</Typography.Body>
      </Flex>
    </Flex>
  );
};

type Props = {
  user: GetUserResponse;
  originalBooking?: Booking | null;
  showPhysicianAvailability: boolean;
};

export const PatientInfo = ({ user, originalBooking, showPhysicianAvailability }: Props) => {
  return (
    <Flex vertical gap="1.5rem">
      <Flex align="center" gap=".625rem">
        <Calendar />
        <Typography.Header size={Size.LG}>
          {originalBooking ? 'Reschedule' : 'Schedule'} {showPhysicianAvailability ? 'Physician' : 'CHW'} Visit
        </Typography.Header>
      </Flex>
      <Flex justify="space-between">
        <InfoItemContainer>
          <InfoItem icon={<Icons.Person />} label="Patient Name" value={`${user.first_name} ${user.last_name}`} />
          {/* <InfoItem icon={<Icons.Translate />} label="Translator" value="TODO" /> */}
          {originalBooking && (
            <InfoItem
              icon={<Icons.Calendar />}
              label="Original Call Date"
              value={dayjs(originalBooking?.start).format('MM/DD/YYYY h:mma')}
            />
          )}
        </InfoItemContainer>
      </Flex>
    </Flex>
  );
};
