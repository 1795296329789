import { Button, Flex, Form, Icons, Modal, Size, Typography, styled, useForm } from '@solace-health/ui';
import * as S from './style';
import { copyToClipBoard, getPrettyStartEndTime, timeZoneName } from '../../../../../../utils/general';
import dayjs from 'dayjs';
import { TraitId, UserTimeZone } from '../../../../../../types/user';
import { UseJitsi } from '../../../../../../hooks/useJitsi';
import { useState } from 'react';
import useBookings from '../../../../../../hooks/bookings/useBookings';
import { Booking, BookingState } from '../../../../../../types/booking';
import { SolaceAPI } from '../../../../../../utils/api';
import { SolaceApiError } from '../../../../../../utils/errors';
import { useSnackbar } from 'notistack';
import { statusPillOptions } from '../VisitsAndEncounter';
import StatusPill from '../../../../../../components/sharedStatusPill/StatusPill';
import { hasTrait } from '../../../../../../utils/user';
import timezone from 'dayjs/plugin/timezone';
import { dateFormatted } from '../../../../../../shared/utils/date-utils';

dayjs.extend(timezone);

export type ApptDrawerProps = {
  handleClose: () => void;
  appointment: Booking;
  refresh: () => void;
  setSchedulingDrawerOpen: (isOpen: boolean) => void;
  clearSelectedData: () => void;
};

const ItemContainer = styled(Flex)<{ isCancelled?: boolean }>`
  .label * {
    a {
      color: ${({ isCancelled }) => (isCancelled ? 'rgba(0, 0, 0, 0.5)' : '#285e50')};
      font-weight: 600;
    }
    color: ${({ isCancelled }) => (isCancelled ? 'rgba(0, 0, 0, 0.5)' : '#000')};
    font-weight: 400;
  }
  .value * {
    color: ${({ isCancelled }) => (isCancelled ? 'rgba(0, 0, 0, 0.25)' : '#555')};
  }

  svg * {
    fill: ${({ isCancelled }) => (isCancelled ? 'rgba(0, 0, 0, 0.5)' : '#000')};
  }
`;

const InfoItem = ({
  icon,
  label,
  value,
  strikeThrough = false,
  isCancelled = false,
}: {
  icon?: React.ReactNode;
  label: React.ReactNode;
  value?: React.ReactNode;
  strikeThrough?: boolean;
  isCancelled?: boolean;
}) => {
  return (
    <ItemContainer isCancelled={isCancelled}>
      <Flex
        gap="1rem"
        style={{ textDecoration: strikeThrough ? 'line-through' : 'none', textDecorationColor: 'rgba(0,0,0,0.5)' }}
      >
        {icon && icon}
        <Flex justify="center" gap=".25rem" vertical>
          <Flex className="label">
            <Typography.Body>{label}</Typography.Body>
          </Flex>
          {value && (
            <Flex className="value">
              <Typography.Body>{value}</Typography.Body>
            </Flex>
          )}
        </Flex>
      </Flex>
    </ItemContainer>
  );
};

export default function ApptDrawer({
  appointment,
  handleClose,
  refresh,
  setSchedulingDrawerOpen,
  clearSelectedData,
}: ApptDrawerProps) {
  const { phoneNumber } = UseJitsi();
  const cancelForm = useForm({
    defaultValues: {
      cancelled_reason: '',
    },
  });
  const activeUsersTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const cancelledReason = cancelForm.watch('cancelled_reason');
  const { start: adminStartTime, end: adminEndTime } = getPrettyStartEndTime({
    startDate: appointment?.start,
    endDate: appointment?.end,
    timezone: activeUsersTimeZone as UserTimeZone,
  });

  const { start: clientStartTime, end: clientEndTime } = getPrettyStartEndTime({
    startDate: appointment?.start,
    endDate: appointment?.end,
    timezone: appointment?.client?.time_zone as UserTimeZone,
  });
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { updateBooking } = useBookings();
  const [clientNoShowModalOpen, setClientNoShowModalOpen] = useState(false);
  const [CHWNoShowModalOpen, setCHWNoShowModalOpen] = useState(false);

  const getFormattedDate = (date?: Date | string) => dayjs(date).tz(activeUsersTimeZone).format('dddd, MMMM D');

  if (!appointment) return null;

  const isClinician = appointment?.advocate
    ? hasTrait({ user: appointment.advocate, traitId: TraitId.CommunityHealthPhysician })
    : false;

  const cancellable = appointment.state === BookingState.Accepted && new Date(appointment.end) > new Date();

  const handleCancelAppointment = (body: { cancelled_reason: string }) => {
    SolaceAPI.put({
      path: `/api/booking/community_health/${appointment.id}/cancel`,
      body,
    })
      .then(() => {
        cancelForm.reset();
        setIsCancelModalOpen(false);
        refresh();
        enqueueSnackbar(`Successfully cancelled visit!`, { variant: 'success' });
        onClose();
      })
      .catch((response: SolaceApiError) => {
        enqueueSnackbar(`Failed to cancel visit - ${response.error}`, { variant: 'error' });
      });
  };

  const handleNoShow = async (type: 'patient' | 'CHW') => {
    let updatedBooking: Booking | null;
    if (type === 'patient') {
      updatedBooking = await updateBooking(appointment.id, { state: BookingState.ClientNoShow });
    } else {
      updatedBooking = await updateBooking(appointment.id, { state: BookingState.AdvocateNoShow });
    }
    if (!updatedBooking) return;

    setClientNoShowModalOpen(false);
    setCHWNoShowModalOpen(false);
    refresh();
    enqueueSnackbar('No Show Recorded.');
  };

  const handleCopyVideoCallUrl = async () => {
    try {
      const response = await SolaceAPI.get<{ video_call_url: string }>({
        path: `/api/booking/${appointment.id}/video_call_url`,
      });

      copyToClipBoard({ text: response.data.video_call_url });
      enqueueSnackbar(`Copied video call link to clipboard`, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`Could not generate video call link`, { variant: 'error' });
    }
  };

  const { borderColor, backgroundColor, text } = statusPillOptions(appointment.state);
  const isCancelled = appointment.state === BookingState.Cancelled;
  const shouldStrikeThrough = appointment.state === BookingState.Rescheduled || isCancelled;

  const appointmentType = (() => {
    if (!appointment?.advocate) return '';
    if (hasTrait({ user: appointment.advocate, traitId: TraitId.CommunityHealthPhysician })) return 'Clinician Visit';
    if (appointment.is_intro_call) return 'CHW Intro';
    return 'CHW Follow-up';
  })();

  const appointmentCreatedAt = dateFormatted(appointment.created_at, 'MM/DD/YYYY | hh:MM A');
  const appointmentCreatedDay = getFormattedDate(appointment.created_at);

  const rescheduleVisit = () => {
    handleClose();
    setSchedulingDrawerOpen(true);
  };

  const onClose = () => {
    clearSelectedData();
    handleClose();
  };

  const adminTimezone = timeZoneName(activeUsersTimeZone as UserTimeZone);
  const patientTimezone = timeZoneName(appointment?.client?.time_zone as UserTimeZone);

  return (
    <>
      <S.Container>
        <S.InfoContainer>
          <S.Header>
            <Typography.Header>{appointmentType}</Typography.Header>
            <S.StyledCloseButton onClick={handleClose}>Close</S.StyledCloseButton>
          </S.Header>
          <StatusPill borderColor={borderColor} backgroundColor={backgroundColor} strikeThrough={shouldStrikeThrough}>
            {text}
          </StatusPill>
          <Flex vertical gap="1.5rem">
            <InfoItem
              isCancelled={isCancelled}
              icon={<Icons.Person />}
              label="2 Guests"
              value={
                <Flex vertical gap=".25rem">
                  <Typography.Body color="#555555">
                    {`${appointment?.advocate?.first_name} ${appointment?.advocate?.last_name}`}
                  </Typography.Body>
                  <Typography.Body color="#555555">{`${appointment?.client?.first_name} ${appointment?.client?.last_name} - Patient`}</Typography.Body>
                </Flex>
              }
            />
            <InfoItem
              isCancelled={isCancelled}
              icon={<Icons.Schedule />}
              label={getFormattedDate(appointment.start)}
              value={
                <Flex vertical gap={5}>
                  <div>
                    {adminStartTime} - {adminEndTime} {timeZoneName(activeUsersTimeZone as UserTimeZone)}
                  </div>
                  {adminTimezone !== patientTimezone && (
                    <div>
                      ({clientStartTime} - {clientEndTime} {timeZoneName(patientTimezone as UserTimeZone)})
                    </div>
                  )}
                </Flex>
              }
              strikeThrough={isCancelled}
            />
            <InfoItem
              isCancelled={isCancelled}
              icon={<Icons.Copy color="#000" />}
              label={
                <Button.Link color="#285E50" onClick={() => (!isCancelled ? handleCopyVideoCallUrl() : null)}>
                  Copy Video Link
                </Button.Link>
              }
            />
            <InfoItem
              isCancelled={isCancelled}
              icon={<Icons.Call />}
              label={<Typography.Body color="#285E50">{phoneNumber?.formattedNumber}</Typography.Body>}
              value={`Pin: ${appointment.video_call?.pin_code}`}
            />
            <InfoItem
              isCancelled={isCancelled}
              icon={<Icons.Pencil />}
              label="Created On"
              value={
                <Flex vertical gap=".25rem">
                  <Typography.Body color="#555555">{appointmentCreatedDay}</Typography.Body>
                  <Typography.Body color="#555555">{appointmentCreatedAt}</Typography.Body>
                </Flex>
              }
            />
          </Flex>
        </S.InfoContainer>

        {!isCancelled && (
          <>
            <S.Divider />
            <Flex vertical gap="1.5rem">
              <Flex align="center" gap=".625rem">
                <Icons.Hamburger />
                <Typography.Header size={Size.SM}>Actions</Typography.Header>
              </Flex>
              <Button.Link color="#285E50" onClick={() => setClientNoShowModalOpen(true)}>
                Mark Patient No-Show
              </Button.Link>
              <Button.Link color="#285E50" onClick={() => setCHWNoShowModalOpen(true)}>
                {`Mark ${isClinician ? 'Clinician' : 'CHW'} No-Show`}
              </Button.Link>
              <Button.Link color="#285E50" onClick={rescheduleVisit}>
                Reschedule Visit
              </Button.Link>
              {cancellable && (
                <>
                  <Button.Link color="#285E50" onClick={() => setIsCancelModalOpen(true)}>
                    Cancel Visit
                  </Button.Link>
                  <Button.Link color="#285E50" onClick={() => setIsCancelModalOpen(true)}>
                    Patient Not a Match
                  </Button.Link>
                </>
              )}
            </Flex>
          </>
        )}

        {/* Cancel Appt Modal */}
        <Modal isOpen={isCancelModalOpen} onClose={() => setIsCancelModalOpen(false)} destroyOnClose>
          <Form.Container onSubmit={cancelForm.handleSubmit(handleCancelAppointment)} formMethods={cancelForm}>
            <Flex vertical gap="16px">
              <Typography.Header>Are you sure you want to cancel?</Typography.Header>
              <Form.Text
                name="cancelled_reason"
                textArea
                placeholder="Reason..."
                formOptions={{ required: true }}
              ></Form.Text>
              <Flex gap="16px">
                <Button.Outline onClick={() => setIsCancelModalOpen(false)}>Go Back</Button.Outline>
                <Form.Submit disabled={!cancelledReason} style={{ width: '100%' }} />
              </Flex>
            </Flex>
          </Form.Container>
        </Modal>

        {/* Client No Show Modal */}
        <Modal
          isOpen={clientNoShowModalOpen}
          onClose={() => setClientNoShowModalOpen(false)}
          footer={
            <Flex gap="1rem">
              <Button.Secondary onClick={() => setClientNoShowModalOpen(false)}>Cancel</Button.Secondary>
              <Button.Outline color="#DA4D54" onClick={() => handleNoShow('patient')}>
                Mark No-Show
              </Button.Outline>
            </Flex>
          }
        >
          <div style={{ padding: '1rem 0' }}>
            <Typography.Header>Did the patient miss the visit?</Typography.Header>
          </div>
        </Modal>

        {/* CHW No Show Modal */}
        <Modal
          isOpen={CHWNoShowModalOpen}
          onClose={() => setCHWNoShowModalOpen(false)}
          footer={
            <Flex gap="1rem">
              <Button.Secondary onClick={() => setCHWNoShowModalOpen(false)}>Cancel</Button.Secondary>
              <Button.Outline color="#DA4D54" onClick={() => handleNoShow('CHW')}>
                Mark No-Show
              </Button.Outline>
            </Flex>
          }
        >
          <div style={{ padding: '1rem 0' }}>
            <Typography.Header>Did the CHW miss the visit?</Typography.Header>
          </div>
        </Modal>
      </S.Container>
    </>
  );
}
