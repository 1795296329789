import { TimeSpan } from '../../../types/timeSpan';

/**
 * Converts excess minutes into hours and normalizes the time object.
 * @param hours - The hours component of the time.
 * @param minutes - The minutes component of the time.
 * @returns The normalized time object with updated hour and minute values.
 */
export const normalizeTime = ({ hours, minutes }: { hours: number; minutes: number }) => {
  const excessMinutes = minutes % 60;
  const hoursFromMinutes = (minutes - excessMinutes) / 60;

  return { hours: hours + hoursFromMinutes, minutes: excessMinutes };
};

/**
 * Sums the total time and earnings where timeSpans intersects the selectedTimeSpanIds.
 * @param selectedTimeSpanIds - The array of all Time Span IDs that should be included in the calculations.
 * @param timeSpans - The array of time spans to sum if present in the selectedTimeSpanIds array.
 * @returns The object containing the sum of total time (normalized) and earnings.
 */

export const sumTotalTimeAndAmount = ({
  selectedTimeSpanIds,
  timeSpans,
}: {
  selectedTimeSpanIds: TimeSpan['id'][];
  timeSpans: TimeSpan[];
}) => {
  const results = timeSpans
    .filter((timeSpan) => selectedTimeSpanIds.includes(timeSpan.id))
    .reduce(
      (calculations, timeSpan) => {
        calculations.time.hours += timeSpan.total_time.hours;
        calculations.time.minutes += timeSpan.total_time.minutes;
        calculations.amount += timeSpan.estimated_earnings;

        return calculations;
      },
      { time: { hours: 0, minutes: 0 }, amount: 0 },
    );

  results.time = normalizeTime(results.time);

  return results;
};

export const formatCurrency = (amountInCents: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
  }).format(amountInCents / 100);
};
