import MaterialTable from '@material-table/core';
import { tableIcons } from '../../../components/shared/MaterialTableShared';
import useReviews, { Review } from '../../../hooks/useReviews';
import { Accordion, AccordionSummary, AccordionDetails, Button, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { User } from '../../../types/user';
import { Flex } from '@solace-health/ui';
import { phoneNumberFormat } from '../../../utils/general';

export const ReviewsPage = () => {
  const [reviews, setReviews] = useState<{ pending: Review[]; all: Review[] }>({ pending: [], all: [] });
  const { data: allReviews, refresh, updateReview } = useReviews();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const pending = allReviews.filter((review) => !review.is_public && !review.deleted_dt);
    const all = allReviews.filter((review) => review.reviewer_id);

    setReviews({
      pending,
      all,
    });
  }, [allReviews]);

  const onUpdateReview = ({ id, body }: { id: string; body: { deleted_dt?: Date; is_public?: boolean } }) => {
    updateReview({ id, body })
      .then(() => {
        let msg = 'Successfully ';
        msg += body.is_public ? 'published' : 'unpublished';
        msg += ' review';
        enqueueSnackbar(msg, { variant: 'success' });

        refresh();
      })
      .catch(() => {
        let msg = 'Failed to ';
        msg += body.is_public ? 'publish' : 'unpublish';
        msg += ' review';
        enqueueSnackbar(msg, { variant: 'error' });
      });
  };

  const getStatus = (review: Review) => {
    if (review.deleted_dt) return 'Deleted';
    if (!review.is_public) return 'Pending';

    return 'Published';
  };

  const sortStatus = (row: Review, value: Review) => {
    const rowStatus = getStatus(row);
    const valueStatus = getStatus(value);

    if (rowStatus < valueStatus) return -1;
    return rowStatus > valueStatus ? 1 : 0;
  };

  const columns = [
    {
      title: 'Status',
      customFilterAndSearch: (filter: any, row: any, _: any) => {
        return !!getStatus(row).toLowerCase().match(filter.toLowerCase());
      },
      customSort: sortStatus,
      render: (row: any) => {
        if (row.deleted_dt) return <Typography color="var(--failColor)">Deleted</Typography>;
        if (!row.is_public) return <Typography color="var(--appColorDarkGrey)">Pending</Typography>;

        return <Typography color="var(--appPrimaryColor)">Published</Typography>;
      },
    },
    {
      title: 'Rating',
      field: 'rating',
    },
    {
      title: 'Advocate Name',
      customFilterAndSearch: (filter: any, row: any, _: any) => {
        const name = `${row.reviewee.first_name} ${row.reviewee.last_name}`.toLowerCase();

        return !!name.match(filter.toLowerCase());
      },
      render: (row: any) => {
        return `${row.reviewee.first_name} ${row.reviewee.last_name}`;
      },
    },
    {
      title: 'Review',
      render: (row: any) => {
        return (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              style={{ color: 'var(--appPrimaryColor)', textDecoration: 'underline' }}
            >
              {row.content?.slice(0, 100)}...
            </AccordionSummary>
            <AccordionDetails>{row.content}</AccordionDetails>
          </Accordion>
        );
      },
    },
    {
      title: 'Reviewer',
      customFilterAndSearch: (filter: string, row: Review) => {
        const reviewer = row.reviewer as User;
        const name = `${reviewer.first_name} ${reviewer.last_name}`.toLowerCase();
        const phone = phoneNumberFormat(reviewer.phone);
        const email = reviewer.email.toLowerCase();

        return (
          !!name.match(filter.toLowerCase()) || !!phone.match(filter.toLowerCase()) || !!email.match(filter.toLowerCase())
        );
      },
      customSort: (reviewA: Review, reviewB: Review) => {
        const nameA = `${reviewA.reviewer?.first_name} ${reviewA.reviewer?.last_name}`;
        const nameB = `${reviewB.reviewer?.first_name} ${reviewB.reviewer?.last_name}`;

        return nameA.localeCompare(nameB);
      },
      render: (row: Review) => {
        const reviewer = row.reviewer as User;

        return (
          <Flex style={{ flexDirection: 'column', gap: '5px' }}>
            <div>
              {reviewer.first_name} {reviewer.last_name}
            </div>
            <div>{reviewer.email}</div>
            <div>{phoneNumberFormat(reviewer.phone)}</div>
          </Flex>
        );
      },
    },
    {
      title: '',
      field: '',
      render: (row: any) => {
        const onUpdatePublish = () => {
          const isPublic = !row.is_public;
          onUpdateReview({ id: row.id, body: { is_public: isPublic } });
        };

        const onDelete = () => {
          onUpdateReview({ id: row.id, body: { deleted_dt: new Date() } });
        };

        return (
          <>
            <Button variant="outlined" color="primary" onClick={onUpdatePublish}>
              {row.is_public ? 'Unpublish' : 'Publish'}
            </Button>
            {!row.is_public && !row.deleted_dt && (
              <Button variant="outlined" color="error" onClick={onDelete}>
                Delete
              </Button>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div>
      <h1>Pending Reviews</h1>

      <MaterialTable
        icons={tableIcons}
        columns={columns}
        data={reviews.pending}
        title=""
        options={{
          maxColumnSort: 'all_columns',
          search: true,
          pageSize: 10,
          pageSizeOptions: [10, 20],
        }}
      />

      <h1>All Reviews</h1>

      <MaterialTable
        icons={tableIcons}
        columns={columns}
        data={reviews.all}
        title=""
        options={{
          maxColumnSort: 'all_columns',
          search: true,
          pageSize: 10,
          pageSizeOptions: [10, 20],
        }}
      />
    </div>
  );
};
