import { Typography } from '@solace-health/ui';
import { PatientStatus } from '../../types/user';
import StatusPill from '../sharedStatusPill/StatusPill';
import { prettyStatusName } from '../../utils/user';

const statusPillOptions = (status: PatientStatus) => {
  switch (status) {
    case PatientStatus.Chw_Visit:
      return {
        borderColor: 'var(--border-color-dark-green, #285E50)',
        backgroundColor: 'var(--green-100, #F4F8F7)',
      };
    case PatientStatus.Clinician_Visit:
      return {
        borderColor: 'var(--border-color-dark-green, #285E50)',
        backgroundColor: 'var(--green-100, #F4F8F7)',
      };
    case PatientStatus.Schedule_Chw:
      return {
        borderColor: 'var(--border-color-danger, #DA4D54)',
        backgroundColor: 'var(--danger-bg-100, #F1E1E2)',
      };
    case PatientStatus.Schedule_Clinician:
      return {
        borderColor: 'var(--border-color-danger, #DA4D54)',
        backgroundColor: 'var(--danger-bg-100, #F1E1E2)',
      };
    case PatientStatus.Archived:
      return {
        borderColor: 'var(--border-color-dark, #555)',
        backgroundColor: 'var(--grey-100, #EEE)',
      };
    case PatientStatus.Check_Insurance:
      return {
        borderColor: 'var(--border-color-warning, #D7A13B)',
        backgroundColor: 'var(--gold-100, #FFF7E9)',
      };
    case PatientStatus.LovedOne:
      return {
        borderColor: 'var(--border-color-light, #D9D9D9)',
        backgroundColor: 'var(--grey-100, #EEE)',
      };
    default:
      return {
        borderColor: 'var(--border-color-light, #D9D9D9)',
        backgroundColor: 'var(--surface-primary, #FFF)',
      };
  }
};

export default function PatientStatusPill({ status }: { status: PatientStatus }) {
  const { borderColor, backgroundColor } = statusPillOptions(status);

  return (
    <StatusPill borderColor={borderColor} backgroundColor={backgroundColor}>
      <Typography.Body>{prettyStatusName[status]}</Typography.Body>
    </StatusPill>
  );
}
