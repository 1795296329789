import React from 'react';
import { SnackbarProvider } from 'notistack';
import { COLORS } from './enums/layout-enums';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import AuthenticationProvider from './contexts/AuthenticationProvider';
import { SolaceThemeProvider } from '@solace-health/ui';

const Main: React.FC = () => {
  document.body.style.backgroundColor = COLORS.WHITE_BACKGROUND;
  const notistackRef = React.createRef<any>();

  let theme = createTheme();
  theme = createTheme(theme, {
    palette: {
      primary: {
        main: COLORS.GREEN_BRAND_PRIMARY,
      },
    },
    colors: {
      primary: '#1D4339',
      secondaryGreen: '#285E50',
      lightGreen: '#BED3CC',
      fail: '#ff0000',
      gray: '#f3f3f3',
    },
    breakpoint: {
      small: '500px',
      medium: '800px',
      large: '1200px',
    },
    typography: {
      family: {
        body: 'var(--font-lato)',
        heading: "'Mollie Glaston', serif",
        subHeading: "'Cinzel', sans-serif",
        script: "'Dancing Script', sans-serif",
      },
      weight: {
        normal: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: 'var(--font-lato)',
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          item: {
            paddingTop: '8px !important',
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            '&:before': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            display: 'flex',
            justifyContent: 'flex-start',
            boxShadow: 'none',
            '& .Mui-expanded': {
              WebkitTransform: 'none',
              transform: 'none',
            },
            '&.Mui-expanded': {
              minHeight: 'unset',
            },
            padding: 0,
          },
          content: {
            flexGrow: 'unset',
            '&.Mui-expanded': {
              margin: '12px 0',
            },
          },
          expandIconWrapper: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-start',
            position: 'relative',
            overflow: 'hidden',

            WebkitTransform: 'none',
            transform: 'none',

            '*': {
              backgroundColor: '#fff',
            },

            svg: {
              transform: 'rotate(270deg)',
            },

            '&.Mui-expanded': {
              WebkitTransform: 'none',
              transform: 'none',
              svg: {
                transform: 'rotate(0deg)',
              },
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: '10px 0',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '.MuiInputBase-root': {
              borderRadius: '8px !important',
            },
            input: {
              borderRadius: '8px',
              fontFamily: 'var(--font-lato)',
              fontStyle: 'normal',
              fontSize: '16px',
              fontWeight: '400',
              color: '#101010',
              height: 'auto',
              [theme.breakpoints.down('md')]: {
                padding: '15px 16px',
                fontSize: '16px',
                lineHeight: '19px',
                height: 'auto',
              },
              '&.MuiInputBase-inputAdornedStart': {
                paddingLeft: 0,
              },
            },
            '.MuiInputBase-multiline': {
              borderRadius: '8px',
              fontFamily: 'var(--font-lato)',
              fontStyle: 'normal',
              fontWeight: '400',
              color: '#101010',
              [theme.breakpoints.down('md')]: {
                padding: '15px 16px',
                fontSize: '16px',
                lineHeight: '19px',
                height: 'auto',
              },
            },
            fieldset: {
              borderColor: 'var(--appColorDarkGrey)',
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
            fieldset: {
              borderColor: 'var(--appColorDarkGrey)',
            },
          },
          select: {
            [theme.breakpoints.down('md')]: {
              padding: '15px 16px',
              fontSize: '16px',
              lineHeight: '19px',
              minHeight: 'auto',
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            fieldset: {
              borderColor: 'var(--appColorDarkGrey)',
            },
            '.MuiOutlinedInput-root': {
              padding: 0,
              borderRadius: '8px',
            },
            '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
              padding: '6px 14px',
            },
          },
          input: {
            [theme.breakpoints.down('md')]: {
              padding: '9px 5px',
              fontSize: '16px',
              lineHeight: '19px',
            },

            '&.MuiInputBase-inputAdornedEnd': {
              padding: '15px 16px',
            },
          },
        },
      },
      MuiDatePicker: {
        styleOverrides: {
          root: {
            '&.Mui-focused': {
              backgroundColor: 'var(--appPrimaryColor) !important',
              color: '#EEEEEE',
              '&:focus': {
                backgroundColor: 'var(--appPrimaryColor) !important',
                color: '#EEEEEE',
              },
              '&:hover': {
                backgroundColor: 'var(--appPrimaryColor) !important',
                color: '#EEEEEE',
              },
            },
            '&.Mui-selected': {
              backgroundColor: 'var(--appPrimaryColor) !important',
              color: '#EEEEEE',
              '&:focus': {
                backgroundColor: 'var(--appPrimaryColor) !important',
                color: '#EEEEEE',
              },
              '&:hover': {
                backgroundColor: 'var(--appPrimaryColor) !important',
                color: '#EEEEEE',
              },
            },
          },
        },
      },
      '.MuiOutlinedInput-root': {
        padding: 0,
        borderRadius: '8px',
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            '& p': {
              fontFamily: 'var(--font-lato)',
              fontStyle: 'normal',
              fontWeight: 400,
              color: '#101010',
              fontSize: '16px',
            },
          },
        },
      },
      MuiBottomNavigationAction: {
        styleOverrides: {
          root: {
            color: '#9A9A9A',
            '&.Mui-selected': {
              color: '#101010',
            },
          },
          label: {
            fontFamily: 'var(--font-lato)',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '17px',
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            display: 'none',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontFamily: 'var(--font-lato)',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '13px',
            lineHeight: '19px',
            color: '#5A5A5A',
            background: '#EEEEEE',
            borderRadius: '38px',
            padding: '12px 24px',
            marginBottom: '20px',
            marginRight: '5px',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '8px',

            '&:hover': {
              backgroundColor: 'var(--appPrimaryColor) ',
              opacity: '0.5',
              color: '#EEEEEE',
            },
          },
        },
      },
    },
  });

  return (
    <SolaceThemeProvider>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider
            ref={notistackRef}
            maxSnack={3}
            autoHideDuration={5000}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            <BrowserRouter>
              <StyledEngineProvider injectFirst>
                <AuthenticationProvider>
                  <App />
                </AuthenticationProvider>
              </StyledEngineProvider>
            </BrowserRouter>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </SolaceThemeProvider>
  );
};

export default Main;
