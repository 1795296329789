import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthentication from './useAuth';
import Loading from '../../../components/general/Loading';
import { useSnackbar } from 'notistack';

const AuthCallbackPage = () => {
  const history = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { handleCallback } = useAuthentication();

  useEffect(() => {
    handleCallback(window.location.hash)
      .then(() => {
        const url = localStorage.getItem('redirectUrl');
        history(url || '/');
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: 'error' });
        history('/');
      });
  }, []);

  return <Loading loading />;
};
export default AuthCallbackPage;
