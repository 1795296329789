import { Button, Flex, Size, Toggle, Typography } from '@solace-health/ui';
import { ListingDetails } from '../ListingDetails';
import PhysicianStates from '../PhysicianStates';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Listing } from '../../../../Listing';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Traits } from '../Traits';
import { PausedTraits } from '../PausedTraits';
import * as S from './style';
import { useEffect, useState } from 'react';
import useGetUser from '../../../../../../../hooks/useGetUser';
import { useParams } from 'react-router-dom';
import useGetListing from '../../../../../../../hooks/useGetListing';
import Loading from '../../../../../../../components/general/Loading';
import { hasTrait } from '../../../../../../../utils/user';
import { TraitId } from '../../../../../../../types/user';
import { useSnackbar } from 'notistack';
import { SolaceAPI } from '../../../../../../../utils/api';
import { AxiosResponse } from 'axios';

export default function MainSection() {
  const { enqueueSnackbar } = useSnackbar();

  const [inOmniscientMode, setInOmniscientMode] = useState(false);
  const [checked, setChecked] = useState<string[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: { message: string | null } }>({
    isListingActive: { message: null },
    isLoginActive: { message: null },
  });

  const params = useParams<{ userId: string }>();

  const {
    data: user,
    loading: loadingUser,
    loginAs,
    refresh,
  } = useGetUser<{ identities: { provider: 'auth0' | 'google-oauth2' | 'facebook' }[] }>({
    id: params.userId,
    includes: ['identities', 'traits'],
  });

  const {
    data: { listing },
    loading: loadingListing,
  } = useGetListing({ id: user?.sharetribe_default_listing_uuid || '' });

  useEffect(() => {
    const initialChecked = [];
    if (!listing?.deactivated_dt) initialChecked.push('isListingActive');
    if (user?.active) initialChecked.push('isLoginActive');

    setChecked(initialChecked);
  }, [user, listing]);

  if (loadingUser || !user || (user.sharetribe_default_listing_uuid && loadingListing)) return <Loading loading={true} />;

  const isPhysician = hasTrait({ user, traitId: TraitId.CommunityHealthPhysician });

  const withSetErrors = (fn: Promise<AxiosResponse<any>>, value: string, message: string | null) => {
    fn.then(() => {
      setErrors({ ...errors, [value]: { message: null } });
    }).catch(() => {
      setErrors({ ...errors, [value]: { message } });
    });
  };

  const handleToggle = (value: string) => {
    if (!listing) return;

    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    const shouldActivate = currentIndex === -1;

    if (shouldActivate) {
      newChecked.push(value);

      if (value === 'isListingActive') {
        withSetErrors(
          SolaceAPI.put({
            path: `/api/listings/${listing.id}`,
            body: { deactivate: false },
          }),
          value,
          'Failed to activate listing',
        );
      } else if (value === 'isLoginActive') {
        withSetErrors(
          SolaceAPI.put({
            path: `/api/user/${user.id}`,
            body: { active: true },
          }),
          value,
          'Failed to activate user',
        );
      }
    } else {
      newChecked.splice(currentIndex, 1);

      if (value === 'isListingActive') {
        withSetErrors(
          SolaceAPI.put({
            path: `/api/listings/${listing.id}`,
            body: { deactivate: true },
          }),
          value,
          'Failed to deactivate listing',
        );
      } else if (value === 'isLoginActive') {
        withSetErrors(
          SolaceAPI.put({
            path: `/api/user/${user.id}`,
            body: { active: false },
          }),
          value,
          'Failed to deactivate user login',
        );
      }
    }

    setChecked(newChecked);
  };

  const onLoginAs = () => {
    loginAs({ id: user.sharetribe_uuid as string })
      .then((response) => {
        enqueueSnackbar(response.data.message, { variant: 'success' });
        setInOmniscientMode(true);
      })
      .catch(() => {
        enqueueSnackbar('Failed to login as user. Reach out to engineering for help.', { variant: 'error' });
      });
  };

  return (
    <Flex justify="space-between" gap={24}>
      <Flex vertical style={{ flexGrow: 1 }}>
        <ListingDetails listing={listing} />
        {isPhysician && <PhysicianStates physicianId={user.id} />}
        {user.sharetribe_default_listing_uuid && <Listing user={user} />}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography.Header>Omniscient Mode</Typography.Header>
          </AccordionSummary>
          <AccordionDetails>
            <Flex vertical gap={16}>
              <Typography.Body>This will give you the ability to log into the app as this user.</Typography.Body>

              <Button.Unstyled
                style={{
                  border: '1px solid #DA4D54',
                  backgroundColor: '#F1E1E2',
                  textDecoration: 'none',
                  maxWidth: 'fit-content',
                  padding: '0 20px',
                }}
                onClick={onLoginAs}
              >
                Login as {user.first_name} {user.last_name}
              </Button.Unstyled>
              {inOmniscientMode && (
                <Flex vertical gap={12}>
                  <Typography.Body style={{ color: '#DA4D54' }}>
                    You are now in Omniscient Mode for {user.first_name} {user.last_name}.
                  </Typography.Body>
                  <Button.Outline
                    style={{ borderColor: '#DA4D54', color: '#DA4D54', maxWidth: '200px' }}
                    onClick={() => window.open(`${process.env.REACT_APP_SOLACE_APP_URL}/login_as`, '_blank')}
                  >
                    Go to Dashboard
                  </Button.Outline>
                </Flex>
              )}
            </Flex>
          </AccordionDetails>
        </Accordion>
      </Flex>
      <S.Wrapper vertical gap="1.5rem">
        <S.CapabilitesContainer vertical gap={16}>
          <Typography.Header>Capabilities</Typography.Header>

          {listing?.id && (
            <Flex gap={12} align="center" justify="space-between">
              <Typography.Body bold size={Size.LG}>
                Listing
              </Typography.Body>
              <Toggle
                name="listingActive"
                handleEvent={() => handleToggle('isListingActive')}
                defaultState={checked.indexOf('isListingActive') !== -1}
                value="listingActive"
              />
            </Flex>
          )}
          <Flex gap={12} align="center" justify="space-between">
            <Typography.Body bold size={Size.LG}>
              Login
            </Typography.Body>
            <Toggle
              name="loginActive"
              handleEvent={() => handleToggle('isLoginActive')}
              defaultState={checked.indexOf('isLoginActive') !== -1}
              value="loginActive"
            />
          </Flex>
          {errors.isListingActive.message && (
            <Typography.Body color="#DA4D54">{errors.isListingActive.message}</Typography.Body>
          )}
          {errors.isLoginActive.message && <Typography.Body color="#DA4D54">{errors.isLoginActive.message}</Typography.Body>}
          <Traits user={user} refresh={refresh} />
        </S.CapabilitesContainer>
        {listing?.id && (
          <S.CapabilitesContainer vertical gap={16}>
            <Typography.Header>Accepting New...</Typography.Header>
            <PausedTraits listing={listing} />
          </S.CapabilitesContainer>
        )}
      </S.Wrapper>
    </Flex>
  );
}
