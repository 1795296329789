import useSWR from 'swr';
import fetcher from './fetcher';

type Data = {
  listing_id: string;
  listing: {
    advocate_name: string;
  };
  versions: {
    id: string;
    table_name: 'listing_scores';
    date: string;
    data: {
      listing_id: string;
      quality_score: number;
      efficacy_score: number;
      monetary_score: number;
      quality_score_metadata: Record<string, string>;
      efficacy_score_metadata: Record<string, string>;
      monetary_score_metadata: Record<string, string>;
    };
  }[];
};

const useGetListingScore = ({ listingId }: { listingId: string }): { data?: Data; loading: boolean; error: Error } => {
  const { data, error } = useSWR<Data>(`/v1/api/listing_scores/${listingId}`, fetcher);

  return {
    data,
    loading: data === undefined && !error,
    error,
  };
};

export default useGetListingScore;
