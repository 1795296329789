import { Table, styled } from '@solace-health/ui';

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  && > * {
    width: auto;
  }
`;

export const StyledTable = styled(Table)<{ clickable: boolean }>`
  .ant-table {
    border: transparent;
  }

  &&& th {
    background-color: #fff;
    border-bottom: 1px solid #000;
  }

  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: #347866;
  }

  .ant-table-column-sorters {
    justify-content: flex-start;
  }

  .ant-table-column-title {
    flex: 0;
    min-width: max-content;
    color: #555555;
  }

  .ant-table-row {
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};

    .ant-table-cell-row-hover {
      background: ${({ clickable }) => (clickable ? '#fafafa' : '#fff')};
    }
  }

  .rowSelected {
    background: #f4f8f7;
    .ant-table-cell:first-of-type {
      border-left: 3px solid #347866;
    }
  }
`;
