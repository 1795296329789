import { Flex, Form, Typography, useForm } from '@solace-health/ui';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import { useSnackbar } from 'notistack';
import {
  AdvocateOnboardingStepType,
  AdvocateOnboardingStepIdentifierKey,
  AdvocateOnboardingStep,
} from '../../../../../../hooks/users/useGetAdvocateOnboardingSteps';
import { SolaceAPI } from '../../../../../../utils/api';
import { SolaceApiError } from '../../../../../../utils/errors';
import { KeyedMutator } from 'swr';

type Props = {
  onboardingSteps: AdvocateOnboardingStep[];
  advocateId: string;
  refresh: KeyedMutator<AdvocateOnboardingStep[]>;
};

export const SetStartDate = ({ onboardingSteps, advocateId, refresh }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const formMethods = useForm();

  const onSubmit = async (values: { start_date: string }) => {
    try {
      await SolaceAPI.post({
        path: `/api/admin/advocates/${advocateId}/onboarding_steps`,
        body: {
          type: AdvocateOnboardingStepType.SetStartDate,
          identifier_key: AdvocateOnboardingStepIdentifierKey.StartDate,
          identifier: dayjs.utc(values.start_date).startOf('day').toISOString(),
        },
      });
    } catch (e) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`Failed to set start date - [${error.requestId}]`, { variant: 'error' });
      return;
    }

    enqueueSnackbar(`Successfully set start date`, { variant: 'success' });
    refresh();
  };

  const currentStartDate = (() => {
    const startDateSteps = onboardingSteps.filter((step) => step.type === AdvocateOnboardingStepType.SetStartDate);
    const startDateStep = orderBy(startDateSteps, ['completed_dt'], ['desc'])?.[0];
    if (!startDateStep) return null;

    return dayjs.utc(startDateStep.identifier).format('MM/DD/YYYY');
  })();

  return (
    <Form.Container onSubmit={onSubmit} formMethods={formMethods}>
      <Flex vertical gap="1rem" style={{ minWidth: '1200px', width: '100%' }}>
        <Typography.Header>Start Date</Typography.Header>
        <Flex gap="1rem" style={{ width: '100%' }}>
          <Form.DateTextMaskInput maxDate={dayjs().add({ year: 1 }).toDate()} name="start_date" />
          <Form.Submit>Set</Form.Submit>
        </Flex>
        {currentStartDate && (
          <Typography.Body>
            Current Start Date: <b>{currentStartDate}</b>
          </Typography.Body>
        )}
      </Flex>
    </Form.Container>
  );
};
