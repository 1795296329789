import { User } from '../../types/user';
import useGetPaginatedResource from '../useGetPaginatedResource';

export const useGetPatients = ({ include = [], filter = {} }: { include?: string[]; filter?: Record<string, unknown> }) => {
  const restPaginatedResourceHookReturn = useGetPaginatedResource<User>('/v1/api/admin/patients', {
    include,
    filter,
  });

  return {
    ...restPaginatedResourceHookReturn,
    refresh: restPaginatedResourceHookReturn.mutate,
  };
};
