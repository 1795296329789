import { Flex, Size, Typography } from '@solace-health/ui';
import { Role, User } from '../../../../../types/user';

type Props = {
  user: User & { roles: Role[] };
};

export const Header = ({ user }: Props) => {
  return (
    <Flex vertical gap="1rem">
      <Flex align="center" justify="space-between">
        <Flex align="baseline" gap="1rem">
          <Typography.Header size={Size.XL}>
            {user.first_name} {user.last_name}
          </Typography.Header>
        </Flex>
      </Flex>
    </Flex>
  );
};
