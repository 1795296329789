import useSWR from 'swr';
import fetcher from '../fetcher';

export type CallTranscript = {
  user: { id: string; first_name: string; last_name: string };
  text: string;
  event_dt: string;
}[];

export const useVideoCallTranscript = ({ id }: { id: string }) => {
  const { data, error, mutate } = useSWR<CallTranscript>(`/v1/api/video_calls/${id}/transcript`, fetcher);

  return {
    data: data || [],
    loading: data === undefined,
    error,
    refresh: mutate,
  };
};
