/**
 * Warning: This code is ugly and might have bugs. It was a quick prototype to be able to view care plans in JSON format
 * because the data is encrypted in the database.
 */
import { Divider, Flex } from '@solace-health/ui';
import { Form, FormTemplate } from '../../../../types/forms';
import { User } from '../../../../types/user';
import { parseContent } from '../utils/parseFormContent';
import { useGetForm } from '../../../../hooks/forms/useGetForm';

type FormJson = { id: string; label: string; details: string; content: string | null };

const CarePlan = ({ id }: { id: string }) => {
  const { data: form } = useGetForm<{ form_template: FormTemplate; sender: User; sent_to: User }>({
    id: id,
    query: { include: ['form_template', 'sender', 'sent_to'] },
  });

  if (!form) return null;

  const jsonData: { form_id: string; form: ({ advocate_id: string; patient_id: string } | FormJson)[] } = {
    form_id: id,
    form: [
      {
        advocate_id: form.sender.id,
        patient_id: form.sent_to.id,
      },
    ],
  };
  form.form_template.components.forEach((component) => {
    component.form_element_responses.forEach((response) => {
      jsonData.form.push({
        id: response.id,
        label: component.form_element_options.options.label,
        details: component.form_element_options.options.details,
        content: parseContent({ formElementOptions: component.form_element_options, content: response.content }),
      });
    });
  });

  return <pre>{JSON.stringify(jsonData, null, 2)}</pre>;
};

export const CarePlans = ({
  forms,
}: {
  forms: (Form & {
    form_template: FormTemplate;
    sender: User;
    sent_to: User;
  })[];
}) => {
  const carePlanForms = forms.filter((form) => form.form_template.type === 'care_plan_form' && !!form.completed_dt);

  return (
    <Flex vertical>
      {carePlanForms.map((carePlanForm) => {
        return (
          <Flex vertical>
            <CarePlan key={carePlanForm.id} id={carePlanForm.id} />
            <Divider />
          </Flex>
        );
      })}
    </Flex>
  );
};
