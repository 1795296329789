import useGetPaginatedResource from '../useGetPaginatedResource';
import { BookingHelpRequest, BookingHelpRequestFilter } from './useGetBookingHelpRequests';

export const useGetPaginatedBookingHelpRequests = ({
  filter = {},
  search = '',
}: {
  filter?: BookingHelpRequestFilter;
  search?: string;
}) => {
  const restPaginatedRourceHookReturn = useGetPaginatedResource<BookingHelpRequest>(`/v1/api/booking_help_requests`, {
    filter,
    include: ['last_contact_by', 'resolved_by', 'prospect'],
    search,
  });

  return {
    ...restPaginatedRourceHookReturn,
    refresh: restPaginatedRourceHookReturn.mutate,
    loading: restPaginatedRourceHookReturn.isLoading,
  };
};
