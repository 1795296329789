import { Pill } from '@solace-health/ui';

type StatusPillProps = {
  children: React.ReactNode;
  backgroundColor: string;
  borderColor?: string;
  strikeThrough?: boolean;
};

export default function StatusPill({
  children,
  backgroundColor,
  borderColor = undefined,
  strikeThrough = false,
}: StatusPillProps) {
  return (
    <div>
      <Pill
        bordered={!!borderColor}
        borderColor={borderColor}
        color={backgroundColor}
        style={{ borderRadius: '.5rem', textDecoration: strikeThrough ? 'line-through' : 'none' }}
      >
        {children}
      </Pill>
    </div>
  );
}
