import styled from '@emotion/styled';
import { Flex, Form } from '@solace-health/ui';

export const FormContainer = styled(Form.Container)`
  max-width: 550px;
  width: 100%;
  margin-top: 40px;
`;

export const CardContent = styled(Flex)`
  padding: 50px;
  border: 1px solid rgb(190, 211, 204);
  box-shadow: rgb(212, 226, 221) 2px 2px 20px;
  border-radius: 20px;
`;
