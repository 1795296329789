import { User } from './user';
import { Encounter } from './encounter';
import { PlaceOfServiceCode } from './referrals';

export enum PhysicianVisitType {
  Initial = 'initial',
  Existing = 'existing',
}

type SdohData = {
  result: 'positive' | 'negative';
  priority: 'urgent' | 'non-urgent';
};

type SoapNote = {
  subjective?: string;
  objective?: string;
  assessment?: string;
  plan?: string;
};

export enum MedicareCode {
  // Medicare
  Chi = 'CHI',
  Pin = 'PIN',
}

export enum PatientJoinedVia {
  Phone = 'phone',
  Video = 'video',
}

export type CptConfigId = MedicareCode;

export enum EncounterStatus {
  Submitted = 'Submitted',
  Pending = 'Pending Review',
}

export type PhysicianVisit = {
  id: string;
  type: PhysicianVisitType;
  reason: string | null;
  time_spent: number;
  patient_joined_via: PatientJoinedVia | null;
  sdoh_screening_performed: boolean;
  sdoh_data: SdohData;
  soap_note: SoapNote;
  /** @deprecated Use soap_note */
  notes: string | null;
  submitted_dt: Date | null;
  encounter_dt: Date | null;
  cpt_config_id: string | null;
  icds: { code: string; description: string; is_primary: boolean }[];
  encounter_id: string;
  patient_id: string;
  physician_id: string;
  created_at: Date;
  updated_at: Date;
  encounter: Encounter;
  patient?: User;
  physician?: User;
  place_of_service: PlaceOfServiceCode;
};
