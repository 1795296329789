import qs from 'qs';
import useSWR from 'swr';
import { Payor } from '../types/payor';
import fetcher from './fetcher';

type Props = {
  include?: ('insurance_company' | 'physicians')[];
};

const usePayors = (jsonApiQuery: Props = {}) => {
  const urlParams = qs.stringify(jsonApiQuery);
  const { data, error, mutate, isLoading } = useSWR<Payor[]>(`/v1/api/payors?${urlParams}`, fetcher);

  return {
    payors: data || [],
    error,
    isLoading,
    refresh: mutate,
  };
};

export default usePayors;
