import { capitalize, startCase, toLower } from 'lodash';
import { USA_STATES } from '../constants/general-constants';

const isUpperCase = (str: any) => /^[A-Z]*$/.test(str);

export const titleCase = (text: string) => startCase(toLower(text));

export const snakeToSentenceCase = (text: string) =>
  text
    .split('_')
    .map((word) => capitalize(word))
    .join(' ');

export const enumValues = (enumVals: any) => {
  const returnArr = [] as any[];
  for (const item in enumVals) {
    returnArr.push(item as any);
  }
  return returnArr;
};

export const enumValuesToOrList = (enumVals: any) => {
  let returnString = '';
  let firstVal = true;
  for (const item in enumVals) {
    if (!firstVal) {
      returnString += ' OR ';
    }
    firstVal = false;
    returnString += item;
  }
  return returnString;
};

export const capFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const safeSum = (a: number, b: number) => {
  let aa = Number(a);
  let bb = Number(b);
  if (isNaN(a)) {
    aa = 0;
  }
  if (isNaN(b)) {
    bb = 0;
  }
  return aa + bb;
};

export const hasNumberLetter6Length = (str: any) => {
  let hasNumber = false;
  let hasUpper = false;

  for (let i = 0; i < str.length; i++) {
    const char = str.charAt(i);
    const upperCase = isUpperCase(char);
    const isNumber = !isNaN(char);
    if (upperCase) {
      hasUpper = true;
    }
    if (isNumber) {
      hasNumber = true;
    }
  }
  if (str.length >= 6 && hasUpper && hasNumber) {
    return true;
  }
  return false;
};

export const makeSearchObject = (obj: any, include_fields = [] as any[]) => {
  const returnVals = [] as any[];
  for (const [key, value] of Object.entries(obj)) {
    switch (typeof value) {
      case 'string':
      case 'number':
        if (include_fields.length === 0 || include_fields.includes(key)) {
          returnVals.push(value);
        }
        break;
      default:
        break;
    }
  }
  return returnVals.join(' ');
};

export const roundNumberFixed2 = (num: any): number => {
  try {
    return Math.round((Number(num) + Number.EPSILON) * 100) / 100;
  } catch {
    return 0;
  }
};

export const smoothScrollTo = (elementId: string, offset = 100, delay = 150) => {
  if (!document) return;
  const element = document.getElementById(elementId);
  if (!element) return;
  setTimeout(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
    });
  }, delay);
};

export const getStateNameFromAbbreviation = (abbreviation: string) => {
  const state = USA_STATES.find((state) => state.abbreviation === abbreviation);
  return state ? state.name : '';
};
