import { Flex, Icons, Size, Typography } from '@solace-health/ui';
import { GetUserResponse } from '../../../../../hooks/useGetUser';
import dayjs from 'dayjs';
import useReferrals from '../../../../../hooks/referrals/useReferrals';
import { useSnackbar } from 'notistack';
import * as S from './style';
import ActiveTag from './ActiveTag/ActiveTag';
import { LastContactButton } from '../../../../../shared/components/LastContactButton';
import { useNavigate } from 'react-router-dom';
import { User } from '../../../../../types/user';

type PatientHeaderProps = {
  user: GetUserResponse;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLovedOne?: boolean;
  refresh: () => void;
};

export default function PatientHeader({ user, setDrawerOpen, isLovedOne = false, refresh }: PatientHeaderProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { updateLastContact } = useReferrals();
  const navigate = useNavigate();

  const handleUpdateLastContact = async (lastContact: Date) => {
    const referralId = user?.referral?.id;
    try {
      if (!referralId) throw new Error('No Referral ID');
      if (dayjs(lastContact) > dayjs()) throw new Error('Cannot set last contact in the future');

      const res = await updateLastContact({ id: referralId, lastContact });

      if (res.status !== 200) throw new Error(res.statusText);

      enqueueSnackbar('Updated last contact', { variant: 'success' });
      refresh();
    } catch (error) {
      enqueueSnackbar((error as Error).message, { variant: 'error' });
    }
  };

  const lastContactDT = user?.referral?.last_contact_dt;
  let lovedOnesPatient: User | undefined = undefined;
  if (user.sharee_care_relationships && user.sharee_care_relationships.length > 0) {
    lovedOnesPatient = user?.sharee_care_relationships?.[0].client;
  }

  return (
    <Flex align="center" justify="space-between">
      <Flex align="baseline" gap="1rem">
        <Typography.Header size={Size.XL}>
          {user.first_name} {user.last_name}
        </Typography.Header>
        <ActiveTag active={user.active} />
        <Typography.Body color="#555555">ID: {user.internal_id}</Typography.Body>
      </Flex>

      <Flex align="center" gap="1rem">
        <LastContactButton onClick={() => handleUpdateLastContact(new Date())}>
          <Typography.Body>
            Last Contact: {lastContactDT ? new Date(lastContactDT).toLocaleDateString() : 'N/A'}
          </Typography.Body>
        </LastContactButton>
        {!isLovedOne && (
          <S.StyledButton onClick={() => setDrawerOpen(true)}>
            <Icons.Call />
            <Typography.Header size={Size.SM}>Schedule Meeting</Typography.Header>
          </S.StyledButton>
        )}
        {isLovedOne && (
          <S.StyledButton onClick={() => navigate(`/patients/${lovedOnesPatient?.id}`)}>
            <Icons.ExternalLink />
            <Typography.Header size={Size.SM}>View Patient Profile</Typography.Header>
          </S.StyledButton>
        )}
      </Flex>
    </Flex>
  );
}
