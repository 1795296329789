import useSWR from 'swr';
import fetcher from './../fetcher';
import { HealthRecord } from '../../types/healthRecord';
import { PatientLink } from '../../types/commonwell';
import { SolaceAPI } from '../../utils/api';

const usePatientLinks = ({ healthRecordId }: { healthRecordId: HealthRecord['id'] }) => {
  const { data, error, mutate, isLoading } = useSWR<PatientLink[]>(
    healthRecordId && `/v1/api/commonwell/patient_links/${healthRecordId}`,
    fetcher,
  );

  const deletePatientLink = async (linkId: string) => {
    return await SolaceAPI.delete({ path: `/api/commonwell/patient_links/${healthRecordId}/${linkId}` });
  };

  const resetPatientLinks = async () => {
    return await SolaceAPI.put({ path: `/api/commonwell/patient_links/${healthRecordId}` });
  };

  const createPatientLink = async (linkId: string) => {
    return await SolaceAPI.post({ path: `/api/commonwell/patient_links/${healthRecordId}/links/${linkId}` });
  };

  return {
    patientLinks: data || [],
    error,
    refresh: mutate,
    isLoading,
    deletePatientLink,
    resetPatientLinks,
    createPatientLink,
  };
};

export default usePatientLinks;
