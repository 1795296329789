import useSWR from 'swr';
import { HealthInsurance } from '../types/healthInsurance';
import fetcher from './fetcher';

export default function useGetUserHealthInsurances({ userId }: { userId?: string }) {
  const {
    data,
    error,
    mutate: refresh,
    isLoading,
  } = useSWR<HealthInsurance[]>(userId ? `/v1/api/admin/users/${userId}/insurances` : null, fetcher);

  return {
    loading: isLoading,
    data,
    error,
    refresh,
  };
}
