import useSWR from 'swr';
import qs from 'qs';
import fetcher from '../fetcher';
import { User } from '../../types/user';

export enum AdvocateOnboardingStepType {
  AddedToGusto = 'added_to_gusto',
  AlternateEmailSet = 'alternate_email_set',
  AssignedComplianceTraining = 'assigned_compliance_training',
  BackgroundCheckCleared = 'background_check_cleared',
  BackgroundCheckPending = 'background_check_pending',
  BackgroundCheckSent = 'background_check_sent',
  DocusignCompleted = 'docusign_completed',
  DocusignSent = 'docusign_sent',
  GoogleAdminUserCreated = 'google_admin_user_created',
  OfferPacketSent = 'offer_packet_sent',
  SchedulingLinkSent = 'scheduling_link_sent',
  SetStartDate = 'set_start_date',
}

export enum AdvocateOnboardingStepCompletedVia {
  Automated = 'automated',
  Manual = 'manual',
}

export enum AdvocateOnboardingStepIdentifierKey {
  AdvocateId = 'advocateId',
  AlternateEmail = 'alternateEmail',
  CheckrCandidateId = 'candidateId',
  DocusignEnvelopeId = 'envelopeId',
  StartDate = 'startDate',
}

export type AdvocateOnboardingStep = {
  type: AdvocateOnboardingStepType;
  identifier_key: AdvocateOnboardingStepIdentifierKey;
  identifier: string;
  completed_via: AdvocateOnboardingStepCompletedVia;
  completed_dt: string;
  completed_by: User;
};

export const useGetAdvocateOnboardingSteps = ({ advocateId }: { advocateId: string }) => {
  const query = qs.stringify({ include: ['completed_by'] });
  const { data, error, isLoading, mutate } = useSWR<AdvocateOnboardingStep[]>(
    advocateId ? `/v1/api/admin/advocates/${advocateId}/onboarding_steps?${query}` : null,
    fetcher,
  );

  return {
    data: data || [],
    loading: isLoading,
    error,
    refresh: mutate,
  };
};
