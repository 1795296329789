import useSWR from 'swr';
import fetcher from './fetcher';
import { SolaceAPI } from '../utils/api';

export enum AlgorithmVariableSlug {
  ADVOCATE_BONUSES = 'advocate_bonuses',
  AVAILABILITY_TIERS = 'availability_tiers',
  CHW_BONUSES = 'chw_bonuses',
  DISTANCE_TIERS = 'distance_tiers',
  PHYSICIAN_BONUSES = 'physician_bonuses',
}

type AlgorithmVariableValue =
  | number
  | string
  | {
      within_n_hours: number;
      score: number;
    }[]
  | {
      less_than_miles: number;
      score: number;
    }[];

export type AlgorithmVariable = {
  id: string;
  slug: AlgorithmVariableSlug;
  name: string;
  value: AlgorithmVariableValue;
  active_since_dt: string;
};

const useAlgorithmVariables = () => {
  const { data: AlgorithmVariables, error, mutate } = useSWR<AlgorithmVariable[]>('/v1/api/algorithm_variables', fetcher);

  const saveAlgorithmVariable = ({ algorithmVariable }: { algorithmVariable: AlgorithmVariable }) => {
    return SolaceAPI.put({
      path: `/api/algorithm_variables/${algorithmVariable.slug}`,
      body: { name: algorithmVariable.name, value: algorithmVariable.value },
    });
  };

  return {
    loading: AlgorithmVariables === undefined,
    data: AlgorithmVariables || [],
    error,
    refresh: mutate,
    saveAlgorithmVariable,
  };
};

export default useAlgorithmVariables;
