import { Size, Typography } from '@solace-health/ui';
import {
  AdvocateOnboardingStep,
  AdvocateOnboardingStepType,
} from '../../../../../../hooks/users/useGetAdvocateOnboardingSteps';
import dayjs from 'dayjs';
import { OnboardingStatusPill } from './OnboardingStatusPill';
import { KeyedMutator } from 'swr';
import { SolaceAPI } from '../../../../../../utils/api';
import { SolaceApiError } from '../../../../../../utils/errors';
import { useSnackbar } from 'notistack';
import * as S from './style';
import { OnboardingStepStatus } from './types';

type Props = {
  onboardingSteps: AdvocateOnboardingStep[];
  advocateId: string;
  refresh: KeyedMutator<AdvocateOnboardingStep[]>;
};
export const OfferPacketStep = ({ onboardingSteps, advocateId, refresh }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  let status: OnboardingStepStatus | null = null;
  let date = null;

  const offerPacketSentStep = onboardingSteps.find((step) => step.type === AdvocateOnboardingStepType.OfferPacketSent);
  if (offerPacketSentStep) {
    status = OnboardingStepStatus.Sent;
    date = offerPacketSentStep.completed_dt;
  }

  if (!status) status = OnboardingStepStatus.NotSent;

  const onMarkComplete = async () => {
    try {
      await SolaceAPI.post({
        path: `/api/admin/advocates/${advocateId}/onboarding_steps`,
        body: {
          type: AdvocateOnboardingStepType.OfferPacketSent,
        },
      });
    } catch (e) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`Failed to mark complete - [${error.requestId}]`, { variant: 'error' });
      return;
    }

    enqueueSnackbar(`Marked complete`, { variant: 'success' });
    refresh();
  };

  return (
    <tr>
      <td>
        <Typography.Header>Offer Packet</Typography.Header>
      </td>
      <td>
        <OnboardingStatusPill status={status} />
      </td>
      <td>
        {date ? (
          <Typography.Body size={Size.SM}>
            <i>{dayjs(date).format('MM/DD/YYYY [at] h:mm A')}</i>
          </Typography.Body>
        ) : null}
      </td>
      <td>{offerPacketSentStep?.completed_by?.fullName}</td>
      <td>{status !== 'sent' && <S.ActionButton onClick={onMarkComplete}>Mark Sent</S.ActionButton>}</td>
    </tr>
  );
};
