import useSWR from 'swr';
import fetcher from './fetcher';
import { Listing } from '../types/listing';
import { User } from '../types/user';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import qs from 'qs';
dayjs.extend(objectSupport);

export type CommunityHealthListing = Listing & { bookings_count: number } & {
  user: User & { address: { city: string; state: string } | null };
};

export type CommunityHealthAppointments = {
  [date: string]: {
    [time: string]: {
      start: string;
      end: string;
      listings: CommunityHealthListing[];
    };
  };
};

export const useCHWAvailability = ({
  month,
  year,
  user,
  listingId,
}: {
  month: number;
  year: number;
  user: User;
  listingId?: Listing['id'];
}) => {
  const monthOffset = month - 1;
  const startDate = dayjs({ year, month: monthOffset }).toISOString();
  const endDate = dayjs({ year, month }).endOf('month').toISOString();
  const query = qs.stringify({ start_dt: startDate, end_dt: endDate, time_zone: user?.time_zone, listing_id: listingId });

  const { data, error, mutate } = useSWR<CommunityHealthAppointments>(
    user?.id ? `/v1/api/timeslots/community_health?${query}` : null,
    fetcher,
  );

  return {
    loading: data === undefined,
    data: data || ({} as CommunityHealthAppointments),
    error,
    refresh: mutate,
  };
};
