import { Button, Divider, Flex, Modal, Size, Typography } from '@solace-health/ui';
import { capitalize } from 'lodash';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../../../hooks/useQuery';
import useEligibilityChecks from '../../../../hooks/referrals/useEligibilityChecks';
import { Referral, ReferralEligibilityCheck, ReferralEligibilityCheckStatus } from '../../../../types/referrals';
import { AdminTable } from '../../../../components/shared/Table';
import StatusPill from '../../../../components/sharedStatusPill/StatusPill';
import { QueryParamInputs } from '../../../../components/shared/Table/antdOnChangeToQueryParams';

const ELIGIBILITY_CHECK_ID_COLUMN = 'id';

const EligibilityCheckPage = () => {
  const history = useNavigate();
  const query = useQuery();
  const id = query.get(ELIGIBILITY_CHECK_ID_COLUMN);

  const { data, pagination, queryParamInputs, setQueryParamInputs } = useEligibilityChecks();

  const [eligibilityCheckResponse, setEligibilityCheckResponse] = useState<(typeof data)[0]>();
  const [eligibilityCheckRequest, setEligibilityCheckRequest] = useState<(typeof data)[0]>();

  useEffect(() => {
    if (!id) return;

    setQueryParamInputs({ filter: { id }, page: 1 } as QueryParamInputs);
  }, [id, data]);

  const name = (item: any) => {
    if (item.referral?.user) {
      return `${item.referral?.user?.first_name} ${item.referral?.user?.last_name}`;
    }

    if (item.referral?.prospect) {
      return `${item.referral?.prospect?.first_name} ${item.referral?.prospect?.last_name}`;
    }

    return 'N/A';
  };

  const formattedData = data.map((item) => ({
    name: name(item),
    ...item,
  }));

  const onViewAll = () => {
    setQueryParamInputs({} as QueryParamInputs);
    query.delete(ELIGIBILITY_CHECK_ID_COLUMN);
  };

  const pillColorOptions = {
    [ReferralEligibilityCheckStatus.Eligible]: {
      backgroundColor: 'var(--green-100, #F4F8F7)',
      borderColor: 'var(--border-color-dark-green, #285E50)',
    },
    [ReferralEligibilityCheckStatus.Unchecked]: {
      backgroundColor: 'var(--grey-100, #EEE)',
      borderColor: 'var(--border-color-dark, #555)',
    },
    [ReferralEligibilityCheckStatus.Failed]: {
      backgroundColor: 'var(--danger-bg-100, #F1E1E2)',
      borderColor: 'var(--border-color-danger, #DA4D54)',
    },
    [ReferralEligibilityCheckStatus.Ineligible]: {
      backgroundColor: 'var(--danger-bg-100, #F1E1E2)',
      borderColor: 'var(--border-color-danger, #DA4D54)',
    },
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'referral.first_name',
      sorter: true,
      render: (_: string, row: ReferralEligibilityCheck & { referral?: Referral; name: string }) => {
        if (row.referral?.user_id) {
          return <Button.Link onClick={() => history(`/patients/${row.referral?.user_id}`)}>{row.name}</Button.Link>;
        }

        return row.name;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: ReferralEligibilityCheckStatus) => (
        <StatusPill
          backgroundColor={pillColorOptions[status]?.backgroundColor || 'var(--grey-100, #EEE)'}
          borderColor={pillColorOptions[status]?.borderColor || 'var(--border-color-dark, #555)'}
        >
          {capitalize(status)}
        </StatusPill>
      ),
      filters: [
        { text: capitalize(ReferralEligibilityCheckStatus.Eligible), value: ReferralEligibilityCheckStatus.Eligible },
        { text: capitalize(ReferralEligibilityCheckStatus.Unchecked), value: ReferralEligibilityCheckStatus.Unchecked },
        { text: capitalize(ReferralEligibilityCheckStatus.Failed), value: ReferralEligibilityCheckStatus.Failed },
        { text: capitalize(ReferralEligibilityCheckStatus.Ineligible), value: ReferralEligibilityCheckStatus.Ineligible },
      ],
    },
    {
      title: 'Submitted',
      dataIndex: 'submitted_dt',
      sorter: true,
      render: (date: Date | null) => (date ? dayjs(date).format('M/DD/YYYY h:mmA z') || '' : '-'),
      defaultSortOrder: 'ascend',
    },
    {
      title: '',
      render: (
        row: ReferralEligibilityCheck & { referral: Referral & { user: { first_name: string; last_name: string } } },
      ) => <Button.Link onClick={() => setEligibilityCheckRequest(row)}>View Request</Button.Link>,
    },
    {
      title: '',
      render: (row: ReferralEligibilityCheck & { referral: Referral }) => (
        <Button.Link onClick={() => setEligibilityCheckResponse(row)}>View Response</Button.Link>
      ),
    },
  ];

  const fullName = (() => {
    if (eligibilityCheckRequest?.referral.user) {
      return `${eligibilityCheckRequest?.referral?.user?.first_name} ${eligibilityCheckRequest?.referral?.user?.last_name}.`;
    }

    return `${eligibilityCheckRequest?.referral?.first_name} ${eligibilityCheckRequest?.referral?.last_name}.`;
  })();

  const requestPayload = (() => {
    if (eligibilityCheckRequest?.request_payload) return eligibilityCheckRequest?.request_payload;

    return eligibilityCheckRequest?.referral.eligibility_check_data;
  })();

  return (
    <>
      <AdminTable
        showPagination
        pagination={pagination}
        onChange={setQueryParamInputs}
        onTextFilter={(search) => setQueryParamInputs({ ...queryParamInputs, search })}
        columns={columns}
        data={formattedData ?? data}
        actionItem={
          <Flex vertical gap="1rem">
            <Typography.Header size={Size.LG}>Eligibility Checks</Typography.Header>
            {id && <Button.Link onClick={onViewAll}>View All</Button.Link>}
          </Flex>
        }
      />

      {/* View Response Modal */}
      <Modal isOpen={!!eligibilityCheckResponse} onClose={() => setEligibilityCheckResponse(undefined)}>
        <Flex vertical gap="0.5rem">
          <Typography.Header
            size={Size.LG}
          >{`${eligibilityCheckResponse?.referral?.user?.first_name} ${eligibilityCheckResponse?.referral?.user?.last_name}.`}</Typography.Header>

          <Typography.Body>
            <strong>Submitted:</strong> {dayjs(eligibilityCheckResponse?.submitted_dt).format('M/DD/YYYY h:mmA z') || ''}
          </Typography.Body>
          {eligibilityCheckResponse?.status_reason && (
            <Typography.Body>
              <strong>Reason:</strong> {eligibilityCheckResponse?.status_reason}
            </Typography.Body>
          )}
        </Flex>

        <Divider />
        <pre>{JSON.stringify(eligibilityCheckResponse?.result_payload, null, 2)}</pre>
      </Modal>
      {/* View Request Modal */}
      <Modal isOpen={!!eligibilityCheckRequest} onClose={() => setEligibilityCheckRequest(undefined)}>
        <Typography.Header size={Size.LG}>{fullName}</Typography.Header>
        <Divider />
        <pre>{JSON.stringify(requestPayload, null, 2)}</pre>
      </Modal>
    </>
  );
};

export default EligibilityCheckPage;
