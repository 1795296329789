import { Divider, Flex, Form, FormatType, Typography, useForm } from '@solace-health/ui';
import { GetUserResponse } from '../../../../../hooks/useGetUser';
import * as S from './style';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { phoneNumberFormat } from '../../../../../utils/general';
import { UserSchema, UserSchemaType } from './schema';
import dayjs from 'dayjs';
import { Referral } from '../../../../../types/referrals';
import usePatient from '../../../../../hooks/usePatient';

export type PatientInfoSectionProps = {
  patient: GetUserResponse<{ referral: Referral }>;
  isLovedOne?: boolean;
  refresh: () => void;
};

export default function PatientInfoSection({ patient, isLovedOne = false, refresh }: PatientInfoSectionProps) {
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const { updatePatient } = usePatient();

  const formMethods = useForm<UserSchemaType>({
    resolver: zodResolver(UserSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      patient_sex: '',
      patient_dob: '',
      address: {},
    },
    reValidateMode: 'onChange',
  });

  const {
    formState: { dirtyFields, errors },
    reset,
  } = formMethods;

  useEffect(() => {
    reset({
      first_name: patient.first_name,
      last_name: patient.last_name,
      email: patient.email,
      phone: phoneNumberFormat(patient.phone),
      patient_sex: patient.referral?.sex,
      patient_dob: patient.dob ? dayjs.utc(patient?.dob).format('MM/DD/YYYY') : '',
      ...(patient?.address ? { address: patient.address } : {}),
    });
  }, [patient, reset]);

  const handleSaveChanges = async (formData: UserSchemaType) => {
    setIsSavingChanges(true);

    const { first_name, last_name, email, phone, patient_sex, patient_dob, address } = formData;

    const addressHasChanged = (() => {
      if (!address) return false;

      return (
        patient.address?.street !== address.street ||
        patient.address?.city !== address.city ||
        patient.address?.state !== address.state ||
        patient.address?.zip !== address.zip ||
        patient.address?.unit !== address.unit
      );
    })();

    if (
      patient.first_name !== first_name ||
      patient.last_name !== last_name ||
      patient.email !== email ||
      patient.phone !== phone ||
      addressHasChanged
    ) {
      await updatePatient(patient.id, { first_name, last_name, email, phone, address, dob: patient_dob, sex: patient_sex });

      refresh();
    }

    const referral = patient?.referral;

    if (!referral) return setIsSavingChanges(false);

    refresh();

    reset({ ...formData });

    setIsSavingChanges(false);
  };

  const valuesHaveChanges = Object.keys(dirtyFields).length > 0;

  return (
    <Form.Container formMethods={formMethods} onSubmit={handleSaveChanges}>
      <S.Container>
        <Typography.Header>Patient Information</Typography.Header>
        <S.FormWrapper>
          <Flex justify="space-between" gap="1rem">
            <Form.TextInput name="first_name" label="First Name" formOptions={{ required: true }} />
            <Form.TextInput name="last_name" label="Last Name" formOptions={{ required: true }} />
          </Flex>
          {!isLovedOne && (
            <>
              <Form.TextInput name="patient_dob" label="Date of Birth" formOptions={{ required: true }} />
              <Form.RadioGroup
                name="patient_sex"
                label="Sex"
                options={[
                  { label: 'Male', value: 'male' },
                  { label: 'Female', value: 'female' },
                ]}
                formOptions={{
                  value: patient.referral?.sex,
                }}
              />
              <Flex vertical gap={8}>
                <Form.LocationSelect
                  api_key={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
                  label="Home Address"
                  placeholder="Address Line 1"
                  locationType="full"
                  streetFieldName="address.street"
                  cityFieldName="address.city"
                  stateFieldName="address.state"
                  zipFieldName="address.zip"
                  formOptions={{ required: true }}
                  hasError={!!errors.address}
                />
                <Flex gap={8}>
                  <S.Address name="address.city" placeholder="City" readOnly />
                  <S.Address name="address.state" placeholder="State" readOnly />
                  <S.Address name="address.zip" placeholder="Zip" readOnly />
                </Flex>
              </Flex>
              <Divider />
            </>
          )}
          <Typography.Header>Contact Information</Typography.Header>
          <Form.TextInput name="email" label="Email" formOptions={{ required: true }} />
          <Form.TextInput name="phone" label="Phone" format={FormatType.Phone} formOptions={{ required: true }} />
        </S.FormWrapper>
      </S.Container>

      <S.StickyButtonContainer show={valuesHaveChanges}>
        <Form.Submit label="Save Changes" isSubmitting={isSavingChanges} />
      </S.StickyButtonContainer>
    </Form.Container>
  );
}
