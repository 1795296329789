import { TraitId, User } from '../../types/user';
import useGetPaginatedResource from '../useGetPaginatedResource';

export const useGetPaginatedAdvocates = ({ traitIds, includes = [] }: { traitIds: TraitId[]; includes?: string[] }) => {
  const restPaginatedResourceHookReturn = useGetPaginatedResource<User>('/v1/api/advocates', {
    filter: { traits: traitIds.map((traitId) => ({ id: traitId })) },
    include: includes,
  });

  return {
    ...restPaginatedResourceHookReturn,
    refresh: restPaginatedResourceHookReturn.mutate,
  };
};
