import useSWR, { SWRResponse } from 'swr';
import { User } from '../types/user';
import fetcher from './fetcher';
import { TimeSpan } from '../types/timeSpan';
import { AdvocatePatient } from '../types/advocatePatient';
import { Payout } from '../types/payout';

export type DeletedTimeSpan = TimeSpan & {
  submitted_dt: string;
  deleted_dt: string;
  deleted_by: { first_name: string; last_name: string };
  advocate_patient: AdvocatePatient;
  payout: Payout;
};

type GetTimeSpansProps = {
  advocateId?: User['id'];
};

export default function useGetDeletedTimespans({
  advocateId = undefined,
}: GetTimeSpansProps): SWRResponse<DeletedTimeSpan[]> {
  const { data, ...rest } = useSWR(`/v1/api/admin/advocates/${advocateId}/deleted_time_spans`, fetcher);

  return {
    data: (data ?? []).map((timespanRecord: { data: DeletedTimeSpan }) => timespanRecord.data),
    ...rest,
  };
}
