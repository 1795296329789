import { Button, Flex, Form, Size, Typography, useFormContext } from '@solace-health/ui';
import { UnpaidTimeSpansResponse } from '../../../hooks/useUnpaidTimeSpans';
import { TimeSpan } from '../../../types/timeSpan';
import { FormFields } from './PayoutsPage';
import { formatCurrency, normalizeTime, sumTotalTimeAndAmount } from './calculations';
import { AdvocateRow } from './AdvocateRow';

export const Table = ({
  unpaidTimeSpans,
  className = '',
  isSubmitting,
}: {
  unpaidTimeSpans: UnpaidTimeSpansResponse;
  className?: string;
  isSubmitting: boolean;
}) => {
  const { watch, setValue } = useFormContext();
  const selectedTimeSpanIds = watch(FormFields.TimeSpanIds);

  const { allTimeSpanIds, allCalculations } = Object.keys(unpaidTimeSpans).reduce(
    (summary, advocateId) => {
      const timeSpans = unpaidTimeSpans[advocateId];
      summary.allTimeSpanIds.push(...timeSpans.map((timeSpan) => timeSpan.id));

      const calculations = sumTotalTimeAndAmount({ selectedTimeSpanIds, timeSpans });

      summary.allCalculations.time = normalizeTime({
        hours: summary.allCalculations.time.hours + calculations.time.hours,
        minutes: summary.allCalculations.time.minutes + calculations.time.minutes,
      });
      summary.allCalculations.amount += calculations.amount;

      return summary;
    },
    { allTimeSpanIds: [] as TimeSpan['id'][], allCalculations: { time: { hours: 0, minutes: 0 }, amount: 0 } },
  );

  const toggleSelectAll = () => {
    if (selectedTimeSpanIds.length === allTimeSpanIds.length) {
      setValue(FormFields.TimeSpanIds, []);
    } else {
      setValue(FormFields.TimeSpanIds, allTimeSpanIds);
    }
  };

  return (
    <>
      <table cellPadding="1rem" className={className}>
        <tbody>
          <tr>
            <td>
              <Button.Link onClick={toggleSelectAll}>
                <Typography.Body size={Size.XS}>Select All</Typography.Body>
              </Button.Link>
            </td>
            <th>Patient</th>
            <th>Submitted Date Range</th>
            <th>Time</th>
            <th>Amount</th>
          </tr>
          {Object.keys(unpaidTimeSpans).map((advocateId) => (
            <AdvocateRow timeSpans={unpaidTimeSpans[advocateId]} isSubmitting={isSubmitting} />
          ))}
          <tr>
            <td></td>
            <td></td>
            <td>
              <Typography.Header>Summary</Typography.Header>
            </td>
            <td>
              <Typography.Header size={Size.SM}>
                Total: {allCalculations.time.hours} hours {allCalculations.time.minutes} minutes
              </Typography.Header>
            </td>
            <td>
              <Typography.Header size={Size.SM}>Total: {formatCurrency(allCalculations.amount)}</Typography.Header>
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
              <Flex justify="flex-end">
                <Form.Submit disabled={selectedTimeSpanIds.length === 0} isSubmitting={isSubmitting} />
              </Flex>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
