import { Flex, Size, Typography } from '@solace-health/ui';
import useGetUserCarePlans from '../../../../../hooks/useGetUserCarePlans';
import { GetUserResponse } from '../../../../../hooks/useGetUser';
import { CarePlan, CarePlanStatus } from '../../../../../types/carePlan';
import { textCompare } from '../../../../../shared/utils/table-utils';
import { AdminTable } from '../../../../../components/shared/Table';
import StatusPill from '../../../../../components/sharedStatusPill/StatusPill';
import dayjs from 'dayjs';

const getPillColorOptions = (status: CarePlanStatus) => {
  switch (status) {
    case CarePlanStatus.Denied:
      return {
        backgroundColor: 'var(--danger-bg-100, #F1E1E2)',
        borderColor: 'var(--border-color-danger, #DA4D54)',
        text: 'Denied',
      };
    case CarePlanStatus.Approved:
      return {
        backgroundColor: 'var(--green-100, #F4F8F7)',
        borderColor: 'var(--border-color-dark-green, #285E50)',
        text: 'Approved',
      };
    default:
      return {
        backgroundColor: 'var(--gold-100, #FFF7E9)',
        borderColor: 'var(--border-color-warning, #D7A13B)',
        text: 'Pending Review',
      };
  }
};

type CarePlanSectionProps = {
  patient: GetUserResponse;
};

export default function CarePlanSection({ patient }: CarePlanSectionProps) {
  const { data } = useGetUserCarePlans({ userId: patient.sharetribe_uuid });

  const columns = [
    {
      title: 'Created',
      dataIndex: 'drafted_dt',
      sorter: (a: CarePlan, b: CarePlan) => dayjs(a.drafted_dt).diff(dayjs(b.drafted_dt)),
      render: (date: string) => dayjs(date).format('MM/DD/YYYY'),
    },
    {
      title: 'CHW Name',
      dataIndex: 'advocateName',
      sorter: (a: CarePlan & { advocateName: string }, b: CarePlan & { advocateName: string }) =>
        textCompare(a, b, 'advocateName'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a: CarePlan, b: CarePlan) => textCompare(a, b, 'status'),
      render: (status: CarePlanStatus) => {
        const { borderColor, backgroundColor, text } = getPillColorOptions(status);
        return (
          <StatusPill borderColor={borderColor} backgroundColor={backgroundColor}>
            {text}
          </StatusPill>
        );
      },
    },
    {
      title: 'Reviewed By',
      dataIndex: 'reviewedBy',
      sorter: (a: CarePlan & { reviewedBy: string }, b: CarePlan & { reviewedBy: string }) =>
        textCompare(a, b, 'reviewedBy'),
      render: (reviewedBy: string | undefined, record: CarePlan) =>
        reviewedBy && (
          <Flex vertical>
            <Typography.Body>{reviewedBy}</Typography.Body>
            <Typography.Body size={Size.SM} color="#555555">
              {record?.approved_dt ? `Date: ${dayjs(record.approved_dt).format('MM/DD/YYYY')}` : 'Date: n/a'}
            </Typography.Body>
          </Flex>
        ),
    },
  ];

  const formmattedData = data.map((carePlan) => ({
    advocateName: `${carePlan.advocate?.first_name} ${carePlan.advocate?.last_name}`,
    reviewedBy:
      carePlan.last_updated_by_user?.first_name && carePlan.last_updated_by_user?.last_name
        ? `${carePlan.last_updated_by_user?.first_name} ${carePlan.last_updated_by_user?.last_name}`
        : undefined,
    key: carePlan.id,
    ...carePlan,
  }));

  return (
    <Flex vertical gap="1rem">
      <Typography.Header>Care Plans</Typography.Header>
      <AdminTable columns={columns} data={formmattedData} showSearch={false} />
    </Flex>
  );
}
