import { Flex, Size, Toggle, Typography } from '@solace-health/ui';
import { TraitId, User } from '../../../../../../types/user';
import { hasTrait } from '../../../../../../utils/user';
import useGetUser from '../../../../../../hooks/useGetUser';
import { useSnackbar } from 'notistack';

type Props = {
  user: User & { identities: { provider: 'auth0' | 'google-oauth2' | 'facebook' }[] };
  refresh: () => void;
};

export const Traits = ({ user, refresh }: Props) => {
  const { createTrait, deleteTrait } = useGetUser({ id: user.id, includes: ['identities'] });
  const hasSelfPayTrait = hasTrait({ user, traitId: TraitId.SelfPayAdvocate });
  const hasChwAdvocateTrait = hasTrait({ user, traitId: TraitId.CommunityHealthAdvocate });
  const hasSicaTrait = hasTrait({ user, traitId: TraitId.SolaceHealthAdvocate });
  const { enqueueSnackbar } = useSnackbar();

  const handleTraitClick = async (isToggled: boolean, traitId: TraitId) => {
    if (!traitId) return;

    try {
      if (isToggled) {
        await createTrait({
          id: user.id,
          traitIds: [traitId],
        });
      } else {
        await deleteTrait({
          id: user.id,
          traitId,
        });
      }
    } catch (error) {
      enqueueSnackbar(`Failed to update user traits - ${error}`, { variant: 'error' });
    } finally {
      refresh();
    }
  };

  return (
    <>
      <Flex gap={12} align="center" justify="space-between">
        <Typography.Body bold size={Size.LG}>
          CHW Advocate
        </Typography.Body>
        <Toggle
          name={TraitId.CommunityHealthAdvocate}
          handleEvent={(isToggled) => handleTraitClick(isToggled, TraitId.CommunityHealthAdvocate)}
          defaultState={hasChwAdvocateTrait && !hasSicaTrait}
          disabled={hasSicaTrait}
          value={TraitId.CommunityHealthAdvocate}
        />
      </Flex>
      <Flex gap={12} align="center" justify="space-between">
        <Typography.Body bold size={Size.LG}>
          Solace Insurance
        </Typography.Body>
        <Toggle
          name={TraitId.SolaceHealthAdvocate}
          handleEvent={(isToggled) => handleTraitClick(isToggled, TraitId.SolaceHealthAdvocate)}
          defaultState={hasSicaTrait}
          value={TraitId.SolaceHealthAdvocate}
        />
      </Flex>
      <Flex gap={12} align="center" justify="space-between">
        <Typography.Body bold size={Size.LG}>
          Self Pay Advocate
        </Typography.Body>
        <Toggle
          name={TraitId.SelfPayAdvocate}
          handleEvent={(isToggled) => handleTraitClick(isToggled, TraitId.SelfPayAdvocate)}
          defaultState={hasSelfPayTrait}
          value={TraitId.SelfPayAdvocate}
        />
      </Flex>
    </>
  );
};
