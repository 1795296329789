import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { AlgorithmVariable } from './AlgorithmVariable';
import useAlgorithmVariables from '../../../../hooks/useAlgorithmVariables';
import { orderBy } from 'lodash';

export const AlgorithmVariables = () => {
  const { data: algorithmVariables } = useAlgorithmVariables();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={12}>
              <h1>Active</h1>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>Name</TableCell>
            <TableCell sx={{ fontWeight: 700 }}>Slug</TableCell>
            <TableCell sx={{ fontWeight: 700 }}>Value</TableCell>
            <TableCell sx={{ fontWeight: 700 }}>Active Since</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {orderBy(algorithmVariables, 'slug').map((algorithmVariable) => (
            <AlgorithmVariable key={algorithmVariable.id} algorithmVariable={algorithmVariable} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
