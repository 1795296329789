import { Size, Typography } from '@solace-health/ui';
import {
  AdvocateOnboardingStep,
  AdvocateOnboardingStepType,
} from '../../../../../../hooks/users/useGetAdvocateOnboardingSteps';
import dayjs from 'dayjs';
import { OnboardingStatusPill } from './OnboardingStatusPill';
import * as S from './style';
import { useSnackbar } from 'notistack';
import { SolaceAPI } from '../../../../../../utils/api';
import { SolaceApiError } from '../../../../../../utils/errors';
import { KeyedMutator } from 'swr';
import { OnboardingStepStatus } from './types';

type Props = {
  onboardingSteps: AdvocateOnboardingStep[];
  advocateId: string;
  refresh: KeyedMutator<AdvocateOnboardingStep[]>;
};

export const BackgroundCheckStep = ({ onboardingSteps, advocateId, refresh }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const backgroundCheckCompletedStep = onboardingSteps.find(
    (step) => step.type === AdvocateOnboardingStepType.BackgroundCheckCleared,
  );

  let status: OnboardingStepStatus | null = null;
  let date = null;
  if (backgroundCheckCompletedStep) {
    status = OnboardingStepStatus.Completed;
    date = backgroundCheckCompletedStep.completed_dt;
  }

  if (!status) {
    const backgroundCheckPendingStep = onboardingSteps.find(
      (step) => step.type === AdvocateOnboardingStepType.BackgroundCheckPending,
    );
    if (backgroundCheckPendingStep) {
      status = OnboardingStepStatus.Pending;
      date = backgroundCheckPendingStep.completed_dt;
    }
  }

  if (!status) {
    const backgroundCheckSentStep = onboardingSteps.find(
      (step) => step.type === AdvocateOnboardingStepType.BackgroundCheckSent,
    );
    if (backgroundCheckSentStep) {
      status = OnboardingStepStatus.Sent;
      date = backgroundCheckSentStep.completed_dt;
    }
  }

  if (!status) status = OnboardingStepStatus.NotSent;

  const onMarkComplete = async () => {
    try {
      await SolaceAPI.post({
        path: `/api/admin/advocates/${advocateId}/onboarding_steps`,
        body: {
          type: AdvocateOnboardingStepType.BackgroundCheckCleared,
        },
      });
    } catch (e) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`Failed to mark cleared - [${error.requestId}]`, { variant: 'error' });
      return;
    }

    enqueueSnackbar(`Failed to mark cleared `, { variant: 'success' });
    refresh();
  };

  return (
    <tr>
      <td>
        <Typography.Header>Checkr Background Check</Typography.Header>
      </td>
      <td>
        <OnboardingStatusPill status={status} />
      </td>
      <td>
        {date ? (
          <Typography.Body size={Size.SM}>
            <i>{dayjs(date).format('MM/DD/YYYY [at] h:mm A')}</i>
          </Typography.Body>
        ) : null}
      </td>
      <td>{backgroundCheckCompletedStep?.completed_by?.fullName}</td>
      <td>{status !== 'completed' && <S.ActionButton onClick={onMarkComplete}>Mark Cleared</S.ActionButton>}</td>
    </tr>
  );
};
