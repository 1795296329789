import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { SearchSkill } from './SearchSkill';
import useGetSearchSkills, { SearchSkill as ISearchSkill } from '../../../../hooks/useGetSearchSkills';
import { useState } from 'react';

export const KeywordMatch = () => {
  const { data: searchSkills } = useGetSearchSkills();
  const [newSearchSkill, setNewSearchSkill] = useState<ISearchSkill | null>(null);

  const handleRemoveNewSkill = () => {
    setNewSearchSkill(null);
  };

  const handleAddSearchSkill = () => {
    setNewSearchSkill({
      id: '',
      skill_id: '',
      keywords: [],
      value: 0,
      distance_score_multiplier: 1,
      priority: 0,
      search_result_context: null,
      advocate_profile_context: null,
      skill: {
        id: '',
        name: 'New Skill',
        is_diagnosis: false,
      },
      deactivated_dt: null,
    });
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Skill</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Distance Multiplier</TableCell>
              <Tooltip
                title="The ranking priority for the advocate profile and search result context when a search matches multiple keywords"
                placement="top"
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>
                  <Box sx={{ display: 'flex' }}>
                    Context Priority <InfoIcon htmlColor="black" sx={{ marginLeft: '5px' }} fontSize="small" />
                  </Box>
                </TableCell>
              </Tooltip>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {searchSkills.map((searchSkill) => (
              <SearchSkill key={searchSkill.id} searchSkill={searchSkill} />
            ))}
            {newSearchSkill && <SearchSkill searchSkill={newSearchSkill} handleRemoveNewSkill={handleRemoveNewSkill} />}
          </TableBody>
        </Table>
      </TableContainer>
      {!newSearchSkill && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '24px' }}>
          <Button variant="contained" size="small" sx={{ marginLeft: '5px' }} onClick={handleAddSearchSkill}>
            Add
          </Button>
        </Box>
      )}
    </>
  );
};
