import styled from '@emotion/styled';
import { Button, Flex, Segmented, Typography } from '@solace-health/ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminTable } from '../../../../components/shared/Table';
import { useGetPaginatedAdvocates } from '../../../../hooks/users/useGetPaginatedAdvocates';
import { textCompare } from '../../../../shared/utils/table-utils';
import { Trait, TraitId } from '../../../../types/user';
import { getFullStateName, phoneNumberFormat } from '../../../../utils/general';
import { CreateAdvocateModal } from '../CreateAdvocateModal/CreateAdvocateModal';
import { TraitIcon } from './TraitIcon';

enum AdvocateFilterType {
  SolaceHealthAdvocate = 'solace_health_advocate',
  CommunityHealthAdvocate = 'community_health_advocate',
  SelfPayAdvocate = 'self_pay_advocate',
  CommunityHealthPhysician = 'communith_health_physician',
  All = 'all',
}

const getFilterLabel = (filter: AdvocateFilterType) => {
  switch (filter) {
    case AdvocateFilterType.SolaceHealthAdvocate:
      return 'Solace Advocate';
    case AdvocateFilterType.CommunityHealthAdvocate:
      return 'ICA';
    case AdvocateFilterType.SelfPayAdvocate:
      return 'Self Pay';
    case AdvocateFilterType.CommunityHealthPhysician:
      return 'Physician';
    case AdvocateFilterType.All:
      return 'All';
  }
};

const getTraitsForFilter = (filter: AdvocateFilterType) => {
  switch (filter) {
    case AdvocateFilterType.SolaceHealthAdvocate:
      return [TraitId.SolaceHealthAdvocate];
    case AdvocateFilterType.CommunityHealthAdvocate:
      return [TraitId.CommunityHealthAdvocate];
    case AdvocateFilterType.SelfPayAdvocate:
      return [TraitId.SelfPayAdvocate];
    case AdvocateFilterType.CommunityHealthPhysician:
      return [TraitId.CommunityHealthPhysician];
    case AdvocateFilterType.All:
      return [
        TraitId.CommunityHealthAdvocate,
        TraitId.SelfPayAdvocate,
        TraitId.SolaceHealthAdvocate,
        TraitId.CommunityHealthPhysician,
      ];
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const AdvocatesPage = () => {
  const navigate = useNavigate();

  const [upsertAdvocateModalOpen, setUpsertAdvocateModalOpen] = useState(false);
  const [filterSelection, setFilterSelection] = useState<AdvocateFilterType>(AdvocateFilterType.All);

  const {
    data: advocates,
    isLoading,
    pagination,
    setQueryParams,
    setSearch,
  } = useGetPaginatedAdvocates({
    traitIds: getTraitsForFilter(filterSelection),
    includes: ['traits', 'address'],
  });

  const columns = [
    {
      title: 'ID',
      dataIndex: 'internal_id',
      sorter: (a: { internal_id: number }, b: { internal_id: number }) => a.internal_id - b.internal_id,
      defaultSortOrder: 'descend' as const,
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      sorter: (a: { first_name: string }, b: { first_name: string }) => textCompare(a, b, 'first_name'),
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      sorter: (a: { last_name: string }, b: { last_name: string }) => textCompare(a, b, 'last_name'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a: { email: string }, b: { email: string }) => textCompare(a, b, 'email'),
      render: (email: string) => <div style={{ maxWidth: '300px' }}>{email}</div>,
    },
    {
      title: 'Location',
      dataIndex: 'location',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      sorter: (a: { phone: string }, b: { phone: string }) => Number(a.phone) - Number(b.phone),
      render: (phone: string) => phoneNumberFormat(phone, ''),
    },
    {
      title: 'Traits',
      dataIndex: 'traits',
      render: (traits: Trait[]) => (
        <Flex>
          {traits.map((trait) => {
            return <TraitIcon key={trait.id} trait={trait} />;
          })}
        </Flex>
      ),
    },
  ];

  const tableData = advocates.map((advocate) => ({
    key: advocate.internal_id,
    id: advocate.id,
    internal_id: advocate.internal_id,
    full_name: advocate.fullName,
    first_name: advocate.first_name,
    last_name: advocate.last_name,
    email: advocate.email,
    phone: advocate.phone,
    traits: advocate.traits,
    location: getFullStateName(advocate.address?.state),
  }));

  return (
    <Container>
      <Typography.Display>Advocates</Typography.Display>

      <Flex justify="space-between">
        <Segmented
          defaultValue={AdvocateFilterType.All}
          options={Object.values(AdvocateFilterType).map((type) => ({
            label: getFilterLabel(type),
            value: type,
          }))}
          onChange={(e) => setFilterSelection(e as AdvocateFilterType)}
        />

        <Button.Primary onClick={() => setUpsertAdvocateModalOpen(true)} style={{ maxWidth: '300px' }}>
          Add New Advocate
        </Button.Primary>
      </Flex>
      <AdminTable
        columns={columns}
        data={tableData}
        loading={isLoading}
        onRowClick={(user) => {
          navigate(`/Advocates/${user.id}`);
        }}
        showPagination
        pagination={pagination}
        onChange={setQueryParams}
        onTextFilter={setSearch}
      />
      <CreateAdvocateModal isOpen={upsertAdvocateModalOpen} setIsOpen={setUpsertAdvocateModalOpen} />
    </Container>
  );
};

export default AdvocatesPage;
