import { Button } from '@solace-health/ui';
import { HealthRecord } from '../../../types/healthRecord';
import { ConfirmActionModal } from './shared/ConfirmActionModal';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import usePatient from '../../../hooks/commonwell/usePatient';
import { useNavigate } from 'react-router-dom';

export const DeletePatient = ({ healthRecordId }: { healthRecordId: HealthRecord['id'] }) => {
  const { deletePatient } = usePatient({ healthRecordId });
  const [isModalOpen, setModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const onConfirm = async () => {
    await deletePatient()
      .then(() => {
        enqueueSnackbar('Successfully deleted health record', { variant: 'success' });
        navigate('/health_records');
      })
      .catch((e) => {
        enqueueSnackbar(`Failed to delete health record: ${e.message}`, { variant: 'error' });
      });
  };

  return (
    <>
      <Button.Unstyled
        onClick={() => setModalOpen(true)}
        style={{ maxWidth: '200px', background: 'rgb(218, 77, 84)', color: 'white' }}
      >
        Delete Health Record
      </Button.Unstyled>
      <ConfirmActionModal
        onClose={() => setModalOpen(false)}
        isOpen={isModalOpen}
        onConfirm={onConfirm}
        prompt="Are you sure you want to delete this health record?"
        confirmText="Yes, delete"
      />
    </>
  );
};
