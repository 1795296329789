import { useParams } from 'react-router-dom';
import useGetListingScore from '../../../hooks/useGetListingScore';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../../../components/shared/MaterialTableShared';
import styled from '@emotion/styled';
import { Box, Button, Collapse, LinearProgress, List, ListItemText } from '@mui/material';
import { useState } from 'react';
import { startCase } from 'lodash';

const Pre = styled.pre`
  white-space: pre-wrap;
`;

type CellProps = {
  row: { id: string; data: any };
  name: string;
  metadata: Record<string, string>;
  expanded: string | null;
};

const Cell = ({ row, name, metadata, expanded }: CellProps) => {
  return (
    <Box>
      <h4>{row.data[name]}</h4>
      <div>
        <Collapse in={expanded === row.id}>
          <List>
            {Object.keys(metadata).map((key) => (
              <ListItemText>
                <b>{startCase(key)}:</b> {metadata[key]}
              </ListItemText>
            ))}
          </List>
        </Collapse>
      </div>
    </Box>
  );
};

export const ListingScoreDetails = () => {
  const { listingId } = useParams() as { listingId: string };

  const [expanded, setExpanded] = useState<string | null>(null);

  const { data: listingScore, loading } = useGetListingScore({ listingId });

  const onCollapse = (id: string) => {
    if (expanded === id) return setExpanded(null);

    setExpanded(id);
  };

  const columns = [
    {
      title: 'Date',
      render: (row: any) => {
        const { date } = row;

        return (
          <h3>
            <Button variant="contained" onClick={() => onCollapse(row.id)}>
              {new Date(date).toLocaleDateString()}
            </Button>
          </h3>
        );
      },
    },
    {
      title: 'Quality',
      render: (row: any) => {
        const { quality_score_metadata } = row.data;

        if (!quality_score_metadata) return null;

        return <Cell row={row} name="quality_score" metadata={quality_score_metadata} expanded={expanded} />;
      },
    },
    {
      title: 'Efficacy',
      render: (row: any) => {
        const { efficacy_score_metadata } = row.data;

        if (!efficacy_score_metadata) return null;

        return <Cell row={row} name="efficacy_score" metadata={efficacy_score_metadata} expanded={expanded} />;
      },
    },
    {
      title: 'Monetary',
      render: (row: any) => {
        const { monetary_score_metadata } = row.data;

        if (!monetary_score_metadata) return null;

        return <Cell row={row} name="monetary_score" metadata={monetary_score_metadata} expanded={expanded} />;
      },
    },
    {
      title: 'Conversion',
      render: (row: any) => {
        const { conversion_score_metadata } = row.data;

        if (!conversion_score_metadata) return null;

        return <Cell row={row} name="conversion_score" metadata={conversion_score_metadata} expanded={expanded} />;
      },
    },
  ];

  if (loading) {
    return (
      <div>
        <h1>Loading...</h1>
        <LinearProgress style={{ height: '2px', width: '500px', marginTop: '24px' }} />
      </div>
    );
  }

  return (
    <div>
      <h1>{listingScore?.listing.advocate_name}</h1>
      {loading && <LinearProgress style={{ height: '2px', width: '500px', marginTop: '24px' }} />}
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        data={listingScore?.versions || []}
        title=""
        options={{
          maxColumnSort: 'all_columns',
          pageSize: 50,
          pageSizeOptions: [25, 50, 100],
        }}
      />
    </div>
  );
};
