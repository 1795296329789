import { UsaState } from '../shared/constants/general-constants';
import { AdvocatePatient } from './advocatePatient';
import { CarePlan } from './carePlan';
import { Address } from './forms';
import { Listing } from './listing';
import { Referral } from './referrals';

export enum TraitId {
  ArchivedPatient = 'archived_patient',
  CommunityHealthAdvocate = 'community_health_advocate',
  CommunityHealthPatient = 'community_health_patient',
  SelfPayAdvocate = 'self_pay_advocate',
  CommunityHealthPhysician = 'community_health_physician',
  SolaceHealthAdvocate = 'solace_health_advocate',
  CommunityHealthLovedOne = 'community_health_loved_one',
  InternalUser = 'internal_user',
}

export type Trait = {
  id: TraitId;
  name: string;
};

export enum UserTimeZone {
  Chicago = 'America/Chicago',
  Denver = 'America/Denver',
  LosAngeles = 'America/Los_Angeles',
  NewYork = 'America/New_York',
  Phoenix = 'America/Phoenix',
  Sitka = 'America/Sitka',
  Honolulu = 'Pacific/Honolulu',

  Anchorage = 'America/Anchorage',
  Detroit = 'America/Detroit',
  Indianapolis = 'America/Indianapolis',
  Juneau = 'America/Juneau',
  Louisville = 'America/Louisville',
  Menominee = 'America/Menominee',
}

export enum NoteType {
  Admin = 'admin',
  AdvocateToPatient = 'advocate_to_patient',
}

export enum PatientStatus {
  Unverified = 'unverified',
  Schedule_Clinician = 'schedule_clinician',
  Check_Insurance = 'check_insurance',
  Clinician_Visit = 'clinician_visit',
  Schedule_Chw = 'schedule_chw',
  Chw_Visit = 'chw_visit',
  Active = 'active',
  Archived = 'archived',
  LovedOne = 'loved_one',
  Unknown = 'unknown',
}

export type User = {
  id: string;
  internal_id: number;
  first_name: string;
  last_name: string;
  fullName: string;
  active: boolean;
  email: string;
  phone: string;
  dob?: string | null;
  business_name?: string | null;
  email_confirmed: boolean;
  refresh_token_issued: string;
  email_verification_code?: string | null;
  login_fail_count: number;
  logo_image_url: string | null;
  last_login: string | null;
  search_field: string;
  has_patient_profile: boolean;
  has_advocate_profile: boolean;
  sharetribe_uuid: string;
  sharetribe_default_listing_uuid: string | null;
  signup_dt: string | null;
  profile_image_url: string | null;
  address_id: string | null;
  address: Partial<Address> | null;
  time_zone: UserTimeZone;
  user_notes: UserNote[];
  referral?: Referral;
  created_dt: Date;
  created_at: string;
  patient_status: PatientStatus;
  traits?: Trait[];
  patient_care_plans?: CarePlan[];
  physician_states?: PhysicianState[];
  advocate_relationships?: AdvocatePatient[];
  listings?: Listing[];
  archived_dt: string | null;
};

export type UserNote = {
  id: string;
  from: number;
  to: number;
  title: string;
  description: string;
  type: NoteType;
  last_edit_dt: string;
  created_at: Date;
  updated_at: Date;
  deleted: boolean | null;
  fromUser: User;
  toUser: User;
};

export type PhysicianState = {
  state: UsaState;
  physician_id: string;
  physician: User;
};

export enum RoleName {
  Admin = 'Admin',
  CallTranscripts = 'CallTranscripts',
  CustomerExperienceAdmin = 'CustomerExperienceAdmin',
  CustomerExperience = 'CustomerExperience',
  Forms = 'Forms',
  HealthRecords = 'HealthRecords',
  Operations = 'Operations',
  Outreach = 'Outreach',
  Payouts = 'Payouts',
  Product = 'Product',
  Referrals = 'Referrals',
}

export type Role = {
  id: number;
  name: RoleName;
  created_at: Date;
  updated_at: Date;
};
