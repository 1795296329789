import useSWR from 'swr';
import qs from 'qs';
import fetcher from '../fetcher';
import { Form } from '../../types/forms';

type Query = {
  include?: ('form_template' | 'sender' | 'sent_to')[];
};

export const useGetForms = <Relations extends {}>({ include }: Query = {}) => {
  const query = qs.stringify({ include });
  const { data, error, isLoading } = useSWR<(Form & Relations)[]>(`/v1/api/form?${query}`, fetcher);

  return {
    data: data || [],
    loading: isLoading,
    error,
  };
};
