import { Button, Divider, Flex, Icons, Size, Typography } from '@solace-health/ui';
import { EncounterStatus, PhysicianVisit } from '../../../../../../types/physicianVisit';
import StatusPill from '../../../../../../components/sharedStatusPill/StatusPill';
import { statusPillOptions } from '../VisitsAndEncounter';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import { getDurationInHoursAndMinutes } from '../../../../../../utils/general';

const ListItem = ({ label, value }: { label: string; value: string }) => (
  <Flex justify="space-between" align="center">
    <Typography.Header size={Size.SM}>{label}</Typography.Header>
    <Typography.Body color="#555555">{value}</Typography.Body>
  </Flex>
);

const EncounterNoteItem = ({ label, value }: { label: string; value: string }) => {
  const splitLines = value.split('. ').filter((line) => line.trim() !== '');

  return (
    <Flex vertical gap=".25rem">
      <Typography.Header size={Size.SM}>{label}</Typography.Header>
      <ul style={{ display: 'flex', flexDirection: 'column', gap: '.25rem' }}>
        {splitLines.map((line) => (
          <li>
            <Typography.Body>{line}</Typography.Body>
          </li>
        ))}
      </ul>
    </Flex>
  );
};

type EncounterProps = {
  data: PhysicianVisit;
  onClose: () => void;
};

export default function Encounter({ data, onClose }: EncounterProps) {
  const { borderColor, backgroundColor, text } = statusPillOptions(
    !!data.submitted_dt ? EncounterStatus.Submitted : EncounterStatus.Pending,
  );

  const { hours: hoursSpent, mins: minutesSpent } = getDurationInHoursAndMinutes(data.time_spent);
  const { assessment, objective, plan, subjective } = data.soap_note;

  return (
    <Flex vertical gap="var(--Spacing-xl, 1.5rem)">
      <Flex justify="space-between" align="center">
        <Typography.Header>Encounter</Typography.Header>
        <Button.Outline onClick={onClose} style={{ maxWidth: '5rem' }}>
          Close
        </Button.Outline>
      </Flex>
      <div>
        <StatusPill backgroundColor={backgroundColor} borderColor={borderColor}>
          {text}
        </StatusPill>
      </div>
      <Flex gap="1.5rem" vertical>
        <ListItem value={`${data.physician?.first_name} ${data.physician?.last_name}`} label="Clinician" />
        <ListItem value={dayjs(data.encounter_dt).format('MM/DD/YYYY')} label="Date of Service" />
        <ListItem value={dayjs(data.submitted_dt).format('MM/DD/YYYY')} label="Date Submitted" />
        <ListItem value={`${hoursSpent ? `${hoursSpent}h ` : ''}${minutesSpent}m`} label="Duration" />
        <ListItem value={capitalize(data.type)} label="Type" />
      </Flex>
      <Divider />
      <Flex align="center" gap=".5rem">
        <Icons.Hamburger />
        <Typography.Header>Encounter Notes</Typography.Header>
      </Flex>
      <Flex
        style={{ border: '1px solid #555', borderRadius: '.5rem', padding: '1.5rem 1rem 0rem 1rem' }}
        vertical
        gap="1rem"
      >
        {assessment && <EncounterNoteItem label="Assessment" value={assessment} />}
        {objective && <EncounterNoteItem label="Objective" value={objective} />}
        {plan && <EncounterNoteItem label="Plan" value={plan} />}
        {subjective && <EncounterNoteItem label="Subjective" value={subjective} />}
      </Flex>
    </Flex>
  );
}
