import * as React from 'react';
import PropTypes from 'prop-types';
import AppAuthenticated from './AppAuthenticated';
import AppPublic from './AppPublic';
import './App.css';
import ErrorBoundary from './components/shared/ErrorBoundary';
import useAuthentication from './pages/public/auth/useAuth';
import { ThemeVariablesProvider } from '@solace-health/ui';

function App() {
  const { isAuthenticated } = useAuthentication();

  return (
    <ErrorBoundary>
      <ThemeVariablesProvider>{isAuthenticated ? <AppAuthenticated /> : <AppPublic />}</ThemeVariablesProvider>
    </ErrorBoundary>
  );
}

App.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default App;
