import useSWR from 'swr';
import fetcher from './fetcher';
import { HealthRecord } from '../types/healthRecord';
import { User } from '../types/user';
import { SolaceAPI } from '../utils/api';

const useHealthRecords = () => {
  const { data, error, mutate, isLoading } = useSWR<HealthRecord[]>(`/v1/api/health_records`, fetcher);

  const upsertForUser = async (userId: User['id']) => {
    return await SolaceAPI.post({ path: `/api/admin/users/${userId}/health_records` });
  };

  return {
    healthRecords: data || [],
    error,
    refresh: mutate,
    isLoading,
    upsertForUser,
  };
};

export default useHealthRecords;
