import useSWR from 'swr';
import qs from 'qs';
import fetcher from '../fetcher';
import { Referral, ReferralEligibilityCheck } from '../../types/referrals';
import { useState } from 'react';
import { QueryParamInputs } from '../../components/shared/Table/antdOnChangeToQueryParams';

type PaginatedResponseReturn<T> = {
  data: T[];
  meta: {
    page: number;
    perPage: number;
    totalItems: number;
    totalPages: number;
  };
};

type ReferralEligibilityChecksApiResponse = PaginatedResponseReturn<
  ReferralEligibilityCheck & {
    referral: Referral & {
      user?: { first_name?: string; last_name?: string };
      prospect?: { first_name?: string; last_name?: string };
    };
  }
>;

const useEligibilityChecks = () => {
  const [queryParamInputs, setQueryParamInputs] = useState<QueryParamInputs>({
    page: 1,
    sort: ['-submitted_dt'],
    search: '',
  });
  const urlParams = qs.stringify({
    ...queryParamInputs,
    include: ['referral.prospect', 'referral.user'],
    page: { number: queryParamInputs.page, size: 10 },
  });

  const { data: responseBody, error } = useSWR<ReferralEligibilityChecksApiResponse>(
    `/v1/api/referrals/eligibility_checks?${urlParams}`,
    fetcher,
    { keepPreviousData: true },
  );

  const data = responseBody?.data;
  const meta = responseBody?.meta;

  return {
    data: data || [],
    setQueryParamInputs,
    queryParamInputs,
    pagination: {
      current: queryParamInputs.page,
      pageSize: 10,
      total: meta?.totalItems || 0,
      defaultPageSize: 10,
    },
    loading: data === undefined,
    error,
  };
};

export default useEligibilityChecks;
