import { Button, styled } from '@solace-health/ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .ant-table-row:hover {
    cursor: pointer;
  }
`;

export const LastContact = styled(Button.Unstyled)`
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid #5a5a5a;
  background: #fff;
  color: #000000;
  padding: 4px 8px;
  margin: 8px 0;
`;
