import { useContext } from 'react';
import auth0, { Auth0DecodedHash } from 'auth0-js';
import { AuthenticationContext } from '../../../contexts/AuthenticationProvider';
import { SolaceAPI } from '../../../utils/api';

export enum SocialAuthProvider {
  GOOGLE = 'google-oauth2',
  FACEBOOK = 'facebook',
}
export type AuthResult = {
  accessToken: string;
  idToken: string;
  idTokenPayload: {
    given_name?: string;
    family_name?: string;
    email?: string;
    user_metadata: { email?: string };
    sub: string;
  };
};

const appUrl = process.env.REACT_APP_ADMIN_URL;
const clientID = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
const domain = process.env.REACT_APP_AUTH0_DOMAIN as string;
export const webAuth = new auth0.WebAuth({
  domain,
  clientID,
  responseType: 'token id_token',
  scope: 'openid profile email',
});

const useAuthentication = () => {
  const { setIsAuthenticated, isAuthenticated } = useContext(AuthenticationContext);

  const setTokens = ({ accessToken }: Auth0DecodedHash) => {
    localStorage.setItem('accessToken', accessToken as string);
  };

  const login = async ({ email, password }: { email: string; password: string }) =>
    new Promise((_, reject) => {
      webAuth.login(
        {
          realm: 'Username-Password-Authentication',
          username: email,
          password,
          redirectUri: `${appUrl}/auth-callback`,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        },
        (error: any) => {
          reject(error);
        },
      );
    });

  const handleCallback = async (hash: string): Promise<Auth0DecodedHash> =>
    new Promise((resolve, reject) => {
      webAuth.parseHash({ hash }, (err: any, authResult) => {
        if (err) {
          if (err.error === 'invalid_token') return;

          console.error(err);
          return reject(err);
        }

        setTokens(authResult as Auth0DecodedHash);

        setIsAuthenticated(true);

        resolve(authResult as Auth0DecodedHash);
      });
    });

  const logout = async () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    localStorage.removeItem('user_roles');
    localStorage.removeItem('roles');
    localStorage.removeItem('my_sharetribe_user');
    global.location.href = '/';
  };

  const requestResetPassword = async (email: string) => {
    const { data } = await SolaceAPI.post<{ message: string }>({ path: '/auth/request_password_reset', body: { email } });

    return data.message;
  };

  return {
    login,
    logout,
    handleCallback,
    setTokens,
    setIsAuthenticated,
    isAuthenticated,
    requestResetPassword,
  };
};

export default useAuthentication;
