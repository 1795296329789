import { Flex, Form, useForm } from '@solace-health/ui';
import * as S from './style';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { InternalUserSchema, InternalUserSchemaType } from './schema';
import { User } from '../../../../../types/user';
import { SolaceAPI } from '../../../../../utils/api';
import { SolaceApiError } from '../../../../../utils/errors';
import { useSnackbar } from 'notistack';
import { UserSchemaType } from '../../PatientPage/PatientInfoSection/schema';

export type Props = {
  user: User;
  refresh: () => void;
};

export const InfoSection = ({ user, refresh }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isSavingChanges, setIsSavingChanges] = useState(false);

  const formMethods = useForm<InternalUserSchemaType>({
    resolver: zodResolver(InternalUserSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
    },
    reValidateMode: 'onChange',
  });

  const {
    formState: { dirtyFields },
    reset,
  } = formMethods;

  useEffect(() => {
    reset({
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
    });
  }, [user, reset]);

  const handleSaveChanges = async (formData: UserSchemaType) => {
    setIsSavingChanges(true);

    const { first_name, last_name, email } = formData;

    if (user.first_name !== first_name || user.last_name !== last_name || user.email !== email) {
      try {
        await SolaceAPI.put({ path: `/api/internal_users/${user.id}`, body: { first_name, last_name, email } });
        enqueueSnackbar(`Updated user.`, { variant: 'success' });
      } catch (e) {
        const error = e as SolaceApiError;
        enqueueSnackbar(`Failed to update user - ${error.message}.`, { variant: 'error' });
      }

      refresh();
      setIsSavingChanges(false);
    }
  };

  const valuesHaveChanges = Object.keys(dirtyFields).length > 0;

  return (
    <Form.Container formMethods={formMethods} onSubmit={handleSaveChanges}>
      <S.Container>
        <S.FormWrapper>
          <Flex justify="space-between" gap="1rem">
            <Form.TextInput name="first_name" label="First Name" formOptions={{ required: true }} />
            <Form.TextInput name="last_name" label="Last Name" formOptions={{ required: true }} />
          </Flex>
          <Form.TextInput name="email" label="Email" formOptions={{ required: true }} />
        </S.FormWrapper>
      </S.Container>

      <S.StickyButtonContainer show={valuesHaveChanges}>
        <Form.Submit label="Save Changes" isSubmitting={isSavingChanges} />
      </S.StickyButtonContainer>
    </Form.Container>
  );
};
