import { Grid, Tab as MuiTab, Tabs, styled } from '@mui/material';
import { useState } from 'react';
import { KeywordMatch } from './KeywordMatch/KeywordMatch';
import { AIModels } from './AIModels/AIModels';
import { AlgorithmVariables } from './AlgorithmVariables/AlgorithmVariables';
import { useQuery } from '../../../hooks/useQuery';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const qs = require('qs');

const Tab = styled(MuiTab)(({ active }: { active: string }) => ({
  backgroundColor: active === 'true' ? 'var(--appPrimaryColor)' : 'white',
  border: active === 'true' ? '' : '1px solid black',
  color: active === 'true' ? 'white' : 'black',
}));

export const SearchConfigPage = () => {
  const query = useQuery();
  const tab = query.get('tab');

  const [value, setValue] = useState(parseInt(tab || '') || 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <h2>Search </h2>
      </Grid>

      <Grid item xs={12}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label={<span style={{ color: value === 0 ? 'white' : 'black' }}>AI Models</span>}
            active={String(value === 0)}
          />
          <Tab
            label={<span style={{ color: value === 1 ? 'white' : 'black' }}>Algorithm Variables</span>}
            active={String(value === 1)}
          />
          <Tab
            label={<span style={{ color: value === 2 ? 'white' : 'black' }}>Keyword Matches</span>}
            active={String(value === 2)}
          />
        </Tabs>
      </Grid>

      <Grid item xs={12} role="tabpanel" hidden={value !== 0} id={`simple-tabpanel-${0}`}>
        {value === 0 && <AIModels />}
      </Grid>

      <Grid item xs={12} role="tabpanel" hidden={value !== 1} id={`simple-tabpanel-${1}`}>
        {value === 1 && <AlgorithmVariables />}
      </Grid>

      <Grid item xs={12} role="tabpanel" hidden={value !== 2} id={`simple-tabpanel-${2}`}>
        {value === 2 && <KeywordMatch />}
      </Grid>
    </Grid>
  );
};
