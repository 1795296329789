import { Box, Button, Card, FormLabel, LinearProgress, Paper, TextField } from '@mui/material';
import { useState } from 'react';
import fetcher from '../../../hooks/fetcher';

type ListingsMatch = {
  id: string;
  score?: number;
  values?: number[];
  metadata: {
    content: string;
    content_tokens: number;
    listing_id: string;
    profile_attribute: string;
  };
};

export const TestAISearch = () => {
  const [query, setQuery] = useState('');
  const [listings, setListings] = useState<{ id: string; advocate_name: string; match: ListingsMatch }[]>([]);
  const [loading, setLoading] = useState(false);

  const search = () => {
    setLoading(true);
    fetcher(`/v1/api/ai/listings?query=${query}`)
      .then((listings) => {
        setListings(listings);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  };

  return (
    <Box>
      <Card sx={{ padding: '24px', marginBottom: '48px' }}>
        <FormLabel>
          <h3>How can we help?</h3>
        </FormLabel>
        <TextField style={{ width: '100%' }} multiline rows={5} value={query} onChange={(e) => setQuery(e.target.value)} />
        <Box display="flex" justifyContent="flex-end" width="100%" padding="24px">
          <Button variant="contained" onClick={search}>
            Search
          </Button>
        </Box>
      </Card>
      {loading ? (
        <div>
          <h3>Loading results...</h3>
          <LinearProgress style={{ height: '2px', width: '500px', marginTop: '24px' }} />
        </div>
      ) : (
        <Box>
          <h3>Matched {listings.length} advocates</h3>
          {listings.map((listing) => (
            <Paper sx={{ marginBottom: '24px', padding: '12px' }} elevation={12}>
              <h2 style={{ marginTop: '6px' }}>{listing.advocate_name}</h2>
              <h3>Score: {listing.match.score}</h3>
              <h3>Matched from: {listing.match.metadata.profile_attribute}</h3>
              <Box>{listing.match.metadata.content}</Box>
            </Paper>
          ))}
        </Box>
      )}
    </Box>
  );
};
