import useSWR from 'swr';
import qs from 'qs';
import fetcher from '../fetcher';
import { Prospect } from '../../types/prospect';

export enum BookingHelpRequestStatus {
  Pending = 'pending',
  Complete = 'complete',
}

export enum BookingHelpRequestType {
  /** The prospect requested a call back via the funnel */
  RequestCallback = 'request_callback',
  /** A provider submitted a referral for a patient */
  PatientReferral = 'patient_referral',
}

export type BookingHelpRequest = {
  id: string;
  requested_dt: string;
  completed_dt: string;
  deleted_dt: string;
  phone: string;
  prospect_id: string;
  resolved_by: {
    first_name: string;
  };
  last_contact_by: {
    first_name: string;
  };
  prospect: Prospect;
  type: BookingHelpRequestType;
  last_contact_dt: string | null;
  contacted_count: number;
  payload: {
    insurance?: string;
    patient_notes?: string;

    provider?: {
      first_name: string;
      last_name: string;
      phone: string;
      email: string;
      city?: string;
      state?: string;
      organization_name?: string;
      title?: string;
      npi?: string;
      other_information?: string;
    };
  };
};

export type BookingHelpRequestFilter = {
  type?: BookingHelpRequestType;
  isNull?: ('completed_dt' | 'deleted_dt')[];
  notNull?: 'completed_dt' | 'deleted_dt';
};

const useGetBookingHelpRequests = ({ filter }: { filter?: BookingHelpRequestFilter }) => {
  const params = qs.stringify({ filter, include: ['last_contact_by', 'resolved_by', 'prospect'] });
  const { data, error, isLoading, mutate } = useSWR<BookingHelpRequest[]>(
    `/v1/api/booking_help_requests?${params}`,
    fetcher,
  );

  return {
    data: data || [],
    loading: isLoading,
    error,
    refresh: mutate,
  };
};

export default useGetBookingHelpRequests;
