import { Button, Flex, Typography } from '@solace-health/ui';
import useHealthRecords from '../../../hooks/useHealthRecords';
import { AdminTable } from '../../../components/shared/Table';
import { useMemo } from 'react';
import { HealthRecord, RetrievalStatus } from '../../../types/healthRecord';
import { useNavigate } from 'react-router-dom';
import { CreatePatient } from './CreatePatient';
import { RefreshRecord } from './RefreshRecord';

export const HealthRecordsPage = () => {
  const navigate = useNavigate();
  const { healthRecords, isLoading } = useHealthRecords();

  const { columns, tableData } = useMemo(() => {
    if (isLoading) return { columns: [], tableData: [] };

    const columns = [
      {
        title: 'Email',
        dataIndex: 'email',
      },
      {
        title: 'First Name',
        dataIndex: 'first_name',
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name',
      },
      {
        title: 'Retrieval Status',
        dataIndex: 'retrieval_status',
      },
      {
        title: 'Retrieval Attempts',
        dataIndex: 'retrieval_attempts',
      },
      {
        title: 'Last Sync Date',
        render: (healthRecord: HealthRecord) =>
          healthRecord.last_retrieval_attempt_dt ? (
            <Flex gap="1rem">
              <RefreshRecord healthRecord={healthRecord} />
              {new Date(healthRecord.last_retrieval_attempt_dt).toLocaleString('en-us', { timeZoneName: 'short' })}
            </Flex>
          ) : (
            <Flex gap="1rem">
              <RefreshRecord healthRecord={healthRecord} />
              N/A
            </Flex>
          ),
      },
      {
        render: (healthRecord: HealthRecord) => {
          return (
            healthRecord.retrieval_status === RetrievalStatus.Complete && (
              <Button.Primary onClick={() => navigate(`/health_records/${healthRecord.id}`)}>View</Button.Primary>
            )
          );
        },
      },
    ];

    return {
      columns,
      tableData: healthRecords.map((healthRecord) => {
        return {
          ...healthRecord,
          first_name: healthRecord.user.first_name,
          last_name: healthRecord.user.last_name,
          email: healthRecord.user.email,
        };
      }),
    };
  }, [healthRecords, isLoading]);

  if (isLoading) return <>Loading...</>;

  return (
    <Flex vertical gap="1rem">
      <Flex justify="space-between">
        <Typography.Display>Health Records</Typography.Display>
        <CreatePatient />
      </Flex>

      <AdminTable columns={columns} data={tableData} showSearch={true} onRowClick={() => null} />
    </Flex>
  );
};
