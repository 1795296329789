import useSWR from 'swr';
import fetcher from './fetcher';
import { Trait } from '../types/user';

export type UserCareTeamResponse = { name: string; location?: string; contact?: string; relationship: Trait[] | string };

export default function useGetUserCareTeam({ userId }: { userId?: string }) {
  const {
    data,
    error,
    mutate: refresh,
    isLoading,
  } = useSWR<UserCareTeamResponse[]>(userId ? `/v1/api/admin/users/${userId}/care_team` : null, fetcher);

  return {
    loading: isLoading,
    data,
    error,
    refresh,
  };
}
