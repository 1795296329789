import { useMemo } from 'react';
import { TraitId } from '../../types/user';
import useGetPaginatedResource from '../useGetPaginatedResource';
import { BookingState } from '../../types/booking';
import { VideoCallsResponse } from './useVideoCalls';

export const useGetPaginatedVideoCalls = ({ traitId }: { traitId?: TraitId | null } = {}) => {
  const start = useMemo(() => new Date().toISOString(), []);

  const filter = traitId ? { advocate: { traits: { id: traitId } } } : {};

  const restPaginatedRourceHookReturn = useGetPaginatedResource<VideoCallsResponse>('/v1/api/video_calls', {
    filter: { ...filter, state: [BookingState.NotaMatch, BookingState.Accepted], lessThan: { start } },
    include: ['advocate.traits', 'client', 'video_call.call_transcript_items'],
    sort: ['-start'],
  });

  return {
    ...restPaginatedRourceHookReturn,
    refresh: restPaginatedRourceHookReturn.mutate,
  };
};
