import { styled, Popover as UIPopover } from '@solace-health/ui';

export const Circle = styled.div<{ active: boolean }>`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? '#347866' : '#D9D9D9')};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Popover = styled(UIPopover)`
  min-width: 0;

  .ant-popover-inner {
    background-color: #e9f0ee;
    padding: 0.5rem;
  }
`;
