import { Flex, Toggle, Typography } from '@solace-health/ui';
import { useMemo } from 'react';
import { AdminTable } from '../../../components/shared/Table';
import useFeatureFlags, { FeatureFlag } from '../../../hooks/useFeatureFlags';
import { SolaceAPI } from '../../../utils/api';
import { useSnackbar } from 'notistack';
import { SolaceApiError } from '../../../utils/errors';

export const FeatureFlagsPage = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { data: featureFlags, loading, refresh } = useFeatureFlags();

  const onToggle = async ({ isToggled, id }: any) => {
    try {
      await SolaceAPI.put({
        path: `/api/feature_flags/${id}`,
        body: { active_dt: isToggled ? new Date().toISOString() : null },
      });
    } catch (e) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`Failed to toggle feature flag - ${error.message}`, { variant: 'error' });
    } finally {
      refresh();
    }
  };

  const { columns, tableData } = useMemo(() => {
    const columns = [
      {
        title: 'Slug',
        dataIndex: 'id',
        render: (id: string) => (
          <strong>
            <pre>{id}</pre>
          </strong>
        ),
      },
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      {
        title: 'Active Since',
        dataIndex: 'active_dt',
        render: (activeDt: string) =>
          activeDt ? new Date(activeDt).toLocaleString('en-us', { timeZoneName: 'short' }) : 'N/A',
      },
      {
        title: 'Active',
        render: (featureFlag: FeatureFlag) => (
          <Toggle
            defaultState={featureFlag.is_active}
            name="is_active"
            handleEvent={(isToggled) => onToggle({ isToggled, id: featureFlag.id })}
            value={String(featureFlag)}
          />
        ),
      },
    ];

    return { columns, tableData: featureFlags };
  }, [featureFlags]);

  return (
    <Flex vertical gap="1rem">
      <Typography.Display>Feature Flags</Typography.Display>

      <AdminTable columns={columns} data={tableData} loading={loading} showSearch={false} onRowClick={() => null} />
    </Flex>
  );
};
