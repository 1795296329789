import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import ReCAPTCHA from 'react-google-recaptcha';
import useAuthentication from './useAuth';
import * as S from './style';
import { Flex, Form, Size, Typography, useForm } from '@solace-health/ui';

const Login: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { login } = useAuthentication();
  const formMethods = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const [recaptcha, setRecaptcha] = useState<string | null>(null);
  const [isLoading, setLoading] = useState(false);

  const handleCaptchaChange = (value: string | null) => {
    setRecaptcha(value);
  };

  const handleLogin = async ({ email, password }: { email: string; password: string }) => {
    if (!recaptcha) {
      enqueueSnackbar("Please confirm you're not a robot", { variant: 'error' });
      return;
    }

    if (!email || !password) {
      enqueueSnackbar('Please provide an email and/or password', { variant: 'error' });
      return;
    }

    setLoading(true);

    try {
      await login({ email, password });
      setLoading(false);
    } catch (err: any) {
      enqueueSnackbar(err.description, { variant: 'error' });
      setLoading(false);
    }
  };

  return (
    <S.FormContainer onSubmit={(formMethods) => handleLogin(formMethods)} formMethods={formMethods}>
      <S.CardContent vertical gap={16}>
        <Flex vertical gap={8}>
          <Typography.Display center size={Size.SM}>
            Welcome Back!
          </Typography.Display>
          <Typography.Body center size={Size.LG}>
            Please login
          </Typography.Body>
        </Flex>
        <Form.Text name="email" placeholder="Enter your email address" disabled={isLoading} id="email" />
        <Form.Text name="password" placeholder="Password" disabled={isLoading} id="password" type="password" />
        <Flex style={{ marginTop: '16px' }}>
          <ReCAPTCHA
            onChange={(event) => handleCaptchaChange(event)}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
          />
        </Flex>
        <Flex vertical gap={16} style={{ marginTop: '16px' }}>
          <Form.Submit label="Login" disabled={isLoading} />
        </Flex>
      </S.CardContent>
    </S.FormContainer>
  );
};

export default Login;
