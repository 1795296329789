import { difference, groupBy } from 'lodash';
import { TimeSpan } from '../../../types/timeSpan';
import { User } from '../../../types/user';
import { Button, Flex, Size, Typography, useFormContext } from '@solace-health/ui';
import { FormFields } from './PayoutsPage';
import { formatCurrency, sumTotalTimeAndAmount } from './calculations';
import { PatientLineItem } from './PatientLineItem';

export const AdvocateRow = ({
  timeSpans,
  isSubmitting,
}: {
  timeSpans: (TimeSpan & {
    advocate_patient: {
      patient: User;
      advocate: User;
    };
  })[];
  isSubmitting: boolean;
}) => {
  const { watch, setValue, getValues } = useFormContext();
  const selectedTimeSpanIds = watch(FormFields.TimeSpanIds);

  if (timeSpans.length === 0) return null;

  const advocateName = timeSpans[0].advocate_patient.advocate.fullName;

  const appendToSelected = (timeSpanIds: TimeSpan['id'][]) => {
    setValue(FormFields.TimeSpanIds, Array.from(new Set([...getValues(FormFields.TimeSpanIds), ...timeSpanIds])));
  };

  const toggleSelectAllForAdvocate = () => {
    const advocateTimeSpanIds = timeSpans.map((timeSpan) => timeSpan.id);
    const otherSelectedAdvocatesTimeSpanIds = difference(selectedTimeSpanIds, advocateTimeSpanIds);
    const isEveryTimeSpanSelectedForAdvocate =
      selectedTimeSpanIds.length - otherSelectedAdvocatesTimeSpanIds.length === advocateTimeSpanIds.length;

    if (isEveryTimeSpanSelectedForAdvocate) {
      setValue(FormFields.TimeSpanIds, otherSelectedAdvocatesTimeSpanIds);
    } else {
      appendToSelected(advocateTimeSpanIds);
    }
  };

  const timeSpansByPatientId = groupBy(timeSpans, 'timesheet.advocate_patient.patient_id');

  const calculations = sumTotalTimeAndAmount({ selectedTimeSpanIds, timeSpans });

  return (
    <>
      <tr>
        <th colSpan={5}>
          <Flex gap="1rem" align="center">
            <Typography.Header size={Size.SM}>{advocateName}</Typography.Header>
            <Button.Link onClick={toggleSelectAllForAdvocate}>
              <Typography.Body size={Size.XS}>Select All</Typography.Body>
            </Button.Link>
          </Flex>
        </th>
      </tr>
      {Object.keys(timeSpansByPatientId).map((patientId) => (
        <PatientLineItem timeSpans={timeSpansByPatientId[patientId]} isSubmitting={isSubmitting} />
      ))}
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td>
          <Typography.Header size={Size.XS}>
            Total: {calculations.time.hours} hours {calculations.time.minutes} minutes
          </Typography.Header>
        </td>
        <td>
          <Typography.Header size={Size.XS}>Total: {formatCurrency(calculations.amount)}</Typography.Header>
        </td>
      </tr>
    </>
  );
};
