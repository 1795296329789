import useSWR from 'swr';
import fetcher from '../fetcher';

export type SoapNote = {
  ai_response: any;
};

export const useVideoCallSoapNote = ({ id }: { id: string }) => {
  const { data, error, mutate } = useSWR<SoapNote>(`/v1/api/video_calls/${id}/soap_note`, fetcher);

  return {
    data: data,
    loading: data === undefined,
    error,
    refresh: mutate,
  };
};
