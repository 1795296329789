import useSWR from 'swr';
import qs from 'qs';
import fetcher from '../fetcher';
import { Referral, ReferralEligibilityCheck } from '../../types/referrals';

const useEligibilityChecks = ({ userId }: { userId: string }) => {
  const urlParams = qs.stringify({ include: ['referral.prospect', 'referral.user'] });
  const { data, error } = useSWR<
    (ReferralEligibilityCheck & { referral: (Referral & { prospect: { first_name: string; last_name: string } }) | null })[]
  >(`/v1/api/admin/users/${userId}/eligibility_checks?${urlParams}`, fetcher);

  return {
    data: data || [],
    loading: data === undefined,
    error,
  };
};

export default useEligibilityChecks;
