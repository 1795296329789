import { Form, styled } from '@solace-health/ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const StyledTextInput = styled(Form.TextInput)`
  background-color: #f4f8f7;
`;
