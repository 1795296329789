import useSWR from 'swr';
import fetcher from './fetcher';
import { User } from '../types/user';
import { TimeSpan } from '../types/timeSpan';
import { SolaceAPI } from '../utils/api';

export type UnpaidTimeSpansResponse = {
  [advocateId: string]: (TimeSpan & {
    advocate_patient: {
      patient: User;
      advocate: User;
    };
  })[];
};

const useUnpaidTimeSpans = ({ fromDt, toDt }: { fromDt: Date; toDt: Date }) => {
  const shouldFetch = !!fromDt && !!toDt;
  const {
    data: unpaidTimeSpans,
    error,
    isLoading,
    mutate,
  } = useSWR<UnpaidTimeSpansResponse>(
    shouldFetch
      ? `/v1/api/admin/unpaid_time_spans?${new URLSearchParams({
          from_dt: fromDt.toISOString(),
          to_dt: toDt.toISOString(),
        }).toString()}`
      : null,
    fetcher,
  );

  const payoutTimeSpans = async ({
    timeSpanIds,
    fromDt,
    toDt,
  }: {
    timeSpanIds: TimeSpan['id'][];
    fromDt: Date;
    toDt: Date;
  }) => {
    return SolaceAPI.post({
      path: '/api/admin/payout_time_spans',
      body: { time_span_ids: timeSpanIds, from_dt: fromDt, to_dt: toDt },
    });
  };

  return {
    unpaidTimeSpans,
    isLoading,
    error,
    payoutTimeSpans,
    refresh: mutate,
  };
};

export default useUnpaidTimeSpans;
