/**
 * Warning: This code is ugly and might have bugs. It was a quick prototype to be able to view care plans in JSON format
 * because the data is encrypted in the database.
 */
import { useParams } from 'react-router-dom';
import { useGetForm } from '../../../../hooks/forms/useGetForm';
import { Form, FormTemplate } from '../../../../types/forms';
import { User } from '../../../../types/user';
import { Divider, Flex, LoadingSpinner, Typography } from '@solace-health/ui';
import { Card } from '@mui/material';
import { parseContent } from '../utils/parseFormContent';

export const FormPage = () => {
  const { id: formId } = useParams<{ id: Form['id'] }>() as { id: string };

  const { data: form, loading } = useGetForm<{ form_template: FormTemplate; sender: User; sent_to: User }>({
    id: formId,
    query: { include: ['form_template', 'sender', 'sent_to'] },
  });

  if (loading) return <LoadingSpinner />;
  if (!form) return <Typography.Display>No form found</Typography.Display>;

  const jsonData: { id: string; label: string; details: string; content: string | null }[] = [];
  form.form_template.components.forEach((component) => {
    component.form_element_responses.forEach((response) => {
      jsonData.push({
        id: response.id,
        label: component.form_element_options.options.label,
        details: component.form_element_options.options.details,
        content: parseContent({ formElementOptions: component.form_element_options, content: response.content }),
      });
    });
  });

  return (
    <Flex vertical gap="2rem">
      <Typography.Display>{form.form_template.name}</Typography.Display>
      <Card style={{ padding: '1rem' }}>
        <Flex vertical gap=".5rem">
          <Typography.Header>Advocate: {form.sender.fullName}</Typography.Header>
          <Typography.Header>Patient: {form.sent_to.fullName}</Typography.Header>
        </Flex>
      </Card>
      {jsonData.map((data) => {
        return (
          <Flex key={data.id} vertical gap="1rem">
            <Flex vertical>
              <Typography.Header>{data.label}</Typography.Header>
              <Typography.Body>
                <i>{data.details}</i>
              </Typography.Body>
            </Flex>
            {data.content}
          </Flex>
        );
      })}
      <Divider />
      <Typography.Display>Pretty JSON</Typography.Display>
      <pre>{JSON.stringify(jsonData, null, 2)}</pre>
      <Divider />
      <Typography.Display>Raw JSON</Typography.Display>
      <pre>{JSON.stringify(form, null, 2)}</pre>
    </Flex>
  );
};
