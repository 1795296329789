import { Button, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useReducer, useState } from 'react';
import useBroadcastNotifications from '../../../hooks/useBroadcastNotifications';
import { orderBy } from 'lodash';

type State = {
  title: string;
  body: string;
  action_path: string;
  user_type: string[];
};

const DEFAULT_STATE: State = {
  title: '',
  body: '',
  action_path: '',
  user_type: [],
};

const NotificationsPage = () => {
  const { data: notifications, createNotification, refresh } = useBroadcastNotifications();
  const [error, setError] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // Poll notifications because they're created asynchronously
    const interval = setInterval(() => {
      refresh();
    }, 5000);

    return () => clearInterval(interval);
  });

  const [state, dispatch] = useReducer((prev: State, next: State) => ({ ...prev, ...next }), DEFAULT_STATE);

  const onSubmit = () => {
    const { title, body, action_path } = state;
    if (!title || !body || !action_path) {
      setError('All fields are required.');
      return;
    } else if (state.user_type.length === 0) {
      setError('Must send to advocates, clients, or both.');
      return;
    }

    setError(null);

    createNotification({ body: state })
      .then((response: any) => {
        refresh();
        dispatch({ ...DEFAULT_STATE });
        enqueueSnackbar(response.data.message, { variant: 'success' });
      })
      .catch((data: any) => {
        enqueueSnackbar(data.response.data.error, { variant: 'error' });
      });
  };

  const onChangeUserType = (userType: string, checked: boolean) => {
    if (checked) {
      dispatch({ ...state, user_type: [...state.user_type, userType] });
    } else {
      dispatch({ ...state, user_type: state.user_type.filter((u: string) => u !== userType) });
    }
  };

  const onChange = (key: string, value: string) => {
    dispatch({ ...state, [key]: value });
  };

  const sortedNotifications = orderBy(notifications, ['action_dt'], ['desc']);
  const mostRecentNotification = sortedNotifications[0] || {};
  const minutes = (new Date().getTime() - new Date(mostRecentNotification.action_dt).getTime()) / 1000 / 60;
  let ago = new Date(mostRecentNotification.action_dt).toLocaleString();
  if (Math.floor(minutes) === 0) {
    ago = 'Just Now';
  } else if (Math.floor(minutes) < 60) {
    ago = `${Math.floor(minutes)} minutes ago`;
  }

  console.log(state);

  return (
    <Grid container flexDirection="column" padding="20px 100px" maxWidth="1000px">
      <div>
        <h1>Most Recently Sent Broadcast</h1>
        {mostRecentNotification.title ? (
          <div>
            <h3>
              {mostRecentNotification.title} - {ago} - {mostRecentNotification.count} users
            </h3>
          </div>
        ) : (
          'None'
        )}
      </div>
      <h1>Send a Broadcast Notification</h1>
      <FormControl sx={{ marginBottom: '24px' }}>
        <FormLabel>Send to?</FormLabel>
        <FormControlLabel
          value="advocate"
          control={
            <Checkbox
              checked={state.user_type.some((u: string) => u === 'advocate')}
              onChange={(_, checked) => onChangeUserType('advocate', checked)}
            />
          }
          label="Advocates"
        />
        <FormControlLabel
          value="client"
          control={
            <Checkbox
              checked={state.user_type.some((u: string) => u === 'client')}
              onChange={(_, checked) => onChangeUserType('client', checked)}
            />
          }
          label="Clients"
        />
      </FormControl>
      <FormControl sx={{ marginBottom: '24px' }}>
        <FormLabel>Title</FormLabel>
        <TextField
          fullWidth
          minRows={10}
          margin="normal"
          value={state.title}
          onChange={(event) => onChange('title', event.target.value)}
        />
      </FormControl>
      <FormControl sx={{ marginBottom: '24px' }}>
        <FormLabel>Body</FormLabel>
        <TextField
          fullWidth
          multiline
          minRows={10}
          margin="normal"
          value={state.body}
          onChange={(event) => onChange('body', event.target.value)}
        />
      </FormControl>
      <FormControl sx={{ marginBottom: '24px' }}>
        <FormLabel>Path</FormLabel>
        <TextField
          fullWidth
          minRows={10}
          margin="normal"
          value={state.action_path}
          onChange={(event) => onChange('action_path', event.target.value)}
          helperText="Where should the user be directed to when clicking the notification?"
        />
      </FormControl>

      {error && <p style={{ color: 'red' }}>{error}</p>}
      <Button variant="contained" size="large" onClick={onSubmit} sx={{ marginTop: '8px' }}>
        Send
      </Button>
      <div style={{ marginTop: '48px' }}>
        <h1>Broadcast Notifications</h1>
        <>
          {sortedNotifications.map((notification) => {
            return (
              <div>
                <h3>
                  {notification.title} - {new Date(notification.action_dt).toLocaleString()} - {notification.count} users
                </h3>
              </div>
            );
          })}
        </>
      </div>
    </Grid>
  );
};

export default NotificationsPage;
