import { Flex } from '@solace-health/ui';
import StatusPill from '../../../../../../components/sharedStatusPill/StatusPill';

type Props = {
  status: 'incomplete' | 'not_sent' | 'sent' | 'pending' | 'completed' | null;
};

const statusPillProps = (status: Props['status']) => {
  switch (status) {
    case 'completed':
      return {
        children: 'Completed',
        borderColor: 'var(--border-color-dark-green, #285E50)',
        backgroundColor: 'var(--green-100, #F4F8F7)',
      };
    case 'sent':
      return {
        children: 'Sent',
        borderColor: 'var(--border-color-dark-green, #285E50)',
        backgroundColor: 'var(--green-100, #F4F8F7)',
      };
    case 'pending':
      return {
        children: 'Pending',
        borderColor: 'var(--border-color-warning, #D7A13B)',
        backgroundColor: 'var(--gold-100, #FFF7E9)',
      };
    case 'not_sent':
      return {
        children: 'Not Sent',
        borderColor: 'var(--border-color-danger, #DA4D54)',
        backgroundColor: 'var(--danger-bg-100, #F1E1E2)',
      };
    case 'incomplete':
      return {
        children: 'Incomplete',
        borderColor: 'var(--border-color-danger, #DA4D54)',
        backgroundColor: 'var(--danger-bg-100, #F1E1E2)',
      };
    default:
      return {
        children: 'Unknown',
        borderColor: 'var(--border-color-light, #D9D9D9)',
        backgroundColor: 'var(--surface-primary, #FFF)',
      };
  }
};

export const OnboardingStatusPill = ({ status }: Props) => {
  return (
    <Flex>
      <StatusPill {...statusPillProps(status)} />
    </Flex>
  );
};
