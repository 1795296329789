import { Flex, Size, Toggle, Typography } from '@solace-health/ui';
import { TraitId } from '../../../../../../types/user';
import { Listing, PausedListingTrait } from '../../../../../../types/listing';
import useGetListing from '../../../../../../hooks/useGetListing';

type Props = {
  listing: Listing & { paused_listing_traits: PausedListingTrait[] };
};

export const PausedTraits = ({ listing }: Props) => {
  const { createPausedListingTrait, deletePausedListingTrait, refresh } = useGetListing({ id: listing.id || '' });
  const hasPausedSelfPayTrait = listing.paused_listing_traits.some(
    (pausedListingTrait) => pausedListingTrait.trait_id === TraitId.SelfPayAdvocate,
  );
  const hasPausedChwAdvocateTrait = listing.paused_listing_traits.some(
    (pausedListingTrait) => pausedListingTrait.trait_id === TraitId.CommunityHealthAdvocate,
  );

  const handleTraitClick = async (isToggled: boolean, traitId: TraitId) => {
    if (!traitId) return;

    if (!isToggled) {
      await createPausedListingTrait({
        id: listing.id,
        traitId: traitId,
      });
    } else {
      await deletePausedListingTrait({
        id: listing.id,
        traitId,
      });
    }

    refresh();
  };

  return (
    <>
      <Flex gap={12} align="center" justify="space-between">
        <Typography.Body bold size={Size.LG}>
          Patients
        </Typography.Body>
        <Toggle
          name={TraitId.CommunityHealthAdvocate}
          handleEvent={(isToggled) => handleTraitClick(isToggled, TraitId.CommunityHealthAdvocate)}
          defaultState={!hasPausedChwAdvocateTrait}
          value={TraitId.CommunityHealthAdvocate}
        />
      </Flex>
      <Flex gap={12} align="center" justify="space-between">
        <Typography.Body bold size={Size.LG}>
          Clients
        </Typography.Body>
        <Toggle
          name={TraitId.SelfPayAdvocate}
          handleEvent={(isToggled) => handleTraitClick(isToggled, TraitId.SelfPayAdvocate)}
          defaultState={!hasPausedSelfPayTrait}
          value={TraitId.SelfPayAdvocate}
        />
      </Flex>
    </>
  );
};
