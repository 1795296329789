import useUnpaidTimeSpans from '../../../hooks/useUnpaidTimeSpans';
import { Alert, Button, Flex, Form, Size, Typography, useForm } from '@solace-health/ui';
import * as S from './style';
import { TimeSpan } from '../../../types/timeSpan';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { ConfirmActionModal } from '../healthRecords/shared/ConfirmActionModal';

export enum FormFields {
  FilterFromDt = 'filter_from_dt',
  FilterToDt = 'filter_to_dt',
  TimeSpanIds = 'time_span_ids',
}

type FormTypes = {
  [FormFields.FilterFromDt]: Date | '';
  [FormFields.FilterToDt]: Date | '';
  [FormFields.TimeSpanIds]: TimeSpan['id'][];
};

export const PayoutsPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [errors, setErrors] = useState<{ ids: string[]; error: string }[]>([]);
  const form = useForm<FormTypes>({
    defaultValues: {
      [FormFields.FilterFromDt]: '',
      [FormFields.FilterToDt]: '',
      [FormFields.TimeSpanIds]: [],
    },
  });
  const filterFromDt = form.watch(FormFields.FilterFromDt);
  const filterToDt = form.watch(FormFields.FilterToDt);

  const {
    unpaidTimeSpans,
    isLoading,
    payoutTimeSpans,
    refresh: refreshUnpaidTimeSpans,
  } = useUnpaidTimeSpans({
    fromDt: filterFromDt as Date,
    toDt: filterToDt as Date,
  });

  useEffect(() => {
    form.setValue(FormFields.TimeSpanIds, []);
  }, [filterFromDt, filterToDt]);

  const onConfirmSubmit = async () => {
    setIsSubmitting(true);
    setIsConfirmModalOpen(false);

    const body = form.getValues();
    await payoutTimeSpans({
      timeSpanIds: body[FormFields.TimeSpanIds],
      fromDt: body[FormFields.FilterFromDt] as Date,
      toDt: body[FormFields.FilterToDt] as Date,
    })
      .then(() => {
        setErrors([]);
        enqueueSnackbar('Payout request created!', { variant: 'success' });
      })
      .catch((e) => {
        setErrors(e.rawResponse.failed_time_spans || []);
        enqueueSnackbar(`Payout request failed - ${e.message}`, { variant: 'error' });
      })
      .finally(async () => {
        await refreshUnpaidTimeSpans();
        form.setValue(FormFields.TimeSpanIds, []);
        setIsSubmitting(false);
      });
  };

  return (
    <S.Container>
      <Typography.Display>Payouts</Typography.Display>

      <S.FormContainer onSubmit={() => setIsConfirmModalOpen(true)} formMethods={form}>
        <Flex gap="1rem">
          <Form.DateTextMaskInput label="From Date" name={FormFields.FilterFromDt} />
          <Form.DateTextMaskInput
            label={
              <Flex gap="1rem" align="center" justify="space-between">
                <Typography.Header>To Date</Typography.Header>
                <Button.Link onClick={() => form.setValue(FormFields.FilterToDt, new Date())}>
                  <Typography.Body size={Size.SM}>Set Today</Typography.Body>
                </Button.Link>
              </Flex>
            }
            name={FormFields.FilterToDt}
          />
          {errors.length > 0 && (
            <Alert
              type="error"
              style={{ maxWidth: '40rem' }}
              message={
                <Flex vertical gap="1rem">
                  <Typography.Header bold>Errors:</Typography.Header>
                  {errors.map((error) => {
                    return (
                      <Flex style={{ borderBottom: '1px solid #555' }} gap="1rem">
                        <Typography.Body>
                          <b>{error.ids.length} Time Spans Failed:</b> {error.error}
                        </Typography.Body>
                      </Flex>
                    );
                  })}
                </Flex>
              }
            />
          )}
          {isLoading && <>Loading...</>}
        </Flex>

        <S.Table unpaidTimeSpans={unpaidTimeSpans || {}} isSubmitting={isSubmitting} />
      </S.FormContainer>
      <ConfirmActionModal
        isOpen={isConfirmModalOpen}
        prompt="Are you sure you want to payout the selected time spans?"
        confirmText="Yes, payout"
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={onConfirmSubmit}
      />
    </S.Container>
  );
};
