import { useParams } from 'react-router-dom';

import useGetUser from '../../../../../hooks/useGetUser';

import Loading from '../../../../../components/general/Loading';
import { Header } from './shared/Header';
import { Size, Tabs, Typography } from '@solace-health/ui';

import Timespans from './shared/Timespans';
import MainSection from './shared/MainSection/MainSection';
import { TraitId } from '../../../../../types/user';
import { AdvocateOnboarding } from './AdvocateOnboarding/AdvocateOnboarding';
import { useQuery } from '../../../../../hooks/useQuery';

function Advocate() {
  const params = useParams<{ userId: string }>();
  const query = useQuery();
  const tab = query.get('tab');

  const { data: user, loading: loadingUser } = useGetUser<{
    identities: { provider: 'auth0' | 'google-oauth2' | 'facebook' }[];
  }>({
    id: params.userId,
    includes: ['identities', 'traits'],
  });

  if (loadingUser || !user) return <Loading loading={true} />;

  const isSICA = user?.traits?.find((trait) => trait.id === TraitId.SolaceHealthAdvocate);

  return (
    <div style={{ padding: '.5rem', maxWidth: 1200 }}>
      <Header user={user} />

      <Tabs
        style={{ marginTop: '1rem' }}
        defaultActiveKey={tab || '/'}
        items={[
          {
            label: <Typography.Header size={Size.XS}>Main</Typography.Header>,
            key: '/',
            children: <MainSection />,
          },
          {
            label: <Typography.Header size={Size.XS}>Time Spans</Typography.Header>,
            key: 'time-spans',
            children: <Timespans advocateId={user.id} />,
          },
          ...(isSICA
            ? [
                {
                  label: <Typography.Header size={Size.XS}>Onboarding</Typography.Header>,
                  key: 'onboarding',
                  children: <AdvocateOnboarding advocateId={user.id} />,
                },
              ]
            : []),
        ]}
      />
    </div>
  );
}

export default Advocate;
