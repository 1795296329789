import { Booking } from '../../types/booking';
import { SolaceAPI } from '../../utils/api';
import { useSnackbar } from 'notistack';
import { SolaceApiError } from '../../utils/errors';

export type UpdateBookingDto = {
  start?: Date;
  end?: Date;
  acknowledged_dt?: Date;
  switch_call_type?: boolean;
  state?: string;
};

export default function useBookings() {
  const { enqueueSnackbar } = useSnackbar();

  const updateBooking = async (id: string, data: UpdateBookingDto) => {
    try {
      const { data: updatedBooking } = await SolaceAPI.put<Booking>({
        path: `/api/booking/${id}`,
        body: data,
      });
      return updatedBooking;
    } catch (e) {
      const error = e as SolaceApiError;
      enqueueSnackbar(`Failed to update booking - ${error.message}`);
      return null;
    }
  };

  return {
    updateBooking,
  };
}
