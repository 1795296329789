import useSWR, { SWRResponse } from 'swr';
import fetcher from './fetcher';
import { useState } from 'react';
import qs from 'qs';

import { QueryParamInputs } from '../components/shared/Table/antdOnChangeToQueryParams';
import { debounce } from 'lodash';

type PaginatedResponse<T> = {
  data: T[];
  meta: {
    page: number;
    perPage: number;
    totalItems: number;
    totalPages: number;
  };
};

type PaginatedResourceHookReturn<T> = Omit<SWRResponse<PaginatedResponse<T>, any, any>, 'data'> & {
  data: T[];
  pagination: {
    current: number;
    total: number;
    pageSize: number;
    defaultPageSize: number;
  };
  setQueryParams: (input: Partial<QueryParamInputs & Record<string, unknown>>) => void;
  resetQueryParams: () => void;
  setSearch: (value: string) => void;
};

const DEFAULT_QUERY_PARAMS: QueryParamInputs = {
  page: 1,
};

export default function useGetPaginatedResource<DataType>(
  queryKey: string,
  startingQueryParams?: Partial<QueryParamInputs> & Record<string, unknown>,
): PaginatedResourceHookReturn<DataType> {
  const [queryParams, setQueryParams] = useState<QueryParamInputs & Record<string, unknown>>({
    ...startingQueryParams,
    page: 1,
  });

  const urlParams = qs.stringify({
    ...queryParams,
    include: startingQueryParams?.include,
    filter: queryParams?.filter,
    page: { number: queryParams.page, size: 10 },
  });

  const { data: responseBody, ...rest } = useSWR<PaginatedResponse<DataType>>(`${queryKey}?${urlParams}`, fetcher, {
    keepPreviousData: true,
  });

  const { data, meta } = responseBody ?? {};

  const resetQueryParams = () => {
    setQueryParams(DEFAULT_QUERY_PARAMS);
  };

  const setSearch = (input: string) => setQueryParams((prev) => ({ ...prev, search: input, page: 1 }));

  return {
    data: data ?? [],
    setQueryParams: (newState: Partial<QueryParamInputs & Record<string, unknown>>) =>
      setQueryParams((prevState) => ({ ...prevState, ...newState })),
    resetQueryParams,
    setSearch: debounce(setSearch, 300),
    pagination: {
      current: queryParams.page,
      pageSize: meta?.perPage || 0,
      defaultPageSize: meta?.perPage || 10,
      total: meta?.totalItems || 0,
    },
    ...rest,
  };
}
