import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { UserTimeZone } from '../types/user';
import errors from './errors';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import { USA_STATES } from '../shared/constants/general-constants';

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

const TIME_ZONE_NAME = {
  EST: 'Eastern Time',
  CST: 'Central Time',
  MST: 'Mountain Time',
  PST: 'Pacific Time',
  AKST: 'Alaska Time',
  HST: 'Hawaii-Aleutian',
} as const;

const TIME_ZONE_ABBR = {
  [UserTimeZone.NewYork]: 'EST',
  [UserTimeZone.Detroit]: 'EST',
  [UserTimeZone.Indianapolis]: 'EST',
  [UserTimeZone.Louisville]: 'EST',

  [UserTimeZone.Chicago]: 'CST',
  [UserTimeZone.Menominee]: 'CST',

  [UserTimeZone.Denver]: 'MST',
  [UserTimeZone.Phoenix]: 'MST',

  [UserTimeZone.LosAngeles]: 'PST',

  [UserTimeZone.Sitka]: 'AKST',
  [UserTimeZone.Anchorage]: 'AKST',
  [UserTimeZone.Juneau]: 'AKST',

  [UserTimeZone.Honolulu]: 'HST',
} as const;

export default function GetFriendlyError(error: string): string {
  if (errors[error]) {
    return errors[error];
  }
  return errors.UNKNOWN_ERROR;
}

export const boolToString = (val: any, trueVal: string, falseVal: string) => {
  let boolval = false;
  if (typeof val === 'boolean') {
    boolval = val;
  } else {
    if (typeof val === 'string') {
      if (val.toLowerCase() === 'true') {
        boolval = true;
      }
    }
  }
  if (boolval === true) {
    return trueVal;
  }
  return falseVal;
};

export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const getUrlParam = (param: string) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(param);
};

export const phoneNumberFormat = (value?: string, previousValue = '') => {
  // return nothing if no value
  if (!value) return '';

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue;

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }

  return value;
};

export const timeZoneName = (timezone?: UserTimeZone, { includeAbbr = false }: { includeAbbr?: boolean } = {}) => {
  if (!timezone) return '';

  const abbr = timeZoneAbbr(timezone);
  if (!TIME_ZONE_NAME[abbr]) return timezone;

  if (includeAbbr) return `${TIME_ZONE_NAME[abbr]} (${abbr})`;

  return TIME_ZONE_NAME[abbr];
};

/**
 *
 * @param date A date string in the format YYYY-MM-DDTHH:MM:SS-04:00
 * @returns A string formatted as HH:MMam in the timezone represented by the offset (-04:00)
 */
export const timeInOriginalTimeZone = (date: string) => {
  return dayjs(date).utcOffset(date.slice(-6)).format('h:mma');
};

export const timeZoneAbbr = (timezone: UserTimeZone): keyof typeof TIME_ZONE_NAME => TIME_ZONE_ABBR[timezone] || timezone;

export const getAppointmentDurationInMinutes = (start?: string | Date, end?: string | Date) =>
  dayjs.duration(dayjs(end).diff(start)).asMinutes();

export const getPrettyStartEndTime = ({
  startDate,
  endDate,
  timezone,
}: {
  startDate?: Date | string;
  endDate?: Date | string;
  timezone?: UserTimeZone;
}) => {
  const start = dayjs(startDate).tz(timezone).format('h:mma');
  const end = dayjs(endDate).tz(timezone).format('h:mma');

  return { start, end };
};

export const getFullStateName = (stateAbbr?: string | null) => {
  const state = USA_STATES.find((state) => state.abbreviation === stateAbbr);

  return state?.name ?? 'n/a';
};

export const getDurationInHoursAndMinutes = (duration: number) => {
  const dayjsSeconds = dayjs.duration(duration, 'seconds');
  const hours = dayjsSeconds.hours();
  const mins = dayjsSeconds.minutes();

  return { hours, mins };
};

export function copyToClipBoard({ text }: { text: string }) {
  navigator.clipboard.writeText(text);
}
